import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../axiosInstance";

const Unsubscribe = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Get the code from the URL query parameter
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const code = query.get("code");

  useEffect(() => {
    // Function to verify the code by making a POST request
    const verifyCode = async () => {
      try {
        await api.post("/api/unsubscribe", {
          cancellation_code: code,
        });
        setLoading(false);
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/unauthorized");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    };

    verifyCode();
  }, [code, navigate]);

  return (
    <div>{loading ? <h1>Unsubscribing...</h1> : <h1>Unsubscribed!</h1>}</div>
  );
};

export default Unsubscribe;
