import React, { useState } from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Button } from "react-bootstrap";
import ShareButton from "./ShareButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const PublicCompanyCard = ({
  companyName,
  description,
  websiteLink,
  addressLine1,
  addressLine2,
  city,
  country,
  postcode,
  email,
  phone,
  facebookLink,
  instagramLink,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => setIsExpanded(!isExpanded);

  const hasAddress =
    addressLine1 || addressLine2 || city || country || postcode;
  const hasContact = email || phone || websiteLink;

  return (
    <div>
      <Card className="company-profile-card">
        <Card.Body className="position-relative">
          <div className="share-button-container mr-2">
            <ShareButton />
          </div>

          <Card.Title>
            {companyName}
            <Button variant="link" className="ml-2" onClick={handleToggle}>
              <FontAwesomeIcon
                icon={isExpanded ? faChevronUp : faChevronDown}
              />
            </Button>
          </Card.Title>

          {isExpanded && (
            <>
              <Card.Text>{description}</Card.Text>

              {hasAddress && (
                <>
                  <Card.Subtitle className="mb-2 fw-bold">
                    Address
                  </Card.Subtitle>
                  {addressLine1 && (
                    <span>
                      {addressLine1}
                      <br />
                    </span>
                  )}
                  {addressLine2 && (
                    <span>
                      {addressLine2}
                      <br />
                    </span>
                  )}
                  {city && (
                    <span>
                      {city}
                      <br />
                    </span>
                  )}
                  {country && (
                    <span>
                      {country}
                      <br />
                    </span>
                  )}
                  {postcode && <span>{postcode}</span>}
                </>
              )}

              {hasContact && (
                <>
                  <Card.Subtitle className="mt-4 mb-2 fw-bold">
                    Contact
                  </Card.Subtitle>
                  {phone && (
                    <span>
                      {phone}
                      <br />
                    </span>
                  )}
                  {email && <span>{email}</span>}
                </>
              )}

              {websiteLink && (
                <Card.Text className="mt-4">
                  <a
                    href={websiteLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit Our Website
                  </a>
                </Card.Text>
              )}

              {/* Social Media Links */}
              <div className="mt-3">
                {facebookLink && (
                  <a
                    href={facebookLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    <FontAwesomeIcon icon={faFacebook} size="2x" />
                  </a>
                )}
                {instagramLink && (
                  <a
                    href={instagramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                  </a>
                )}
              </div>
            </>
          )}
          <div
            className="powered-by"
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              fontSize: "15px",
              color: "#6c757d",
            }}
          >
            &copy; {new Date().getFullYear()} Timetablr
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

PublicCompanyCard.propTypes = {
  companyName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  websiteLink: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  postcode: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  facebookLink: PropTypes.string,
  instagramLink: PropTypes.string,
};

export default PublicCompanyCard;
