import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const convertUtcToLocalTime = (utcTime) => {
  const [time, modifier] = utcTime.split(" "); // Separate time and AM/PM
  const [hours, minutes] = time.split(":").map(Number); // Split hours and minutes
  const formattedModifier = modifier.toUpperCase(); // Ensure modifier is uppercase

  // Create a UTC date string using the current date
  const utcDate = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      hours + (formattedModifier === "PM" ? 12 : 0),
      minutes
    )
  );

  // Convert to local time string in 'hh:mm AM/PM' format
  const localTimeString = utcDate
    .toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .replace(/(am|pm)/, (match) => match.toUpperCase());

  return localTimeString;
};

const TimesChart = ({ proposedTimes }) => {
  // Check if proposedTimes is empty
  if (proposedTimes.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh", // Make it occupy the full height of the viewport
          fontSize: "24px", // Adjust font size as needed
          color: "grey", // You can change the color if needed
        }}
      >
        No proposed times for this class.
      </div>
    );
  }

  // Prepare data for the chart
  const days = [...new Set(proposedTimes.map((item) => item.day))];
  const times = [...new Set(proposedTimes.map((item) => item.time))];

  // Convert UTC times to local times using the utility function
  const localTimes = times.map(convertUtcToLocalTime);

  // Define different colors for each day
  const dayColors = {
    Monday: "#FF6384",
    Tuesday: "#36A2EB",
    Wednesday: "#FFCE56",
    Thursday: "#4BC0C0",
    Friday: "#9966FF",
    Saturday: "#FF9F40",
    Sunday: "#40FF69", // Repeat or use another color if needed
  };

  // Prepare datasets for the chart
  const datasets = localTimes.map((time) => ({
    label: time,
    data: days.map((day) => {
      const item = proposedTimes.find(
        (i) => i.day === day && convertUtcToLocalTime(i.time) === time
      );
      return item ? item.count : 0;
    }),
    backgroundColor: days.map((day) => dayColors[day] || "#3399ff"), // Use the color for each day
  }));

  const data = {
    labels: days,
    datasets,
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1, // This ensures integer ticks on the Y-axis
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Disable the legend
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <div className="chart-container">
      <Bar data={data} options={options} />
    </div>
  );
};

export default TimesChart;
