import React, { useState, useEffect } from "react";
import { Accordion, Container, Card, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PublicLayout from "./PublicLayout";
import api from "../axiosInstance";

const ClassDetails = () => {
  const [classes, setClasses] = useState([]);
  const navigate = useNavigate();

  const timetableId = useParams()["id"];

  const fetchClasses = async () => {
    try {
      const response = await api.get(`/api/timetables/${timetableId}/classes`);
      setClasses(response.data);
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            navigate("/bad-request");
            break;
          case 402:
            navigate("/payment-required");
            break;
          case 401:
            navigate("/auth/login");
            break;
          case 500:
            navigate("/server-error");
            break;
          default:
            navigate("/not-found");
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  useEffect(() => {
    fetchClasses();
  }, [timetableId]);

  return (
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh" }}>
      <PublicLayout>
        <Container>
          <Row className="align-items-center">
            <Col>
              <h3 className="mt-4">Classes</h3>
            </Col>
          </Row>
          <Row className="mt-4">
            {classes.length === 0 ? (
              <p>No classes available.</p>
            ) : (
              classes.map((cls) => (
                <Col key={cls.id} md={4} className="mb-4">
                  <Card
                    className="h-100 d-flex flex-column"
                    style={{
                      borderTop: cls.display_colour
                        ? `6px solid ${cls.display_colour}`
                        : "none", // Add top border if display_colour is defined
                    }}
                  >
                    <Card.Header>
                      <Card.Title className="fw-bold">
                        {cls.title} {cls.level ? `- ${cls.level}` : ""}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body className="d-flex flex-column">
                      <Card.Text>{cls.description}</Card.Text>
                      <Card.Text>
                        {cls.location && (
                          <>
                            <strong>Location:</strong>
                            <br />
                            {cls.location}
                          </>
                        )}
                      </Card.Text>

                      <Card.Text>
                        {cls.instructor_names &&
                          cls.instructor_names.length > 0 && (
                            <>
                              <strong>Instructor(s):</strong>
                              <br />
                              {cls.instructor_names.map((name, index) => (
                                <span key={index}>
                                  {name}
                                  <br />
                                </span>
                              ))}
                            </>
                          )}
                      </Card.Text>

                      <Card.Text>
                        {cls.level && (
                          <>
                            <strong>Level:</strong>
                            <br />
                            {cls.level}
                          </>
                        )}
                      </Card.Text>
                      <Accordion defaultActiveKey="1" className="mt-auto">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Additional Info</Accordion.Header>
                          <Accordion.Body>
                            <Card.Text>
                              {/* Cost */}
                              {cls.cost ? (
                                <>
                                  <strong>Cost:</strong>
                                  <br />
                                  {cls.cost}
                                  <br />
                                </>
                              ) : null}

                              {/* Equipment */}
                              {cls.equipment ? (
                                <>
                                  <strong>Equipment:</strong>
                                  <br />
                                  {cls.equipment}
                                  <br />
                                </>
                              ) : null}

                              {/* Requirements */}
                              {cls.requirements ? (
                                <>
                                  <strong>Requirements:</strong>
                                  <br />
                                  {cls.requirements}
                                  <br />
                                </>
                              ) : null}

                              {/* Additional Notes */}
                              {cls.additional_notes ? (
                                <>
                                  <strong>Notes:</strong>
                                  <br />
                                  {cls.additional_notes}
                                  <br />
                                </>
                              ) : null}

                              {/* Display Colour */}
                              {cls.display_colour ? (
                                <>
                                  <strong>Display Colour:</strong>
                                  <br />
                                  <div
                                    className="mt-2"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      backgroundColor: cls.display_colour,
                                      borderRadius: "10%",
                                      border: "1px solid #000",
                                    }}
                                  />
                                </>
                              ) : null}

                              {/* Placeholder text if none of the fields are defined */}
                              {!cls.cost &&
                              !cls.equipment &&
                              !cls.requirements &&
                              !cls.additional_notes &&
                              !cls.display_colour ? (
                                <span>
                                  No additional information available.
                                </span>
                              ) : null}
                            </Card.Text>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Container>
      </PublicLayout>
    </div>
  );
};

export default ClassDetails;
