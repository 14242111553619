import React, { useState } from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";

const featuresData = [
  {
    id: 1,
    name: "Company Information",
    image: "/images/landing_public_company_info.png",
    icon: "faCalendar",
    description:
      "Showcase your business details, including your contact information, address, website and social media links.",
  },
  {
    id: 2,
    name: "Timetable",
    image: "/images/landing_public_timetable.png",
    icon: "📊",
    description: "Display your timetable to your customers including cancelled and rescheduled classes.",
  },
  {
    id: 3,
    name: "Class Details",
    image: "/images/landing_public_classes.png",
    icon: "✅",
    description: "Provide your customers to view detailed class information.",
  },
  {
    id: 4,
    name: "Instructor Profiles",
    image: "/images/landing_public_instructors.png",
    icon: "🤝",
    description: "Introduce your customers to their instructors.",
  }
];

const PublicFeatureSelector = () => {
  const [selectedFeature, setSelectedFeature] = useState(featuresData[0]);

  const handleFeatureSelect = (feature) => {
    setSelectedFeature(feature);
  };

  return (
    <Container className="mt-5">
      <Card className="p-4">
        {/* Large Heading */}
        <h1 className="text-center mb-4">Public Page Features</h1>

        <h6 className="text-center">
          With your public page, your customers can view your company information, class details,
          instructor profiles and class schedule. They can also propose different times for your classes
          and subscribe to class cancellation and rescheduling notifications.
        </h6>

        <Row className="mt-3">
          <Col md={4}>
            <Card className="p-3 border-0 d-flex flex-column">
              {featuresData.map((feature) => (
                <Button
                  key={feature.id}
                  variant={
                    selectedFeature.id === feature.id ? "primary" : "light"
                  }
                  className="w-100 mb-3 d-flex align-items-center"
                  onClick={() => handleFeatureSelect(feature)}
                >
                  <div className="text-start">
                    <strong>{feature.name}</strong>
                    <div>{feature.description}</div>
                  </div>
                </Button>
              ))}
            </Card>
          </Col>

          <Col md={8}>
            <Card className="text-center p-3 border-0 d-flex align-items-center justify-content-center" style={{ height: '100%', backgroundColor: '#f0f4f8' }}>
              <img
                src={selectedFeature.image}
                alt={selectedFeature.name}
                style={{
                  maxWidth: "100%", // Increase size by adjusting maxWidth
                  maxHeight: "100vh", // Control the height (70% of the viewport height)
                  objectFit: "contain",
                }}
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default PublicFeatureSelector;
