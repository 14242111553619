import React, { useState } from "react";
import { Navbar, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCalendar,
  faUserFriends,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";

const PublicLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { id: timetableId } = useParams();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  // Dynamic width and margin
  const drawerWidth = isOpen ? "250px" : "70px";
  const contentStyle = {
    marginLeft: isOpen ? "250px" : "70px",
    transition: "margin-left 0.3s ease", // Smooth transition
  };

  return (
    <div>
      <div
        className="public-drawer"
        style={{
          width: drawerWidth,
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Navbar bg="light" variant="light" className="flex-column mt-3">
          <Nav className="flex-column">
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="calendar-tooltip">Timetable</Tooltip>}
              delay={{ show: 250, hide: 400 }}
            >
              <Nav.Link
                className="ml-3"
                href={`/timetable/${timetableId}`}
                style={{ color: "black" }}
              >
                <FontAwesomeIcon icon={faCalendar} />
                {isOpen && <span className="ml-2">Timetable</span>}
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="calendar-tooltip">Classes</Tooltip>}
              delay={{ show: 250, hide: 400 }}
            >
              <Nav.Link
                className="ml-3"
                href={`/timetable/${timetableId}/classes`}
                style={{ color: "black" }}
              >
                <FontAwesomeIcon icon={faClipboard} />
                {isOpen && <span className="ml-2">Classes</span>}
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="calendar-tooltip">Instructors</Tooltip>}
              delay={{ show: 250, hide: 400 }}
            >
              <Nav.Link
                className="ml-2"
                href={`/timetable/${timetableId}/instructors`}
                style={{ color: "black" }}
              >
                <FontAwesomeIcon icon={faUserFriends} />
                {isOpen && <span className="ml-2">Instructors</span>}
              </Nav.Link>
            </OverlayTrigger>
          </Nav>
        </Navbar>
        <div className="toggle-arrow" onClick={toggleDrawer}>
          <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
        </div>
      </div>
      <div className="content" style={contentStyle}>
        {/* This is where page content will be rendered */}
        {children}
      </div>
    </div>
  );
};

export default PublicLayout;
