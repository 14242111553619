import React from "react";
import { Nav } from "react-bootstrap";

const GettingStartedSidebar = () => {
  return (
    <Nav className="flex-column" defaultActiveKey="/home">
      <Nav.Link style={{ color: "black" }} href="#introduction">
        Introduction
      </Nav.Link>
      <Nav.Link style={{ color: "black" }} href="#getting-started">
        Getting Started
      </Nav.Link>
    </Nav>
  );
};

export default GettingStartedSidebar;
