import React, { useState } from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import GettingStartedSidebar from "../components/documentation/GettingStartedSidebar";
import GettingStartedContent from "../components/documentation/GettingStartedContent";
import Header from "../components/Header";
import DashboardSidebar from "../components/documentation/DashboardSidebar";
import DashboardContent from "../components/documentation/DashboardContent";
import TimetableSidebar from "../components/documentation/TimetableSidebar";
import TimetableContent from "../components/documentation/TimetableContent";
import ClassDetailsContent from "../components/documentation/ClassDetailsContent";
import ClassDetailsSidebar from "../components/documentation/ClassDetailsSidebar";
import InstructorSidebar from "../components/documentation/InstructorSidebar";
import InstructorContent from "../components/documentation/InstructorContent";
import CustomerPageContent from "../components/documentation/CustomerPageContent";
import CustomerPageSidebar from "../components/documentation/CustomerPageSidebar";

const Documentation = () => {
  const [activeKey, setActiveKey] = useState("getStarted");

  return (
    <Container fluid>
      <Header />
      <div
        style={{
          background: "#f0f4f8",
          padding: "10px",
          borderRadius: "5px",
          marginBottom: "20px",
        }}
      >
        <Tabs
          id="layout-tabs"
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k)}
          className="mb-4"
          style={{ color: "white", fontWeight: "bold" }}
        >
          <Tab eventKey="getStarted" title="Get Started">
            <Row>
              <Col md={2}>
                <GettingStartedSidebar />
              </Col>
              <Col md={10}>
                <GettingStartedContent />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="dashboard" title="Dashboard">
            <Row>
              <Col md={2}>
                <DashboardSidebar />
              </Col>
              <Col md={10}>
                <DashboardContent />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="timetables" title="Timetables">
            <Row>
              <Col md={2}>
                <TimetableSidebar />
              </Col>
              <Col md={10}>
                <TimetableContent />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="classDetails" title="Class Details">
            <Row>
              <Col md={2}>
                <ClassDetailsSidebar />
              </Col>
              <Col md={10}>
                <ClassDetailsContent />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="instructorProfiles" title="Instructor Profiles">
            <Row>
              <Col md={2}>
                <InstructorSidebar />
              </Col>
              <Col md={10}>
                <InstructorContent />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="customerPage" title="Public Page">
            <Row>
              <Col md={2}>
                <CustomerPageSidebar />
              </Col>
              <Col md={10}>
                <CustomerPageContent />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </div>
    </Container>
  );
};

export default Documentation;
