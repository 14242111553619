import React, { useState, useEffect } from "react";
import PublicInstructors from "../components/PublicInstructors";
import Instructors from "../components/Instructors";

// Function to check if a token exists in local storage
const checkAuthStatus = () => {
  // Retrieve the token from local storage
  const token = localStorage.getItem("tt_jwt");
  // Simple check to see if the token exists
  return new Promise((resolve) => {
    if (token) {
      // Optionally, validate the token here (e.g., check expiry or call an API)
      resolve(true);
    } else {
      resolve(false);
    }
  });
};

const InstructorInfo = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check authentication status when the component mounts
    checkAuthStatus().then((status) => {
      setIsLoggedIn(status);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Optionally show a loading spinner or message
  }

  return <div>{isLoggedIn ? <Instructors /> : <PublicInstructors />}</div>;
};

export default InstructorInfo;
