import React from "react";
import { Nav } from "react-bootstrap";
import Dashboard from "../../pages/Dashboard";

const ClassDetailsSideBar = () => {
  return (
    <Nav className="flex-column" defaultActiveKey="/home">
      <Nav.Link style={{ color: "black" }} href="#createClass">
        Create a Class
      </Nav.Link>
      <Nav.Link style={{ color: "black" }} href="#editClass">
        Edit & Delete Classes
      </Nav.Link>
    </Nav>
  );
};

export default ClassDetailsSideBar;
