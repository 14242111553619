// Header.js
import React from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import "../styles.css"; // Import styles for the header

function Header() {
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">Timetablr</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Button
              variant="outline-primary"
              className="mr-2 ml-2"
              href="/auth/login"
            >
              Login
            </Button>
            <Button variant="outline-primary" href="/auth/signup">
              Sign Up
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
