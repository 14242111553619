import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import PublicLayout from "./PublicLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import api from "../axiosInstance";

const InstructorProfiles = () => {
  const [instructors, setInstructors] = useState([]);
  const navigate = useNavigate();

  const timetableId = useParams()["id"];

  const fetchInstructors = async () => {
    try {
      const response = await api.get(
        `/api/timetables/${timetableId}/instructors`
      );
      setInstructors(response.data);
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            navigate("/bad-request");
            break;
          case 402:
            navigate("/payment-required");
            break;
          case 401:
            navigate("/auth/login");
            break;
          case 500:
            navigate("/server-error");
            break;
          default:
            navigate("/not-found");
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  useEffect(() => {
    fetchInstructors();
  }, [timetableId]);

  return (
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh" }}>
      <PublicLayout>
        <Container>
          <Row className="align-items-center">
            <Col>
              <h3 className="mt-4">Instructor Profiles</h3>
            </Col>
          </Row>

          <Row className="mt-4">
            {instructors.length === 0 ? (
              <p>No instructors available.</p>
            ) : (
              instructors.map((instructor) => (
                <Col key={instructor.id} md={4} className="mb-4">
                  <Card className="h-100 d-flex flex-column">
                    {instructor.image_url && (
                      <Card.Img
                        variant="top"
                        src={instructor.image_url}
                        alt={instructor.name}
                        style={{ height: "450px", width: "auto", maxWidth: "100%", objectFit: "cover" }}
                      />
                    )}
                    <Card.Header>
                      <Card.Title>{instructor.name}</Card.Title>
                    </Card.Header>
                    <Card.Body className="d-flex flex-column">
                      <Card.Text className="flex-grow-1">
                        {instructor.bio}
                      </Card.Text>
                      <div className="mt-3 mb-4">
                        {instructor.facebook && (
                          <a
                            href={instructor.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mr-3"
                          >
                            <FontAwesomeIcon icon={faFacebook} size="2x" />
                          </a>
                        )}
                        {instructor.instagram && (
                          <a
                            href={instructor.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon icon={faInstagram} size="2x" />
                          </a>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Container>
      </PublicLayout>
    </div>
  );
};

export default InstructorProfiles;
