import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Container, Card } from "react-bootstrap";
import LoginHeader from "../components/LoginHeader";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import useRecaptcha from "../hooks/useRecaptcha";
import api from "../axiosInstance";

const ProposeTime = () => {
  const [email, setEmail] = useState("");
  const [classes, setClasses] = useState([]); // State to hold class options
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [proposedStart, setProposedStart] = useState(new Date());
  const [emailError, setEmailError] = useState("");
  const [timeError, setTimeError] = useState("");

  const navigate = useNavigate();
  const timetableId = useParams()["id"];
  const classId = useParams()["classId"]; // Class ID from URL params

  const RECAPTCHA_SITE_KEY = "6Ldk9k4qAAAAAPZl0dfdKCXZQHKlR9YzbN9bffkI";
  useRecaptcha(RECAPTCHA_SITE_KEY);

  // Fetch available classes from the API on component mount
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await api.get(
          `/api/timetables/${timetableId}/classes`
        );
        setClasses(response.data); // Update state with classes from the API
      } catch (err) {
        setError("Failed to fetch classes.");
      }
    };

    fetchClasses();
  }, [timetableId]);

  // Get the selected class name
  const selectedClass = classes.find((cls) => cls.id === parseInt(classId));

  // Check if class has a level and add it to the title
  const selectedClassName = selectedClass
    ? `${selectedClass.title}${selectedClass.level ? ` - ${selectedClass.level}` : ""}`
    : "Loading...";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setEmailError("");
    setTimeError("");

    // Basic email validation
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (!proposedStart) {
      setTimeError("Please select a proposed time.");
      return;
    }

    try {
      const recaptchaToken = await window.grecaptcha.execute(
        RECAPTCHA_SITE_KEY,
        { action: "submit" }
      );

      proposedStart.setSeconds(0);
      proposedStart.setMilliseconds(0);
      await api.post("/api/propose-time", {
        email: email,
        classId: classId,
        start: proposedStart.toISOString(),
        recaptcha_token: recaptchaToken,
      });
      navigate("/proposal-verification");
    } catch (err) {
      if (err.response) {
        if (err.response.data?.message) {
          setError(err.response.data.message);
        } else {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  return (
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh" }}>
      <LoginHeader />
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Container>
          <Card style={{ width: "100%", maxWidth: "500px", margin: "0 auto" }}>
            <Card.Header as="h5">Propose New Time</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                {/* Display the class they are proposing a time for */}
                <Form.Group controlId="formClassDisplay" className="mb-3">
                  <Form.Label>Proposing Time For:</Form.Label>
                  <div>{selectedClassName}</div>
                </Form.Group>

                {/* Email field */}
                <Form.Group controlId="formEmail">
                  <Form.Label>
                    Email address <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={!!emailError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {emailError}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Proposed start date and time */}
                <Form.Group
                  controlId="formProposeEventStart"
                  className="mt-3"
                >
                  <Form.Label>
                    Start Date and Time{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={moment(proposedStart).format("YYYY-MM-DDTHH:mm")}
                    onChange={(e) =>
                      setProposedStart(new Date(e.target.value))
                    }
                    isInvalid={!!timeError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {timeError}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-4">
                  Propose Time
                </Button>
              </Form>
              {message && (
                <Alert variant="success" className="mt-3">
                  {message}
                </Alert>
              )}
              {error && (
                <Alert variant="danger" className="mt-3">
                  {error}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
};

export default ProposeTime;
