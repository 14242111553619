import React, { useState, useEffect, useMemo } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Select from "react-select";
import {
  Alert,
  Badge,
  Container,
  Card,
  Button,
  Tab,
  Tabs,
  Modal,
  Nav,
  Form,
  Accordion,
  Row,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, Link, useNavigate } from "react-router-dom";
import CustomEvent from "./CustomEvent";
import "../calendarStyles.css";
import CompanyProfileCard from "./CompanyProfileCard";
import RRuleInput from "./RRuleInput";
import Layout from "./Layout";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import CustomToolbar from "./CustomToolbar";
import EventsCards from "./EventCards";
import api from "../axiosInstance";

const localizer = momentLocalizer(moment);

const Timetable = () => {
  // State hooks
  const [classes, setClasses] = useState([]);
  const [hideCancelledEvents, setHideCancelledEvents] = useState(false);
  const [instructors, setInstructors] = useState([]);
  const [activeTab, setActiveTab] = useState("details");
  const [viewTab, setViewTab] = useState("calendar");
  const [events, setEvents] = useState([]);
  const [timetable, setTimetable] = useState([]);
  const [error, setError] = useState("");
  const [eventStartError, setEventStartError] = useState("");
  const [eventEndError, setEventEndError] = useState("");
  const [rescheduleEventEndError, setRescheduleEventEndError] = useState("");
  const [
    rescheduleRecurringEventEndError,
    setRescheduleRecurringEventEndError,
  ] = useState("");
  const [rescheduleRecurringEventError, setRescheduleRecurringEventError] =
    useState("");
  const [selectedClassError, setSelectedClassError] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventStart, setEventStart] = useState(new Date());
  const [rescheduledEventStart, setRescheduledEventStart] = useState(
    new Date()
  );
  const [rescheduledEventReason, setRescheduledEventReason] = useState("");
  const [cancelEventReason, setCancelEventReason] = useState("");
  const [rescheduledEventEnd, setRescheduledEventEnd] = useState(new Date());
  const [rescheduledRecurringEventStart, setRescheduledRecurringEventStart] =
    useState(new Date());
  const [rescheduledRecurringEventEnd, setRescheduledRecurringEventEnd] =
    useState(new Date());
  const [recurrenceRule, setRecurrenceRule] = useState("");
  const [rescheduledRecurrenceRule, setRescheduledRecurrenceRule] =
    useState("");
  const [eventEnd, setEventEnd] = useState(new Date());
  const [isRecurring, setIsRecurring] = useState(false);
  const [classLevel, setClassLevel] = useState("");
  const [classType, setClassType] = useState("");
  const [classInstructor, setClassInstructor] = useState("");
  const [selectedAction, setSelectedAction] = useState(null);
  const [defaultView, setDefaultView] = useState(Views.WEEK);
  const { id: timetableId } = useParams();
  const scrollToTime = new Date();
  scrollToTime.setHours(7, 0, 0); // Scroll to 7:00 AM
  const navigate = useNavigate();
  const today = new Date();

  // Calculate 4 weeks back and 4 weeks forward
  const startRange = moment(today).subtract(8, "weeks").toDate();
  const endRange = moment(today).add(8, "weeks").toDate();

  const [currentDate, setCurrentDate] = useState(today);

  const handleNavigate = (newDate, view) => {
    if (newDate < startRange) {
      setCurrentDate(startRange); // Prevent navigating before 8 weeks back
    } else if (newDate > endRange) {
      setCurrentDate(endRange); // Prevent navigating beyond 8 weeks forward
    } else {
      setCurrentDate(newDate); // Allow navigation within the allowed range
    }
  };

  useEffect(() => {
    // Function to determine the default view based on screen width
    const determineDefaultView = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setDefaultView(Views.DAY); // Mobile devices
      } else {
        setDefaultView(Views.WEEK); // Desktops
      }
    };

    determineDefaultView(); // Set the default view on mount

    // Optional: Add an event listener to handle window resizing
    window.addEventListener("resize", determineDefaultView);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", determineDefaultView);
    };
  }, []);

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const timetableResponse = await api.get(
          `/api/timetables/${timetableId}`
        );
        setTimetable(timetableResponse.data);

        const instructorResponse = await api.get(
          `/api/timetables/${timetableId}/instructors`
        );
        setInstructors(instructorResponse.data);

        const classesResponse = await api.get(
          `/api/timetables/${timetableId}/classes`
        );
        setClasses(classesResponse.data);

        const eventsResponse = await api.get(
          `/api/timetables/${timetableId}/events`
        );
        setEvents(eventsResponse.data);
        setFilteredEvents(eventsResponse.data); // Set initial filtered events
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    };

    fetchData();
  }, [timetableId]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--header-bg-color",
      timetable.header_colour || "#0061f2"
    );
    document.documentElement.style.setProperty(
      "--header-text-color",
      timetable.header_text_colour || "white"
    );
  }, [timetable]);

  // Filter events based on class level
  useEffect(() => {
    filterEvents(classLevel, classType, classInstructor);
  }, [classLevel, classType, classInstructor, events]);

  const filterEvents = (level, title, instructor) => {
    let newFilteredEvents = events;

    if (level && level !== "All Levels") {
      newFilteredEvents = newFilteredEvents.filter(
        (event) => event.level === level
      );
    }

    if (title && title !== "All Classes") {
      newFilteredEvents = newFilteredEvents.filter(
        (event) => event.title === title
      );
    }

    if (instructor && instructor !== "All Instructors") {
      newFilteredEvents = newFilteredEvents.filter((event) =>
        event.instructors.some((inst) => inst.name === instructor)
      );
    }

    setFilteredEvents(newFilteredEvents);
  };

  const handleSelectSlot = ({ start }) => {
    setEventStart(start);
    // Set end time to one hour after the start time
    const end = new Date(start);
    end.setHours(start.getHours() + 1);
    setSelectedClass("");
    setIsRecurring(false);
    setEventEnd(end);
    setShowCreateEvent(true);
    setError("");
    setRescheduleRecurringEventError("");
  };

  const handleRRuleChange = (newRRule) => {
    setRecurrenceRule(newRRule);
  };

  const handleRescheduledRRuleChange = (RRule) => {
    setRescheduledRecurrenceRule(RRule);
  };

  const handleCloseCreateEvent = () => {
    setShowCreateEvent(false);
    setEventStart(new Date());
    setEventEnd(new Date());
  };

  const handleCreateEvent = async () => {
    setEventStartError("");
    setEventEndError("");
    setSelectedClassError("");

    if (!selectedClass) {
      setSelectedClassError("Class is required");
      return;
    }

    if (!eventStart) {
      setEventStartError("Event Start date is required");
      return;
    } else {
      eventStart.setSeconds(0);
      eventStart.setMilliseconds(0);
    }

    if (!eventEnd) {
      setEventEndError("Event End date is required");
      return;
    } else {
      eventEnd.setSeconds(0);
      eventEnd.setMilliseconds(0);
    }

    if (eventEnd <= eventStart) {
      setEventEndError("Event end date must be after the event start date");
      return;
    }

    try {
      const token = localStorage.getItem("tt_jwt");
      await api.post(
        "/api/events",
        {
          start: eventStart.toISOString(),
          end: eventEnd.toISOString(),
          classId: selectedClass,
          recurrence_rule: recurrenceRule,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowCreateEvent(false);
      // Refresh events
      const response = await api.get(`/api/timetables/${timetableId}/events`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEvents(response.data);
    } catch (err) {
      if (err.response) {
        if (err.response.data?.message) {
          setError(err.response.data.message);
        } else {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  const getClassTypes = () => {
    const types = classes.map((classItem) => ({
      value: classItem.title,
      label: classItem.title,
    }));

    const uniqueTypes = Array.from(
      new Map(types.map((item) => [item.value, item])).values()
    );

    return [{ value: "", label: "All Classes" }, ...uniqueTypes];
  };

  const getInstructorNames = () => {
    const names = instructors.map((instructorItem) => ({
      value: instructorItem.name,
      label: instructorItem.name,
    }));
    return [{ value: "", label: "All Instructors" }, ...new Set(names)];
  };

  useEffect(() => {
    if (selectedEvent) {
      setRescheduledRecurringEventStart(new Date(selectedEvent.start));
      setRescheduledRecurringEventEnd(new Date(selectedEvent.end));
      setRescheduledEventStart(new Date(selectedEvent.start));
      setRescheduledEventEnd(new Date(selectedEvent.end));
    }
  }, [selectedEvent]);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setSelectedAction(null);
    setRescheduleEventEndError("");
    setShowModal(true);
    setError("");
    setRescheduleRecurringEventError("");
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
    setSelectedAction(null);
    setRescheduledEventReason("");
    setCancelEventReason("");
  };

  const handleCancelEvent = async () => {
    if (window.confirm("Are you sure you want to cancel this event?")) {
      try {
        const token = localStorage.getItem("tt_jwt");
        await api.post(
          "/api/events/cancel",
          {
            event_id: selectedEvent.id,
            cancelled_at: selectedEvent.start.toISOString(),
            reason: cancelEventReason,
            timetable_id: timetableId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShowModal(false);
        setActiveTab("details");
        // Refresh events
        const response = await api.get(
          `/api/timetables/${timetableId}/events`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEvents(response.data);
        setCancelEventReason("");
      } catch (err) {
        setCancelEventReason("");
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    }
  };

  const handleDeleteEvent = async () => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        const token = localStorage.getItem("tt_jwt");
        await api.delete(`/api/events/${selectedEvent.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setShowModal(false);
        setActiveTab("details");
        // Refresh events
        const response = await api.get(
          `/api/timetables/${timetableId}/events`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEvents(response.data);
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    }
  };

  const handleRescheduleEvent = async () => {
    setRescheduleEventEndError("");

    if (rescheduledEventEnd <= rescheduledEventStart) {
      setRescheduleEventEndError(
        "Event end date must be after the event start date"
      );
      return;
    }

    if (
      selectedEvent.start.toISOString() ==
        rescheduledEventStart.toISOString() &&
      selectedEvent.end.toISOString() == rescheduledEventEnd.toISOString()
    ) {
      setError("The event is already scheduled on the provided dates.");
      return;
    }

    if (window.confirm("Are you sure you want to reschedule this event?")) {
      try {
        const token = localStorage.getItem("tt_jwt");
        const old_start_date =
          selectedEvent.old_start_date || selectedEvent.start;
        rescheduledEventStart.setSeconds(0);
        rescheduledEventStart.setMilliseconds(0);
        rescheduledEventEnd.setSeconds(0);
        rescheduledEventEnd.setMilliseconds(0);
        await api.post(
          `/api/events/reschedule`,
          {
            event_id: selectedEvent.id,
            old_start: old_start_date.toISOString(),
            new_start: rescheduledEventStart.toISOString(),
            new_end: rescheduledEventEnd.toISOString(),
            reason: rescheduledEventReason,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShowModal(false);
        setActiveTab("details");
        // Refresh events
        const response = await api.get(
          `/api/timetables/${timetableId}/events`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEvents(response.data);
        setRescheduledEventReason("");
      } catch (err) {
        setRescheduledEventReason("");
        if (err.response) {
          if (err.response.data?.message) {
            setError(err.response.data.message);
          } else {
            switch (err.response.status) {
              case 400:
                navigate("/bad-request");
                break;
              case 402:
                navigate("/payment-required");
                break;
              case 401:
                navigate("/auth/login");
                break;
              case 500:
                navigate("/server-error");
                break;
              default:
                navigate("/not-found");
            }
          }
        } else {
          navigate("/server-error");
        }
      }
    }
  };

  const handleRescheduleRecurringEvent = async () => {
    if (rescheduledRecurringEventEnd <= rescheduledRecurringEventStart) {
      setRescheduleRecurringEventEndError(
        "Event end date must be after the event start date"
      );
      return;
    }

    if (window.confirm("Are you sure you want to reschedule this event?")) {
      try {
        const token = localStorage.getItem("tt_jwt");
        rescheduledRecurringEventStart.setSeconds(0);
        rescheduledRecurringEventStart.setMilliseconds(0);
        rescheduledRecurringEventEnd.setSeconds(0);
        rescheduledRecurringEventEnd.setMilliseconds(0);
        await api.post(
          `/api/events/reschedule-recurring`,
          {
            event_id: selectedEvent.id,
            start: rescheduledRecurringEventStart.toISOString(),
            end: rescheduledRecurringEventEnd.toISOString(),
            finish_date: selectedEvent.start.toISOString(),
            recurrence_rule: rescheduledRecurrenceRule,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShowModal(false);
        setActiveTab("details");
        // Refresh events
        const response = await api.get(
          `/api/timetables/${timetableId}/events`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEvents(response.data);
      } catch (err) {
        if (err.response) {
          if (err.response.data?.message) {
            setRescheduleRecurringEventError(err.response.data.message);
          } else {
            switch (err.response.status) {
              case 400:
                navigate("/bad-request");
                break;
              case 402:
                navigate("/payment-required");
                break;
              case 401:
                navigate("/auth/login");
                break;
              case 500:
                navigate("/server-error");
                break;
              default:
                navigate("/not-found");
            }
          }
        } else {
          navigate("/server-error");
        }
      }
    }
  };

  const handleCancelRecurringEvent = async () => {
    if (
      window.confirm("Are you sure you want to cancel this recurring event?")
    ) {
      try {
        const token = localStorage.getItem("tt_jwt");
        await api.post(
          `/api/events/cancel-recurring`,
          {
            event_id: selectedEvent.id,
            finish_date: selectedEvent.start.toISOString(),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShowModal(false);
        setActiveTab("details");
        // Refresh events
        const response = await api.get(
          `/api/timetables/${timetableId}/events`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEvents(response.data);
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    }
  };

  const handleRecreateEvent = async () => {
    if (window.confirm("Are you sure you want to recreate this event?")) {
      try {
        const token = localStorage.getItem("tt_jwt");
        await api.post(
          "/api/events/recreate",
          {
            event_id: selectedEvent.id,
            cancelled_at: selectedEvent.start.toISOString(),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShowModal(false);
        setActiveTab("details");
        // Refresh events
        const response = await api.get(
          `/api/timetables/${timetableId}/events`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEvents(response.data);
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    }
  };

  const eventList = filteredEvents
    .filter((event) => !(event.cancelled && hideCancelledEvents)) // Filter out cancelled events if hideCancelledEvents is false
    .map((event) => ({
      id: event.id,
      start: new Date(event.start),
      end: new Date(event.end),
      title: event.title,
      description: event.description,
      display_colour: event.display_colour,
      level: event.level,
      location: event.location,
      cost: event.cost,
      additional_notes: event.additional_notes,
      equipment: event.equipment,
      requirements: event.requirements,
      cancelled: event.cancelled,
      rrule: event.rrule,
      old_start_date: event.old_start_date
        ? new Date(event.old_start_date)
        : event.old_start_date,
      reason: event.reason,
      // Handle multiple instructors
      instructors: event.instructors.map((instructor) => ({
        name: instructor.name,
        bio: instructor.bio,
        fb: instructor.fb,
        insta: instructor.insta,
        image_url: instructor.image_url,
      })),
    }));

  const classOptions = classes.map((cls) => ({
    value: cls.id,
    label: cls.level ? `${cls.title} - ${cls.level}` : cls.title,
  }));

  const eventStyleGetter = (event) => {
    const backgroundColor = "#F5F5F5";
    return {
      style: {
        backgroundColor,
        color: "black",
        border: "1px solid transparent", // Ensure border is not visible
        borderTop: `5px solid ${event.display_colour || "red"}`, // Custom border on top
      },
    };
  };

  const components = useMemo(
    () => ({
      event: CustomEvent, // used by each view (Month, Day, Week)
      toolbar: CustomToolbar,
    }),
    []
  );

  const classLevelOptions = [
    { value: "", label: "All Levels" },
    { value: "Beginner", label: "Beginner" },
    { value: "Intermediate", label: "Intermediate" },
    { value: "Advanced", label: "Advanced" },
  ];

  const selectActions = () => {
    if (selectedEvent?.rrule == "") {
      return [
        { value: "Delete Event", label: "Delete Event" },
        { value: "Reschedule Event", label: "Reschedule Event" },
        { value: "Cancel Event", label: "Cancel Event" },
      ];
    } else {
      return [
        { value: "Delete Event", label: "Delete Event" },
        { value: "Reschedule Event", label: "Reschedule Event" },
        {
          value: "Reschedule All Recurring Events",
          label: "Reschedule All Recurring Events",
        },
        { value: "Cancel Event", label: "Cancel Event" },
        {
          value: "Cancel All Recurring Events",
          label: "Cancel All Recurring Events",
        },
      ];
    }
  };

  const actionDescriptions = {
    "Delete Event": `Deleting an event will remove it (and any recurring events
                  associated with it) without sending a notification to any
                  subscribers. This should typically be used for reverting
                  mistakes.

                  e.g. I accidently created a Kickboxing event every Monday, so I will delete it without sending
                  any notifications.`,
    "Reschedule Event": `Rescheduling the event will change the date and time of the selected event only.
                      
                      e.g. Kickboxing is every Monday at 10am. This Monday we have to reschedule it to 11am but
                      every Monday after that it will remain at 10am.`,
    "Reschedule All Recurring Events": `Rescheduling all recurring events will change the date and time of all associated recurring events
    from this date onwards.
                      
                      e.g. Kickboxing is every Monday at 10am. We want to change this to be every Tuesday at 10am.`,
    "Cancel Event": `Cancelling an event will send a notification to any
                  class subscribers. The event will remain on the timetable displayed
                  as cancelled. All other recurring events will
                  remain as they are.

                  e.g. There is a Kickboxing event every Monday and I wish to cancel this
                  event just for the selected date.`,
    "Cancel All Recurring Events": `Cancelling all recurring events will cancel this event and all associated recurring events.
                  Any class subscribers will be notified and the events will be removed from the timetable.

                  e.g. There is a Kickboxing event every Monday and I wish to cancel this event every
                  week, whilst still notifying any class subscribers.`,
  };

  const handleActionChange = (selectedOption) => {
    setSelectedAction(selectedOption);
  };

  const handleViewChange = (view) => {
    setDefaultView(view);
  };

  const handlePerformAction = () => {
    if (selectedAction.value == "Delete Event") {
      handleDeleteEvent();
    } else if (selectedAction.value == "Cancel Event") {
      handleCancelEvent();
    } else if (selectedAction.value == "Reschedule All Recurring Events") {
      handleRescheduleRecurringEvent();
    } else if (selectedAction.value == "Reschedule Event") {
      handleRescheduleEvent();
    } else if (selectedAction.value == "Cancel All Recurring Events") {
      handleCancelRecurringEvent();
    }
  };

  return (
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh" }}>
      <Layout>
        <Container>
          <Row className="mb-4 mt-2">
            <Col xs={12}>
              <CompanyProfileCard
                companyName={timetable.title}
                description={timetable.description}
                websiteLink={timetable.website}
                addressLine1={timetable.address_line_1}
                addressLine2={timetable.address_line_2}
                city={timetable.city}
                country={timetable.country}
                postcode={timetable.postcode}
                email={timetable.email}
                phone={timetable.phone}
                facebookLink={timetable.facebook}
                instagramLink={timetable.instagram}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-4">
              <Card>
                <Card.Body>
                  <div>
                    <Row className="mb-4 align-items-center">
                      <Col xs={12} md={2} className="mb-3">
                        <Form.Group controlId="formClassLevel" className="mb-0">
                          <Select
                            options={classLevelOptions}
                            value={classLevelOptions.find(
                              (option) => option.value === classLevel
                            )}
                            onChange={(option) =>
                              setClassLevel(option ? option.value : "")
                            }
                            placeholder="Select Level"
                            styles={{
                              container: (provided) => ({
                                ...provided,
                                maxWidth: "100%",
                              }),
                              control: (provided) => ({
                                ...provided,
                                border: "0.5px solid", // Customize border color
                                boxShadow: "none", // Remove default shadow
                                "&:hover": {
                                  border: "1px solid #0056b3", // Change border color on hover
                                },
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                color: "", // Match the arrow color
                                "&:hover": {
                                  color: "#0056b3", // Change arrow color on hover
                                },
                              }),
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={2} className="mb-3">
                        <Form.Group controlId="formClassType" className="mb-0">
                          <Select
                            options={getClassTypes()}
                            value={getClassTypes().find(
                              (option) => option.value === classType
                            )}
                            onChange={(option) =>
                              setClassType(option ? option.value : "")
                            }
                            placeholder="Select Class Type"
                            styles={{
                              container: (provided) => ({
                                ...provided,
                                maxWidth: "100%",
                              }),
                              control: (provided) => ({
                                ...provided,
                                border: "0.5px solid", // Customize border color
                                boxShadow: "none", // Remove default shadow
                                "&:hover": {
                                  border: "1px solid #0056b3", // Change border color on hover
                                },
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                color: "", // Match the arrow color
                                "&:hover": {
                                  color: "#0056b3", // Change arrow color on hover
                                },
                              }),
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={2} className="mb-3">
                        <Form.Group
                          controlId="formClassInstructor"
                          className="mb-0"
                        >
                          <Select
                            options={getInstructorNames()}
                            value={getInstructorNames().find(
                              (option) => option.value === classInstructor
                            )}
                            onChange={(option) =>
                              setClassInstructor(option ? option.value : "")
                            }
                            placeholder="Select Instructor"
                            styles={{
                              container: (provided) => ({
                                ...provided,
                                maxWidth: "100%",
                              }),
                              control: (provided) => ({
                                ...provided,
                                border: "0.5px solid", // Customize border color
                                boxShadow: "none", // Remove default shadow
                                "&:hover": {
                                  border: "1px solid #0056b3", // Change border color on hover
                                },
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                color: "", // Match the arrow color
                                "&:hover": {
                                  color: "#0056b3", // Change arrow color on hover
                                },
                              }),
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={2} className="ml-5">
                        {/* Checkbox to toggle showing cancelled events */}
                        <Form.Group
                          controlId="hideCancelledEvents"
                          className="mb-3"
                        >
                          <Form.Check
                            type="checkbox"
                            label="Hide Cancelled Events"
                            checked={hideCancelledEvents} // Bind checkbox to state
                            onChange={() =>
                              setHideCancelledEvents((prev) => !prev)
                            } // Toggle state on change
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Tabs
                      id="view-switcher"
                      activeKey={viewTab}
                      onSelect={(key) => setViewTab(key)}
                      className="mb-3"
                    >
                      <Tab eventKey="calendar" title="Calendar View">
                        <div className="calendar-border">
                          <Calendar
                            localizer={localizer}
                            events={eventList}
                            startAccessor="start"
                            endAccessor="end"
                            views={["week", "day"]}
                            style={{
                              height: "calc(100vh - 50px)",
                              width: "100%",
                            }}
                            selectable
                            onSelectSlot={handleSelectSlot}
                            onSelectEvent={handleEventClick}
                            eventPropGetter={eventStyleGetter}
                            scrollToTime={scrollToTime}
                            view={defaultView}
                            onView={handleViewChange}
                            date={currentDate}
                            onNavigate={handleNavigate}
                            components={components}
                            longPressThreshold={10}
                          />
                        </div>
                      </Tab>
                      <Tab eventKey="dayCards" title="Day View">
                        <EventsCards
                          onEventClick={handleEventClick}
                          events={eventList}
                          headerColor={timetable.header_colour || "#007bff"}
                          headerTextColor={
                            timetable.header_text_colour || "white"
                          }
                        />
                      </Tab>
                    </Tabs>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedEvent?.title}{" "}
              {selectedEvent?.level ? `- ${selectedEvent.level}` : ""}
            </Modal.Title>
            {selectedEvent?.cancelled && (
              <Badge bg="danger" className="ml-2">
                Cancelled
              </Badge>
            )}
            {selectedEvent?.old_start_date && (
              <Badge bg="warning" className="ml-2">
                Rescheduled from:&nbsp;
                {new Date(selectedEvent?.old_start_date).toLocaleDateString(
                  undefined,
                  {
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}
              </Badge>
            )}
          </Modal.Header>
          <Modal.Body>
            {/* Tabs for navigation */}
            <div className="mb-3">
              <Nav
                variant="tabs"
                activeKey={activeTab}
                onSelect={(tab) => setActiveTab(tab)}
              >
                <Nav.Item>
                  <Nav.Link eventKey="details">Event Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="instructor">Instructor Profiles</Nav.Link>
                </Nav.Item>
                {!selectedEvent?.cancelled && (
                  <Nav.Item>
                    <Nav.Link eventKey="actions">Actions</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </div>

            {activeTab === "instructor" && (
              <>
                {selectedEvent?.instructors &&
                selectedEvent.instructors.length > 0 ? (
                  selectedEvent.instructors.map((instructor, index) => (
                    <div key={instructor.id} className="mb-4">
                      {/* Display instructor image if available */}
                      {instructor.image_url && (
                        <div className="mb-3">
                          <img
                            src={instructor.image_url}
                            alt="Instructor"
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      )}
                      {/* Display Name */}
                      {instructor.name && (
                        <Card.Text>
                          <strong>Name</strong>
                          <br />
                          {instructor.name}
                        </Card.Text>
                      )}
                      {/* Display Bio */}
                      {instructor.bio && (
                        <Card.Text>
                          <strong>Bio</strong>
                          <br />
                          {instructor.bio}
                        </Card.Text>
                      )}
                      {/* Display social media links */}
                      <div className="mt-3">
                        {instructor.fb && (
                          <a
                            href={instructor.fb}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mr-3"
                          >
                            <FontAwesomeIcon icon={faFacebook} size="2x" />
                          </a>
                        )}
                        {instructor.insta && (
                          <a
                            href={instructor.insta}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon icon={faInstagram} size="2x" />
                          </a>
                        )}
                      </div>
                      {/* Horizontal separator */}
                      {index < selectedEvent.instructors.length - 1 && <hr />}
                    </div>
                  ))
                ) : (
                  <Card.Text style={{ color: "grey" }}>
                    No instructor profiles for this class.
                  </Card.Text>
                )}
              </>
            )}

            {activeTab === "details" && (
              <>
                <Card.Text>{selectedEvent?.description}</Card.Text>
                <Card.Text>
                  <strong>Date & Time:</strong>
                  <br />
                  {moment(selectedEvent?.start).format(
                    "DD/MM/YYYY HH:mm"
                  )} - {moment(selectedEvent?.end).format("DD/MM/YYYY HH:mm")}
                </Card.Text>
                <Card.Text>
                  {selectedEvent?.location && (
                    <>
                      <strong>Location:</strong>
                      <br />
                      {selectedEvent.location}
                      <br />
                    </>
                  )}

                  {selectedEvent?.instructors &&
                    selectedEvent.instructors.length > 0 && (
                      <>
                        <strong>Instructor(s):</strong>
                        <br />
                        {selectedEvent.instructors.map((instructor, index) => (
                          <span key={index}>
                            {instructor.name}
                            {index < selectedEvent.instructors.length - 1
                              ? ", "
                              : ""}
                          </span>
                        ))}
                        <br />
                      </>
                    )}

                  {selectedEvent?.level && (
                    <>
                      <strong>Level:</strong>
                      <br />
                      {selectedEvent.level}
                      <br />
                    </>
                  )}
                </Card.Text>
                <Accordion defaultActiveKey="1" className="mb-2">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Additional Info</Accordion.Header>
                    <Accordion.Body>
                      <Card.Text>
                        {selectedEvent?.cost ? (
                          <>
                            <strong>Cost:</strong>
                            <br />
                            {selectedEvent.cost}
                            <br />
                          </>
                        ) : null}

                        {selectedEvent?.equipment ? (
                          <>
                            <strong>Equipment:</strong>
                            <br />
                            {selectedEvent.equipment}
                            <br />
                          </>
                        ) : null}

                        {selectedEvent?.requirements ? (
                          <>
                            <strong>Requirements:</strong>
                            <br />
                            {selectedEvent.requirements}
                            <br />
                          </>
                        ) : null}

                        {selectedEvent?.additional_notes ? (
                          <>
                            <strong>Notes:</strong>
                            <br />
                            {selectedEvent.additional_notes}
                            <br />
                          </>
                        ) : null}

                        {selectedEvent?.display_colour ? (
                          <>
                            <strong>Display Colour:</strong>
                            <br />
                            <div
                              className="mt-2"
                              style={{
                                width: "30px",
                                height: "30px",
                                backgroundColor: selectedEvent.display_colour,
                                borderRadius: "10%",
                                border: "1px solid #000",
                              }}
                            />
                          </>
                        ) : null}

                        {/* Placeholder text if none of the fields are defined */}
                        {!selectedEvent?.cost &&
                        !selectedEvent?.equipment &&
                        !selectedEvent?.requirements &&
                        !selectedEvent?.additional_notes &&
                        !selectedEvent?.display_colour ? (
                          <span>No additional information available.</span>
                        ) : null}
                      </Card.Text>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {selectedEvent?.cancelled ? (
                  <div>
                    <strong>Cancellation Reason:</strong>
                    <br />
                    <span>
                      {selectedEvent?.reason
                        ? selectedEvent.reason
                        : "No reason provided."}
                    </span>
                    <br />
                    <Alert variant="warning" className="mt-4" dismissible>
                      The event was cancelled. Click the button below to
                      reinstate the event. Any class subscribers will then be
                      notified.
                    </Alert>
                    <Button
                      variant="danger"
                      onClick={handleRecreateEvent}
                      className="w-100 mt-2"
                    >
                      Recreate Event
                    </Button>
                  </div>
                ) : null}
              </>
            )}

            {activeTab === "actions" && (
              <div>
                <Form.Group className="mt-4 mb-4">
                  {/* React Select Dropdown for Actions */}
                  <Select
                    value={selectedAction}
                    onChange={handleActionChange}
                    options={selectActions()}
                    placeholder="Choose an action..."
                  />
                </Form.Group>

                {/* Conditionally Render Alert */}
                {selectedAction && (
                  <Alert variant="info" className="mt-3">
                    {actionDescriptions[selectedAction.value]}
                  </Alert>
                )}

                {selectedAction &&
                  selectedAction.value ===
                    "Reschedule All Recurring Events" && (
                    <div className="mt-3">
                      <Form.Group controlId="formRescheduleEventStart">
                        <Form.Label>
                          Start Date and Time{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="datetime-local"
                          value={moment(rescheduledRecurringEventStart).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          onChange={(e) =>
                            setRescheduledRecurringEventStart(
                              new Date(e.target.value)
                            )
                          }
                        />
                      </Form.Group>

                      <Form.Group controlId="formRescheduleEventEnd">
                        <Form.Label>
                          End Date and Time{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="datetime-local"
                          value={moment(rescheduledRecurringEventEnd).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          onChange={(e) =>
                            setRescheduledRecurringEventEnd(
                              new Date(e.target.value)
                            )
                          }
                          isInvalid={!!rescheduleRecurringEventEndError}
                        />
                        <Form.Control.Feedback type="invalid">
                          {rescheduleRecurringEventEndError}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="formRRule">
                        <RRuleInput
                          onRRuleChange={handleRescheduledRRuleChange}
                        />
                      </Form.Group>

                      <Button
                        variant="danger"
                        className="mt-1"
                        onClick={handlePerformAction}
                      >
                        {selectedAction.label}
                      </Button>
                      {rescheduleRecurringEventError && (
                        <Alert variant="danger" className="mt-4">
                          {rescheduleRecurringEventError}
                        </Alert>
                      )}
                    </div>
                  )}

                {selectedAction &&
                  selectedAction.value === "Reschedule Event" && (
                    <div className="mt-3">
                      <Form.Group controlId="formRescheduleEventStart">
                        <Form.Label>
                          Start Date and Time{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="datetime-local"
                          value={moment(rescheduledEventStart).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          onChange={(e) =>
                            setRescheduledEventStart(new Date(e.target.value))
                          }
                        />
                      </Form.Group>

                      <Form.Group controlId="formRescheduleEventEnd">
                        <Form.Label>
                          End Date and Time{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="datetime-local"
                          value={moment(rescheduledEventEnd).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          onChange={(e) =>
                            setRescheduledEventEnd(new Date(e.target.value))
                          }
                          isInvalid={!!rescheduleEventEndError}
                        />
                        <Form.Control.Feedback type="invalid">
                          {rescheduleEventEndError}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Alert variant="warning" className="mt-3" dismissible>
                        If you wish to let your subscribers know the reason for
                        rescheduling, please do so below. Any subscribers to
                        this class will be sent this as a part of their
                        notification.
                      </Alert>
                      <Form.Group controlId="formRescheduleEventEnd">
                        <Form.Label>Reason for Rescheduling</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={rescheduledEventReason}
                          onChange={(e) =>
                            setRescheduledEventReason(e.target.value)
                          }
                        />
                      </Form.Group>

                      <Button
                        variant="danger"
                        className="mt-4"
                        onClick={handlePerformAction}
                      >
                        {selectedAction.label}
                      </Button>
                      {error && (
                        <Alert className="mt-4" variant="danger">
                          {error}
                        </Alert>
                      )}
                    </div>
                  )}

                {selectedAction && selectedAction.value === "Cancel Event" && (
                  <div className="mt-3">
                    <Alert variant="warning" className="mt-3" dismissible>
                      If you wish to let your subscribers know the reason for
                      cancelling, please do so below. Any subscribers to this
                      class will be sent this as a part of their notification.
                    </Alert>
                    <Form.Group controlId="formRescheduleEventEnd">
                      <Form.Label>Reason for Cancelling</Form.Label>
                      <Form.Control
                        as="textarea"
                        value={cancelEventReason}
                        onChange={(e) => setCancelEventReason(e.target.value)}
                      />
                    </Form.Group>

                    <Button
                      variant="danger"
                      className="mt-4"
                      onClick={handlePerformAction}
                    >
                      {selectedAction.label}
                    </Button>
                  </div>
                )}

                {selectedAction &&
                  selectedAction.value !== "Reschedule All Recurring Events" &&
                  selectedAction.value !== "Reschedule Event" &&
                  selectedAction.value !== "Cancel Event" && (
                    <Button
                      variant="danger"
                      className="mt-1"
                      onClick={handlePerformAction}
                    >
                      {selectedAction.label}
                    </Button>
                  )}
              </div>
            )}
          </Modal.Body>
        </Modal>

        <Modal show={showCreateEvent} onHide={handleCloseCreateEvent} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Create Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formEventClass">
                <Form.Label>
                  Select Class <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Select
                  value={classOptions.find(
                    (option) => option.value === selectedClass
                  )}
                  onChange={(selectedOption) =>
                    setSelectedClass(selectedOption.value)
                  }
                  options={classOptions}
                  placeholder="Select a class"
                  className={selectedClassError ? "is-invalid" : ""}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: selectedClassError ? "block" : "none" }}
                >
                  {selectedClassError}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="mb-2 mt-2">
                No classes yet?&nbsp;
                <Link to={`/timetable/${timetableId}/classes`}>
                  Create a class
                </Link>
              </div>

              <Form.Group controlId="formEventStart">
                <Form.Label>
                  Start Date and Time <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={moment(eventStart).format("YYYY-MM-DDTHH:mm")}
                  onChange={(e) => setEventStart(new Date(e.target.value))}
                  isInvalid={!!eventStartError}
                />
                <Form.Control.Feedback type="invalid">
                  {eventStartError}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formEventEnd">
                <Form.Label>
                  End Date and Time <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={moment(eventEnd).format("YYYY-MM-DDTHH:mm")}
                  onChange={(e) => setEventEnd(new Date(e.target.value))}
                  isInvalid={!!eventEndError}
                />
                <Form.Control.Feedback type="invalid">
                  {eventEndError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formIsRecurring" className="mt-4">
                <Form.Check
                  type="checkbox"
                  label="Is this a recurring event?"
                  checked={isRecurring}
                  onChange={(e) => setIsRecurring(e.target.checked)}
                />
              </Form.Group>

              {isRecurring && (
                <Form.Group controlId="formRRule">
                  <RRuleInput onRRuleChange={handleRRuleChange} />
                </Form.Group>
              )}

              <Button
                variant="primary"
                className="w-100 mt-4"
                onClick={handleCreateEvent}
              >
                Publish Event
              </Button>
              {error && (
                <Alert className="mt-4" variant="danger">
                  {error}
                </Alert>
              )}
            </Form>
          </Modal.Body>
        </Modal>
      </Layout>
    </div>
  );
};

export default Timetable;
