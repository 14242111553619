import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { RRule } from "rrule";
import Select from "react-select";

const RRuleInput = ({ onRRuleChange }) => {
  const [frequency, setFrequency] = useState(RRule.WEEKLY);
  const [interval, setInterval] = useState(1);

  const frequencies = [
    { label: "Daily", value: RRule.DAILY },
    { label: "Weekly", value: RRule.WEEKLY },
  ];

  useEffect(() => {
    const rule = new RRule({
      freq: frequency,
      interval: interval,
    });
    onRRuleChange(rule.toString());
  }, [frequency, interval, onRRuleChange]);

  return (
    <Form>
      {/* Frequency Selection */}
      <Form.Group as={Row} controlId="frequency" className="mt-2 mb-2">
        <Form.Label column sm={2}>
          Frequency <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Col sm={10}>
          <Select
            value={frequencies.find((freq) => freq.value === frequency)}
            onChange={(selectedOption) => setFrequency(selectedOption.value)}
            options={frequencies}
          />
        </Col>
      </Form.Group>

      {/* Interval Input */}
      <Form.Group as={Row} controlId="interval">
        <Form.Label column sm={2}>
          Interval <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            type="number"
            value={interval}
            onChange={(e) => setInterval(Number(e.target.value))}
            min="1"
          />
          <Form.Text className="text-muted">
            Specify how often the event repeats (e.g., every 1 week, 2 weeks,
            etc.).
          </Form.Text>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default RRuleInput;
