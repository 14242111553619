import React, { useState, useEffect } from "react";
import {
  Alert,
  Container,
  Card,
  Button,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import api from "../axiosInstance";

const Instructors = () => {
  const [instructors, setInstructors] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [nameError, setNameError] = useState("");
  const [bioError, setBioError] = useState("");
  const [error, setError] = useState("");
  const [instagramError, setInstagramError] = useState("");
  const [facebookError, setFacebookError] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [newInstructor, setNewInstructor] = useState({
    name: "",
    bio: "",
    image_url: "", // Add image URL here
    timetable_id: useParams()["id"],
  });
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const navigate = useNavigate();

  const timetableId = useParams()["id"];

  const fetchInstructors = async () => {
    try {
      const response = await api.get(
        `/api/timetables/${timetableId}/instructors`
      );
      setInstructors(response.data);
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            navigate("/bad-request");
            break;
          case 402:
            navigate("/payment-required");
            break;
          case 401:
            navigate("/auth/login");
            break;
          case 500:
            navigate("/server-error");
            break;
          default:
            navigate("/not-found");
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  useEffect(() => {
    fetchInstructors();
  }, [timetableId]);

  // Inside your Instructors component
  const handleCreateInstructor = async () => {
    setNameError("");
    setBioError("");
    setInstagramError("");
    setFacebookError("");

    if (!newInstructor.name.trim()) {
      setNameError("Name is required");
      return;
    }

    if (!newInstructor.bio.trim()) {
      setBioError("Bio is required");
      return;
    }

    if (newInstructor.facebook) {
      try {
        new URL(newInstructor.facebook);
      } catch (error) {
        setFacebookError(
          "Invalid URL format. Please ensure the URL starts with 'http://' or 'https://' and follows the correct format."
        );
        return;
      }
    }

    if (newInstructor.instagram) {
      try {
        new URL(newInstructor.instagram);
      } catch (error) {
        setInstagramError(
          "Invalid URL format. Please ensure the URL starts with 'http://' or 'https://' and follows the correct format."
        );
        return;
      }
    }

    try {
      const token = localStorage.getItem("tt_jwt");

      // Create a FormData object
      const formData = new FormData();
      formData.append("name", newInstructor.name);
      formData.append("bio", newInstructor.bio);

      if (newInstructor.facebook) {
        formData.append("facebook", newInstructor.facebook);
      }

      if (newInstructor.instagram) {
        formData.append("instagram", newInstructor.instagram);
      }

      // Append the image file if it exists
      if (newInstructor.image) {
        formData.append("image", newInstructor.image);
      }

      formData.append("timetable_id", newInstructor.timetable_id);

      await api.post("/api/instructor", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setShowCreateModal(false);
      setNewInstructor({
        name: "",
        bio: "",
        image: null,
        timetable_id: timetableId,
      });
      fetchInstructors();
      setError("");
    } catch (err) {
      if (err.response) {
        if (err.response.data?.message) {
          setError(err.response.data.message);
        } else {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  const handleDeleteInstructor = async (id) => {
    if (window.confirm("Are you sure you want to delete this instructor?")) {
      try {
        const token = localStorage.getItem("tt_jwt");
        await api.delete(`/api/instructors/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        fetchInstructors();
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    }
  };

  const handleUpdateInstructor = async () => {
    if (!selectedInstructor) return;

    setNameError("");
    setBioError("");
    setInstagramError("");
    setFacebookError("");

    if (!selectedInstructor.name.trim()) {
      setNameError("Name is required");
      return;
    }

    if (!selectedInstructor.bio.trim()) {
      setBioError("Bio is required");
      return;
    }

    if (selectedInstructor.facebook) {
      try {
        new URL(selectedInstructor.facebook);
      } catch (error) {
        setFacebookError(
          "Invalid URL format. Please ensure the URL starts with 'http://' or 'https://' and follows the correct format."
        );
        return;
      }
    }

    if (selectedInstructor.instagram) {
      try {
        new URL(selectedInstructor.instagram);
      } catch (error) {
        setInstagramError(
          "Invalid URL format. Please ensure the URL starts with 'http://' or 'https://' and follows the correct format."
        );
        return;
      }
    }

    try {
      const token = localStorage.getItem("tt_jwt");

      const formData = new FormData();
      formData.append("name", selectedInstructor.name);
      formData.append("bio", selectedInstructor.bio);

      if (selectedInstructor.facebook) {
        formData.append("facebook", selectedInstructor.facebook);
      }

      if (selectedInstructor.instagram) {
        formData.append("instagram", selectedInstructor.instagram);
      }

      if (selectedInstructor.image) {
        formData.append("image", newInstructor.image);
      }

      formData.append("timetable_id", selectedInstructor.timetable_id);

      await api.put(
        `/api/instructors/${selectedInstructor.id}`,
        {
          name: selectedInstructor.name,
          bio: selectedInstructor.bio,
          facebook: selectedInstructor.facebook,
          instagram: selectedInstructor.instagram,
          image: selectedInstructor.image,
          timetable_id: selectedInstructor.timetable_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Set content type for FormData
          },
        }
      );
      setShowEditModal(false);
      setSelectedInstructor(null);
      setError("");
      fetchInstructors();
    } catch (err) {
      if (err.response) {
        if (err.response.data?.message) {
          setError(err.response.data.message);
        } else {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  const handleShowCreateModal = () => setShowCreateModal(true);
  const handleCloseCreateModal = () => setShowCreateModal(false);
  const handleShowEditModal = (selected_instructor) => {
    setSelectedInstructor(selected_instructor);
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => {
    setSelectedInstructor(null);
    setShowEditModal(false);
    setError("");
  };

  return (
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh" }}>
      <Layout>
        <Container className="mt-4">
          <Row className="align-items-center">
            <Col>
              <h3>Manage Instructor Profiles</h3>
            </Col>
            <Col className="text-end">
              <Button
                variant="outline-primary"
                onClick={handleShowCreateModal}
                className="mr-8"
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className={window.innerWidth < 768 ? "" : "me-2"}
                />{" "}
                {/* Icon always shown */}
                <span className="d-none d-md-inline">
                  Create Instructor
                </span>{" "}
                {/* Show text only on medium and larger screens */}
              </Button>
            </Col>
          </Row>

          <Row className="mt-4">
            {instructors.length === 0 ? (
              <p>No instructors available.</p>
            ) : (
              instructors.map((instructor) => (
                <Col key={instructor.id} md={4} className="mb-4">
                  <Card className="h-100 d-flex flex-column">
                    {instructor.image_url && (
                      <Card.Img
                        variant="top"
                        src={instructor.image_url}
                        alt={instructor.name}
                        style={{ height: "450px", width: "auto", maxWidth: "100%", objectFit: "cover" }}
                      />
                    )}
                    <Card.Header>
                      <Card.Title>{instructor.name}</Card.Title>
                    </Card.Header>
                    <Card.Body className="d-flex flex-column">
                      <div className="flex-grow-1">
                        <Card.Text>{instructor.bio}</Card.Text>
                      </div>
                      <div className="mt-3 mb-4">
                        {instructor.facebook && (
                          <a
                            href={instructor.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mr-3"
                          >
                            <FontAwesomeIcon icon={faFacebook} size="2x" />
                          </a>
                        )}
                        {instructor.instagram && (
                          <a
                            href={instructor.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon icon={faInstagram} size="2x" />
                          </a>
                        )}
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <Row>
                        <Button
                          variant="warning"
                          onClick={() => handleShowEditModal(instructor)}
                          className="mt-auto"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </Row>
                      <Row>
                        <Button
                          variant="danger"
                          onClick={() => handleDeleteInstructor(instructor.id)}
                          className="mt-2"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Container>

        {/* Create Instructor Modal */}
        <Modal show={showCreateModal} onHide={handleCloseCreateModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Instructor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="warning" className="mt-2">
              These details will be publicly displayed on your page. Do not
              share anything you wouldn't want to be seen by the public.
            </Alert>
            {error && (
              <Alert variant="danger" className="mt-3">
                {error}
              </Alert>
            )}
            <Form>
              <Form.Group controlId="formInstructorName" className="mb-2">
                <Form.Label className="fw-bold">
                  Name <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter instructor name"
                  value={newInstructor.name}
                  onChange={(e) =>
                    setNewInstructor({ ...newInstructor, name: e.target.value })
                  }
                  isInvalid={!!nameError}
                />
                <Form.Control.Feedback type="invalid">
                  {nameError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formInstructorBio" className="mt-2">
                <Form.Label className="fw-bold">
                  Bio <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter instructor bio"
                  value={newInstructor.bio}
                  onChange={(e) =>
                    setNewInstructor({ ...newInstructor, bio: e.target.value })
                  }
                  isInvalid={!!bioError}
                />
                <Form.Control.Feedback type="invalid">
                  {bioError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formInstructorFacebook" className="mt-2">
                <Form.Label className="fw-bold">Facebook Link</Form.Label>
                <Form.Control
                  type="url"
                  placeholder="Enter Facebook Link"
                  value={newInstructor.facebook}
                  onChange={(e) =>
                    setNewInstructor({
                      ...newInstructor,
                      facebook: e.target.value,
                    })
                  }
                  isInvalid={!!facebookError} // Display validation error
                />
                <Form.Control.Feedback type="invalid">
                  {facebookError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formInstructorInstagram" className="mt-2">
                <Form.Label className="fw-bold">Instagram Link</Form.Label>
                <Form.Control
                  type="url"
                  placeholder="Enter Instagram Link"
                  value={newInstructor.instagram}
                  onChange={(e) =>
                    setNewInstructor({
                      ...newInstructor,
                      instagram: e.target.value,
                    })
                  }
                  isInvalid={!!instagramError} // Display validation error
                />
                <Form.Control.Feedback type="invalid">
                  {instagramError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formInstructorImage" className="mt-2">
                <Form.Label className="fw-bold">Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*" // Accept only image files
                  onChange={
                    (e) =>
                      setNewInstructor({
                        ...newInstructor,
                        image: e.target.files[0],
                      }) // Set the image file
                  }
                />
              </Form.Group>
              <Button
                variant="primary"
                onClick={handleCreateInstructor}
                className="mt-4"
              >
                Publish Instructor
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Edit Instructor Modal */}
        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Instructor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="warning" className="mt-2">
              These details will be publicly displayed on your page. Do not
              share anything you wouldn't want to be seen by the public.
            </Alert>
            {error && (
              <Alert variant="danger" className="mt-3">
                {error}
              </Alert>
            )}
            {selectedInstructor && (
              <Form>
                <Form.Group controlId="formEditInstructorName">
                  <Form.Label className="fw-bold">
                    Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter instructor name"
                    value={selectedInstructor.name}
                    onChange={(e) =>
                      setSelectedInstructor({
                        ...selectedInstructor,
                        name: e.target.value,
                      })
                    }
                    isInvalid={!!nameError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {nameError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formEditInstructorBio" className="mt-2">
                  <Form.Label className="fw-bold">
                    Bio <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter instructor bio"
                    value={selectedInstructor.bio}
                    onChange={(e) =>
                      setSelectedInstructor({
                        ...selectedInstructor,
                        bio: e.target.value,
                      })
                    }
                    isInvalid={!!bioError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {bioError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="formEditInstructorFacebook"
                  className="mt-2"
                >
                  <Form.Label className="fw-bold">Facebook Link</Form.Label>
                  <Form.Control
                    type="url"
                    placeholder="Enter Facebook Link"
                    value={selectedInstructor.facebook}
                    onChange={(e) =>
                      setSelectedInstructor({
                        ...selectedInstructor,
                        facebook: e.target.value,
                      })
                    }
                    isInvalid={!!facebookError} // Display validation error
                  />
                  <Form.Control.Feedback type="invalid">
                    {facebookError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="formEditInstructorInstagram"
                  className="mt-2"
                >
                  <Form.Label className="fw-bold">Instagram Link</Form.Label>
                  <Form.Control
                    type="url"
                    placeholder="Enter Instagram Link"
                    value={selectedInstructor.instagram}
                    onChange={(e) =>
                      setSelectedInstructor({
                        ...selectedInstructor,
                        instagram: e.target.value,
                      })
                    }
                    isInvalid={!!instagramError} // Display validation error
                  />
                  <Form.Control.Feedback type="invalid">
                    {instagramError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="formEditInstructorImage"
                  className="mt-2"
                >
                  <Form.Label className="fw-bold">Upload New Image</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*" // Accept only image files
                    onChange={(e) =>
                      setSelectedInstructor({
                        ...selectedInstructor,
                        image: e.target.files[0], // Set the new image file
                      })
                    }
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  className="mt-4"
                  onClick={handleUpdateInstructor}
                >
                  Publish Instructor
                </Button>
              </Form>
            )}
          </Modal.Body>
        </Modal>
      </Layout>
    </div>
  );
};

export default Instructors;
