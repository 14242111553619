import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Header from "../components/Header";

const Contact = () => {
  return (
    <div>
      <Header />
      <Container className="my-5">
        <Row className="text-center mb-5">
          <Col>
            <h1>Contact Us</h1>
            <p>We love hearing from you! Here are our contact details.</p>
            <p>Feature requests are welcome and encouraged.</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6} lg={4} className="mb-4">
            <Card className="contact-card">
              <Card.Body>
                <Card.Title>Support</Card.Title>
                <Card.Text>
                  <strong>Hours:</strong>
                  <br />
                  Monday - Friday: 9:00 AM - 6:00 PM
                  <br />
                  Saturday - Sunday: Closed
                </Card.Text>
                <Card.Text>
                  <strong>Email:</strong>
                  <br />
                  <a href="mailto:support@timetablr.co">
                    support@timetablr.co
                  </a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
