import React from "react";
import { Nav } from "react-bootstrap";

const InstructorSidebar = () => {
  return (
    <Nav className="flex-column" defaultActiveKey="/home">
      <Nav.Link style={{ color: "black" }} href="#createInstructor">
        Create an Instructor Profile
      </Nav.Link>
      <Nav.Link style={{ color: "black" }} href="#editInstructor">
        Edit & Delete Instructor Profiles
      </Nav.Link>
    </Nav>
  );
};

export default InstructorSidebar;
