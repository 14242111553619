import React, { useState } from "react";
import {
  Alert,
  Card,
  Tabs,
  Tab,
  Form,
  Button,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import BasicHeader from "../components/BasicHeader";
import useRecaptcha from "../hooks/useRecaptcha";
import api from "../axiosInstance";

const validatePassword = (password) => {
  const lengthRequirement = password.length >= 8;
  const uppercaseRequirement = /[A-Z]/.test(password);
  const lowercaseRequirement = /[a-z]/.test(password);
  const numberRequirement = /[0-9]/.test(password);
  const specialCharRequirement = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return {
    length: lengthRequirement,
    uppercase: uppercaseRequirement,
    lowercase: lowercaseRequirement,
    number: numberRequirement,
    specialChar: specialCharRequirement,
  };
};

const Auth = () => {
  const [email, setEmail] = useState("");
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetPasswordMessage, setResetPasswordMessage] = useState("");
  const [error, setError] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const { authType } = useParams();
  const [key, setKey] = useState(authType);
  const [modalShow, setModalShow] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const navigate = useNavigate();

  const RECAPTCHA_SITE_KEY = "6Ldk9k4qAAAAAPZl0dfdKCXZQHKlR9YzbN9bffkI";
  useRecaptcha(RECAPTCHA_SITE_KEY);

  const handleTabChange = (key) => {
    setKey(key);
    setEmail("");
    setPassword("");
    setError("");
    setEmailError("");
    setPasswordError("");
    setConfirmError("");
    setPasswordValidation({
      length: false,
      uppercase: false,
      lowercase: false,
      number: false,
      specialChar: false,
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordValidation(validatePassword(newPassword));
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post("/login", {
        email,
        password,
      });

      const token = response.data["access_token"];

      localStorage.setItem("tt_jwt", token);

      // Redirect to the dashboard or another page
      navigate("/dashboard");
    } catch (err) {
      if (err.response.status == 403) {
        navigate(`/resend-email/${encodeURIComponent(email)}`);
      } else if (err.response.status == 402) {
        localStorage.setItem("tt_jwt", err.response.data.access_token);
        navigate("/payment");
      } else {
        setError(
          err.response.data.message ||
            "Login failed. Please try again or contact us for further assistance."
        );
      }
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    setEmailError("");
    setPasswordError("");
    setConfirmError("");

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    // Check if all validation requirements are met
    if (!Object.values(passwordValidation).every(Boolean)) {
      setPasswordError("Password does not meet the requirements.");
      return;
    }

    if (password != confirmPassword) {
      setConfirmError("Password's don't match.");
      return;
    }

    try {
      const recaptchaToken = await window.grecaptcha.execute(
        RECAPTCHA_SITE_KEY,
        { action: "submit" }
      );
      await api.post("/api/register", {
        email,
        password,
        confirmPassword,
        recaptchaToken,
      });

      setError("");
      navigate("/auth/verification");
    } catch (err) {
      if (err.response.status == 403) {
        navigate(`/resend-email/${encodeURIComponent(email)}`);
      } else {
        setError(
          err.response.data.message ||
            "Sign up failed. Please try again or contact us for further assistance."
        );
      }
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    // Call your password reset API here
    try {
      const recaptchaToken = await window.grecaptcha.execute(
        RECAPTCHA_SITE_KEY,
        { action: "submit" }
      );

      await api.post("/api/trigger-reset-email", {
        resetPasswordEmail,
        recaptchaToken,
      });
      setResetPasswordMessage(
        "A password reset link has been sent to your email."
      );
      setResetPasswordError("");
      setModalShow(false);
      setResetPasswordEmail("");
    } catch (err) {
      setResetPasswordError(
        err.response.data.message || "An error occurred. Please try again."
      );
    }
  };

  const handleCloseResetPassword = () => {
    setModalShow(false);
    setResetPasswordEmail("");
    setResetPasswordError("");
    setResetPasswordMessage("");
  };

  return (
    <div className="page-background">
      <BasicHeader />
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Card style={{ width: "400px" }}>
          <Card.Body>
            <Tabs
              id="auth-tabs"
              activeKey={key}
              onSelect={(k) => handleTabChange(k)}
              className="mb-3"
              fill
            >
              <Tab eventKey="login" title="Login">
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleLogin}>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formPassword" className="mt-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <Form.Text className="text-muted d-block text-end mt-2">
                      <a
                        href="#"
                        onClick={() => setModalShow(true)}
                        style={{ color: "#007bff" }}
                      >
                        Forgot your password?
                      </a>
                    </Form.Text>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-3 w-100"
                  >
                    Login
                  </Button>
                </Form>
              </Tab>
              <Tab eventKey="signup" title="Sign Up">
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSignup}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter email address"
                      value={email}
                      isInvalid={!!emailError}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {emailError}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword" className="mt-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter password"
                      value={password}
                      isInvalid={!!passwordError}
                      onChange={handlePasswordChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {passwordError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword" className="mt-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm password"
                      value={confirmPassword}
                      isInvalid={!!confirmError}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {confirmError}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      Your password must meet the following criteria:
                    </Form.Text>
                    <ListGroup>
                      <ListGroup.Item
                        className={
                          passwordValidation.length
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        At least 8 characters
                      </ListGroup.Item>
                      <ListGroup.Item
                        className={
                          passwordValidation.uppercase
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        At least one uppercase letter
                      </ListGroup.Item>
                      <ListGroup.Item
                        className={
                          passwordValidation.lowercase
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        At least one lowercase letter
                      </ListGroup.Item>
                      <ListGroup.Item
                        className={
                          passwordValidation.number
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        At least one number
                      </ListGroup.Item>
                      <ListGroup.Item
                        className={
                          passwordValidation.specialChar
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        At least one special character
                      </ListGroup.Item>
                    </ListGroup>
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-3 w-100"
                  >
                    Sign Up
                  </Button>
                </Form>
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>

        {/* Modal for Forgot Password */}
        <Modal show={modalShow} onHide={handleCloseResetPassword}>
          <Modal.Header closeButton>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleForgotPassword}>
              <Form.Group controlId="formResetEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your email"
                  value={resetPasswordEmail}
                  onChange={(e) => setResetPasswordEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Send Reset Link
              </Button>
            </Form>
            {resetPasswordError && (
              <Alert variant="danger" className="mt-3">
                {resetPasswordError}
              </Alert>
            )}
            {resetPasswordMessage && (
              <Alert variant="success" className="mt-3">
                {resetPasswordMessage}
              </Alert>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Auth;
