import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./Header";

const Verified = () => {
  return (
    <div>
      <Header />
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <h3>Verification Sucessful!</h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Verified;
