import React from "react";
import { Container, Card, Image } from "react-bootstrap";

const TimetableContent = () => {
  return (
    <Container>
      <section id="introduction">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Timetables</Card.Title>
            <Card.Text>
              The timetable page displays your business details and your timetable.
              From here you can also navigate to your classes, instructors and
              reporting.
              <br />
              <br />
              This is the page from which you will create, delete, cancel and
              reschedule your events.
            </Card.Text>
            <Image src={"/images/timetable.png"} fluid />
          </Card.Body>
        </Card>
      </section>

      <section id="createEvent">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Create Event</Card.Title>
            <Card.Text>
              To create an event, select the rough date and time on the
              calendar. A modal should show, allowing you to input your event
              details.{" "}
              <strong>
                You will have to create a class before you can create an event
                for that class.
              </strong>
              <br />
              <br />
              You can create one off events or events that recur daily or
              weekly. The calendar will display dates 8 weeks into the past and
              8 weeks into the future.
            </Card.Text>
            <Image src={"/images/create_event.png"} fluid />
            <Card.Text className="mt-4">
              The event will then appear on the calendar with the colour you
              selected when creating the class.
            </Card.Text>
            <Image src={"/images/created_event.png"} fluid />
            <Card.Text className="mt-4">
              Selecting the event will then show you details about the
              associated class, instructor and will provide you with the ability
              to perform actions e.g. cancel an event.
            </Card.Text>
            <Image src={"/images/event_details.png"} fluid />
          </Card.Body>
        </Card>
      </section>

      <section id="actions">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Actions</Card.Title>
            <Card.Text>
              For each event there are a number of actions that you can perform.
            </Card.Text>
            <h5>Delete Event</h5>
            <Card.Text>
              Deleting an event should only really be used to revert mistakes or
              when you're happy removing an event without your customers being
              made aware by us. Deleting an event will delete the selected event
              and any associated recurring events without sending a notification
              to your customers.
            </Card.Text>
            <h5>Cancel Event</h5>
            <Card.Text>
              Cancelling an event will trigger a notification to be sent to all
              customers who are subscribed to alerts for that class. The
              notification will tell them that the event was cancelled and will
              make them aware of the reason, if one is provided.
              <br />
              <br />
              The event will remain on the timetable but it will be clear that
              the event has been cancelled.
            </Card.Text>
            <Image src={"/images/cancelled_event.png"} className="mb-4" fluid />
            <Card.Text>
              There is also the option to reinstate the event if it was
              cancelled incorrectly, which will trigger another notification.
              This can be done by once again selecting the event.
            </Card.Text>
            <Image src={"/images/recreate_event.png"} className="mb-4" fluid />
            <h5>Cancel All Recurring Events</h5>
            <Card.Text>
              Cancelling all recurring events is an action unique to recurring
              events. It will remove the events from the calendar and a
              notification will be sent to any class subscribers. This should be
              used if the class is cancelled forever on that schedule. To cancel
              a single event in a line of recurring events, you should use the
              'Cancel Event' action as described above.
            </Card.Text>
            <h5>Reschedule Event</h5>
            <Card.Text>
              Rescheduling an event will trigger a notification to be sent to
              all customers who are subscribed to alerts for that class. The
              notification will tell them that the event was rescheduled and
              will make them aware of the new date and time for the event.
              <br />
              <br />
              The event will then move to the new date and it will be clear that
              it was rescheduled. You can reschedule an event as many times as
              you like.
            </Card.Text>
            <Image
              src={"/images/rescheduled_event.png"}
              className="mb-4"
              fluid
            />
            <h5>Reschedule Recurring Event</h5>
            <Card.Text>
              Rescheduling a recurring event will once again alert any class
              subscribers and it will allow you to change the recurring schedule
              of that event. All recurring events will then move to the new
              date.
            </Card.Text>
          </Card.Body>
        </Card>
      </section>
    </Container>
  );
};

export default TimetableContent;
