import React from "react";
import { Container, Card, Image } from "react-bootstrap";

const DashboardContent = () => {
  return (
    <Container>
      <section id="introduction">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Class Details</Card.Title>
            <Card.Text>
              Your class details provide your customers with all of the
              information they need regarding each class. A class could be a
              beginners kickboxing class, a dance class or anything you need.
              Like timetables, these details will be publicly available so don't
              provide any details you wouldn't want others to see.
              <br />
              <br />
              Once you've created classes you can then schedule them on your
              timetable.
            </Card.Text>
            <Image
              src={"/images/class_details.png"}
              style={{ width: "800px", height: "auto" }}
            />
          </Card.Body>
        </Card>
      </section>

      <section id="createClass">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Create a Class</Card.Title>
            <Card.Text>
              Creating a class is as simple as hitting the 'Create Class' button
              and filling in your class details. Be advised that mandatory
              fields are indicated by a red asterix throughout.
            </Card.Text>
            <Image
              src={"/images/create_class.png"}
              style={{ width: "800px", height: "auto" }}
            />
          </Card.Body>
        </Card>
      </section>

      <section id="editClass">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Edit & Delete a Class</Card.Title>
            <Card.Text>
              You can edit all class fields whenever you wish. Simply select
              the edit button on the class you wish to change.
              <br />
              <br />
              Similarly, you can delete a class by selecting the red trash
              button.
              <br />
              <br />
              <strong>
                Beware that deleting a class will delete any related events from
                your timetable without alerting customers.
              </strong>
            </Card.Text>
            <Image
              src={"/images/edit_class.png"}
              style={{ width: "800px", height: "auto" }}
            />
          </Card.Body>
        </Card>
      </section>
    </Container>
  );
};

export default DashboardContent;
