import React from "react";
import { Container, Card, Image } from "react-bootstrap";

const DashboardContent = () => {
  return (
    <Container>
      <section id="introduction">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Instructor Profiles</Card.Title>
            <Card.Text>
              Your instructor profiles provide your customers with all of the
              information they need regarding each instructor, including their
              name, bio a profile picture and social media links. Once again,
              these details will be publicly available so don't provide any
              details you wouldn't want others to see.
              <br />
              <br />
              Once you've created instructors you can associate them with
              classes.
            </Card.Text>
            <Image
              src={"/images/instructor_details.png"}
              style={{ width: "800px", height: "auto" }}
            />
          </Card.Body>
        </Card>
      </section>

      <section id="createInstructor">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Create an Instructor</Card.Title>
            <Card.Text>
              Creating an instructor is as simple as hitting the 'Create
              Instructor' button and filling in your instructor profile. Be
              advised that mandatory fields are indicated by a red asterix
              throughout.
            </Card.Text>
            <Image
              src={"/images/create_instructor.png"}
              style={{ width: "800px", height: "auto" }}
            />
          </Card.Body>
        </Card>
      </section>

      <section id="editInstructor">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Edit & Delete an Instructor</Card.Title>
            <Card.Text>
              You can edit all instructor fields whenever you wish. Simply
              select the edit button on the instructor you wish to change.
              <br />
              <br />
              Similarly, you can delete an instructor profile by selecting the
              red trash button.
            </Card.Text>
            <Image
              src={"/images/edit_instructor.png"}
              style={{ width: "800px", height: "auto" }}
              className="mt-4"
            />
          </Card.Body>
        </Card>
      </section>
    </Container>
  );
};

export default DashboardContent;
