// src/pages/BadRequest.js
import React from "react";
import LoginHeader from "../../components/LoginHeader";

const BadRequest = () => {
  return (
    <div className="text-dark">
      <LoginHeader />
      <div style={{ textAlign: "center", padding: "50px" }}>
        <h1>400 - Bad Request</h1>
        <p>
          There was an issue with your request. Please check your input and try
          again.
        </p>
      </div>
    </div>
  );
};

export default BadRequest;
