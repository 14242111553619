import React from "react";
import { Table } from "react-bootstrap";

const convertUtcToLocalTime = (utcTime) => {
  const [time, modifier] = utcTime.split(" "); // Separate time and AM/PM
  const [hours, minutes] = time.split(":").map(Number); // Split hours and minutes
  const formattedModifier = modifier.toUpperCase(); // Ensure modifier is uppercase

  // Create a UTC date string using the current date
  const utcDate = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      hours + (formattedModifier === "PM" ? 12 : 0),
      minutes
    )
  );

  // Convert to local time string in 'hh:mm AM/PM' format
  const localTimeString = utcDate
    .toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .replace(/(am|pm)/, (match) => match.toUpperCase());

  return localTimeString;
};

const TimesTable = ({ proposedTimes }) => {
  // Check if proposedTimes is empty
  if (proposedTimes.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          fontSize: "24px",
          color: "grey",
        }}
      >
        No proposed times for this class.
      </div>
    );
  }

  // Group proposals by day and time
  const groupedData = proposedTimes.reduce((acc, item) => {
    const day = item.day;
    const time = item.time;
    if (!acc[day]) {
      acc[day] = {};
    }
    if (!acc[day][time]) {
      acc[day][time] = 0; // Initialize count for this day and time
    }
    acc[day][time] += item.count; // Increment count
    return acc;
  }, {});

  // Convert grouped data to an array of rows for the table
  const tableData = [];
  for (const day in groupedData) {
    for (const time in groupedData[day]) {
      const localTime = convertUtcToLocalTime(time); // Convert time to local time
      tableData.push({
        day,
        time: localTime,
        count: groupedData[day][time],
      });
    }
  }

  return (
    <div className="table-container mx-10 my-10">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Day</th>
            <th>Time</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td>{row.day}</td>
              <td>{row.time}</td>
              <td>{row.count}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TimesTable;
