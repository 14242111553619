import React from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";

function Header() {
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">Timetablr</Navbar.Brand>

        {/* Sign Up button and Toggle button visible only on small screens */}
        <div className="d-flex ml-auto align-items-center d-lg-none">
          <Button
            style={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "0.5rem", // Space between Sign Up and Toggle button
            }}
            href="/auth/signup"
          >
            Sign Up
          </Button>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>

        {/* Navbar Collapse (works on small screens and remains expanded on large screens) */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/pricing">Pricing</Nav.Link>
            <Nav.Link href="/documentation">Documentation</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Button
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 0.5rem",
              }}
              variant="outline-primary"
              href="/auth/login"
            >
              Login
            </Button>
            {/* Sign Up button for large screens */}
            <Button
              className="d-none d-lg-block"
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 0.5rem",
              }}
              href="/auth/signup"
            >
              Sign Up
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
