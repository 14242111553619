import React from "react";
import { Container, Card, Image } from "react-bootstrap";

const CustomerPageContent = () => {
  return (
    <Container>
      <section id="introduction">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Public Page</Card.Title>
            <Card.Text>
              Your timetable, class details, instructor profiles and events are
              all available to the public. All you have to do is share the link
              with your customers either directly or through other means like
              your social media or website.
              <br />
              <br />
              Any updates you make to your classes, instructors or timetables
              will be available in the public page too.
            </Card.Text>
            <Image
              src={"/images/public_timetable.png"}
              style={{ width: "800px", height: "auto" }}
            />
            <Image
              src={"/images/public_class.png"}
              style={{ width: "800px", height: "auto" }}
              className="mt-4"
            />
            <Image
              src={"/images/public_instructor.png"}
              style={{ width: "800px", height: "auto" }}
            />
          </Card.Body>
        </Card>
      </section>

      <section id="classNotifications">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Class Notifications</Card.Title>
            <Card.Text>
              Your customers can select events to subscribe to alerts for the
              associated class. For each subscription, they'll be sent a
              verification link, which they'll have to click before the
              subscription is considered valid. They can unsubscribe to alerts
              at any point, using the link found in any of the notifications
              they've receieved.
            </Card.Text>
            <Image
              src={"/images/subscribe.png"}
              style={{ width: "800px", height: "auto" }}
              className="mt-4"
            />
          </Card.Body>
        </Card>
      </section>

      <section id="proposeTimes">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Propose Times</Card.Title>
            <Card.Text>
              Your customers can select events to propose new times for the
              associated class. They'll be sent a verification link, which
              they'll have to click before the proposal is considered valid.
            </Card.Text>
            <Image
              src={"/images/propose_time.png"}
              style={{ width: "800px", height: "auto" }}
            />
          </Card.Body>
        </Card>
      </section>
    </Container>
  );
};

export default CustomerPageContent;
