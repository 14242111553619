import React, { useState, useEffect } from "react";
import { Navbar, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCalendar,
  faClipboard,
  faTachometerAlt,
  faFileAlt,
  faGraduationCap,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import DashboardHeader from "./DashboardHeader";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { id: timetableId } = useParams();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  // Detect screen size and set default state for the drawer
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsOpen(false); // Close on small screens
      } else {
        setIsOpen(true); // Open on larger screens
      }
    };

    handleResize(); // Set initial state based on the screen size
    window.addEventListener("resize", handleResize); // Listen to window resize

    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  // Dynamic width and margin
  const drawerWidth = isOpen ? "250px" : "70px";
  const contentStyle = {
    marginLeft: isOpen ? "250px" : "70px",
    transition: "margin-left 0.3s ease", // Smooth transition
  };

  return (
    <div>
      <DashboardHeader />
      <div
        className="drawer"
        style={{
          width: drawerWidth,
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid rgba(0, 0, 0, 0.15)",
        }}
      >
        <Navbar bg="light" variant="light" className="flex-column">
          <Nav className="flex-column">
            <h6
              className={`ml-3 mt-3 ${!isOpen ? "d-none" : ""}`}
              style={{ color: "#A9A9A9" }}
            >
              Dashboard
            </h6>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="calendar-tooltip">
                  View All of Your Timetables.
                </Tooltip>
              }
              delay={{ show: 250, hide: 400 }}
            >
              <Nav.Link
                className="ml-3"
                href="/dashboard"
                style={{ color: "black" }}
              >
                <FontAwesomeIcon icon={faTachometerAlt} />
                {isOpen && <span className="ml-2">Dashboard</span>}
              </Nav.Link>
            </OverlayTrigger>
            <h6
              className={`ml-3 mt-3 ${!isOpen ? "d-none" : ""}`}
              style={{ color: "#A9A9A9" }}
            >
              Timetable Management
            </h6>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="calendar-tooltip">Manage your Timetable.</Tooltip>
              }
              delay={{ show: 250, hide: 400 }}
            >
              <Nav.Link
                className="ml-3"
                href={`/timetable/${timetableId}`}
                style={{ color: "black" }}
              >
                <FontAwesomeIcon icon={faCalendar} />
                {isOpen && <span className="ml-2">Timetable</span>}
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="calendar-tooltip">Manage your Classes.</Tooltip>
              }
              delay={{ show: 250, hide: 400 }}
            >
              <Nav.Link
                className="ml-3"
                href={`/timetable/${timetableId}/classes`}
                style={{ color: "black" }}
              >
                <FontAwesomeIcon icon={faClipboard} />
                {isOpen && <span className="ml-2">Classes</span>}
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="calendar-tooltip">
                  Manage your Instructor Profiles.
                </Tooltip>
              }
              delay={{ show: 250, hide: 400 }}
            >
              <Nav.Link
                className="ml-2"
                href={`/timetable/${timetableId}/instructors`}
                style={{ color: "black" }}
              >
                <FontAwesomeIcon icon={faGraduationCap} />
                {isOpen && <span className="ml-2">Instructors</span>}
              </Nav.Link>
            </OverlayTrigger>
            <h6
              className={`ml-3 mt-3 ${!isOpen ? "d-none" : ""}`}
              style={{ color: "#A9A9A9" }}
            >
              Reporting
            </h6>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="calendar-tooltip">
                  View your Timetable Reports.
                </Tooltip>
              }
              delay={{ show: 250, hide: 400 }}
            >
              <Nav.Link
                className="ml-3"
                href={`/timetable/${timetableId}/reports`}
                style={{ color: "black" }}
              >
                <FontAwesomeIcon icon={faFileAlt} />
                {isOpen && <span className="ml-2">Reports</span>}
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="calendar-tooltip">
                  View Proposed Event Time Histograms.
                </Tooltip>
              }
              delay={{ show: 250, hide: 400 }}
            >
              <Nav.Link
                className="ml-3"
                href={`/timetable/${timetableId}/proposed-times`}
                style={{ color: "black" }}
              >
                <FontAwesomeIcon icon={faClock} />
                {isOpen && <span className="ml-2">Proposed Times</span>}
              </Nav.Link>
            </OverlayTrigger>
          </Nav>
        </Navbar>
        <div className="toggle-arrow" onClick={toggleDrawer}>
          <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
        </div>
      </div>
      <div className="content" style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
