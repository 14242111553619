// src/pages/Unauthorized.js
import React from "react";
import LoginHeader from "../../components/LoginHeader";

const Unauthorized = () => {
  return (
    <div className="text-dark">
      <LoginHeader />
      <div style={{ textAlign: "center", padding: "50px" }}>
        <h1>401 - Unauthorized</h1>
        <p>You do not have permission to view this page.</p>
      </div>
    </div>
  );
};

export default Unauthorized;
