import React, { useState, useEffect, useMemo } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Alert,
  Badge,
  Container,
  Card,
  Tab,
  Tabs,
  Modal,
  Accordion,
  Row,
  Col,
  Button,
  Form,
  Nav,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomEvent from "../components/CustomEvent";
import "../calendarStyles.css";
import { useNavigate } from "react-router-dom";
import PublicCompanyCard from "../components/PublicCompanyCard";
import PublicLayout from "../components/PublicLayout";
import Select from "react-select";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import CustomToolbar from "./CustomToolbar";
import EventsCards from "./EventCards";
import api from "../axiosInstance";

const localizer = momentLocalizer(moment);

const PublicTimetable = () => {
  const [activeTab, setActiveTab] = useState("details");
  const [hideCancelledEvents, setHideCancelledEvents] = useState(false);
  const [viewTab, setViewTab] = useState("calendar");
  const [classes, setClasses] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [events, setEvents] = useState([]);
  const [timetable, setTimetable] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [classLevel, setClassLevel] = useState("");
  const [classType, setClassType] = useState("");
  const [classInstructor, setClassInstructor] = useState("");
  const [selectedAction, setSelectedAction] = useState(null);
  const [defaultView, setDefaultView] = useState(Views.WEEK);
  const { id: timetableId } = useParams();
  const scrollToTime = new Date();
  scrollToTime.setHours(7, 0, 0); // Scroll to 7:00 AM
  const navigate = useNavigate();

  const today = new Date();

  // Calculate 8 weeks back and 8 weeks forward
  const startRange = moment(today).subtract(8, "weeks").toDate();
  const endRange = moment(today).add(8, "weeks").toDate();

  const [currentDate, setCurrentDate] = useState(today);

  const handleNavigate = (newDate, view) => {
    if (newDate < startRange) {
      setCurrentDate(startRange); // Prevent navigating before 8 weeks back
    } else if (newDate > endRange) {
      setCurrentDate(endRange); // Prevent navigating beyond 8 weeks forward
    } else {
      setCurrentDate(newDate); // Allow navigation within the allowed range
    }
  };

  useEffect(() => {
    // Function to determine the default view based on screen width
    const determineDefaultView = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setDefaultView(Views.DAY); // Mobile devices
      } else {
        setDefaultView(Views.WEEK); // Desktops
      }
    };

    determineDefaultView(); // Set the default view on mount

    // Optional: Add an event listener to handle window resizing
    window.addEventListener("resize", determineDefaultView);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", determineDefaultView);
    };
  }, []);

  const handleSubscribe = () => {
    const classId = selectedEvent.classId;
    // Navigate to the desired route
    navigate(`/timetable/${timetableId}/classes/${classId}/subscribe`);
  };

  const handleTimeProposal = () => {
    const classId = selectedEvent.classId;
    // Navigate to the desired route
    navigate(`/timetable/${timetableId}/classes/${classId}/propose-time`);
  };

  useEffect(() => {
    const fetchTimetable = async () => {
      try {
        const response = await api.get(`/api/timetables/${timetableId}`);
        setTimetable(response.data);
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    };

    const fetchInstructors = async () => {
      try {
        const instructorResponse = await api.get(
          `/api/timetables/${timetableId}/instructors`
        );
        setInstructors(instructorResponse.data);
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    };

    const fetchClasses = async () => {
      try {
        const response = await api.get(
          `/api/timetables/${timetableId}/classes`
        );
        setClasses(response.data);
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    };

    const fetchEvents = async () => {
      try {
        const response = await api.get(`/api/timetables/${timetableId}/events`);
        setEvents(response.data);
        setFilteredEvents(response.data); // Set initial filtered events
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    };

    fetchTimetable();
    fetchInstructors();
    fetchClasses();
    fetchEvents();
  }, [timetableId]);

  useEffect(() => {
    filterEvents(classLevel, classType, classInstructor);
  }, [classLevel, classType, classInstructor, events]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--header-bg-color",
      timetable.header_colour || "#0061f2"
    );
    document.documentElement.style.setProperty(
      "--header-text-color",
      timetable.header_text_colour || "white"
    );
  }, [timetable]);

  const getClassTypes = () => {
    const types = classes.map((classItem) => ({
      value: classItem.title,
      label: classItem.title,
    }));
    return [{ value: "", label: "All Classes" }, ...new Set(types)];
  };

  const getInstructorNames = () => {
    const names = instructors.map((instructorItem) => ({
      value: instructorItem.name,
      label: instructorItem.name,
    }));
    return [{ value: "", label: "All Instructors" }, ...new Set(names)];
  };

  const filterEvents = (level, title, instructor) => {
    let newFilteredEvents = events;

    if (level && level !== "All Levels") {
      newFilteredEvents = newFilteredEvents.filter(
        (event) => event.level === level
      );
    }

    if (title && title !== "All Classes") {
      newFilteredEvents = newFilteredEvents.filter(
        (event) => event.title === title
      );
    }

    if (instructor && instructor !== "All Instructors") {
      newFilteredEvents = newFilteredEvents.filter((event) =>
        event.instructors.some((inst) => inst.name === instructor)
      );
    }

    setFilteredEvents(newFilteredEvents);
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const actionDescriptions = {
    subscribe: `Subscribe to email alerts for the selected class type. You will recieve alerts for event
                  cancellations and rescheduled events. You can unsubscribe at any time by using the link provided in
                  the email alert.`,
    proposal: `Would you prefer this class on a different day and time? Propose a new schedule to help the business
                improve their timetable.`,
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
    setSelectedAction(null);
  };

  const selectActions = () => {
    return [
      { value: "subscribe", label: "Subscribe to Class Alerts" },
      { value: "proposal", label: "Propose New Time" },
    ];
  };

  const eventList = filteredEvents
    .filter((event) => !(event.cancelled && hideCancelledEvents)) // Filter out cancelled events if hideCancelledEvents is false
    .map((event) => ({
      id: event.id,
      start: new Date(event.start),
      end: new Date(event.end),
      title: event.title,
      description: event.description,
      display_colour: event.display_colour,
      level: event.level,
      location: event.location,
      cost: event.cost,
      additional_notes: event.additional_notes,
      equipment: event.equipment,
      requirements: event.requirements,
      cancelled: event.cancelled,
      classId: event.classId,
      rrule: event.rrule,
      old_start_date: event.old_start_date
        ? new Date(event.old_start_date)
        : event.old_start_date,
      reason: event.reason,
      // Handle multiple instructors
      instructors: event.instructors.map((instructor) => ({
        name: instructor.name,
        bio: instructor.bio,
        fb: instructor.fb,
        insta: instructor.insta,
        image_url: instructor.image_url,
      })),
    }));

  const eventStyleGetter = (event) => {
    const backgroundColor = "#F5F5F5";
    return {
      style: {
        backgroundColor,
        color: "black",
        border: "1px solid transparent", // Ensure border is not visible
        borderTop: `5px solid ${event.display_colour || "red"}`, // Custom border on top
      },
    };
  };

  const components = useMemo(
    () => ({
      event: CustomEvent, // used by each view (Month, Day, Week)
      toolbar: CustomToolbar,
    }),
    []
  );

  const handleActionChange = (selectedOption) => {
    setSelectedAction(selectedOption);
  };

  const handleViewChange = (view) => {
    setDefaultView(view);
  };

  const classLevelOptions = [
    { value: "", label: "All Levels" },
    { value: "Beginner", label: "Beginner" },
    { value: "Intermediate", label: "Intermediate" },
    { value: "Advanced", label: "Advanced" },
  ];

  return (
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh" }}>
      <PublicLayout>
        <Container>
          <Row className="mb-4">
            <Col className="mt-4">
              <PublicCompanyCard
                companyName={timetable.title}
                description={timetable.description}
                websiteLink={timetable.website}
                addressLine1={timetable.address_line_1}
                addressLine2={timetable.address_line_2}
                city={timetable.city}
                country={timetable.country}
                postcode={timetable.postcode}
                email={timetable.email}
                phone={timetable.phone}
                facebookLink={timetable.facebook}
                instagramLink={timetable.instagram}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-4">
              <Card>
                <Card.Body>
                  <Row>
                    <Col xs={12} md={12}>
                      <Alert variant="info" dismissible>
                        <strong>Select timetable events to:</strong>
                        <ul
                          style={{ listStyleType: "disc", paddingLeft: "20px" }}
                        >
                          <li>See further event details.</li>
                          <li>
                            Subscribe to classes for cancellation and
                            rescheduling notifications.
                          </li>
                          <li>Propose times that work better for you.</li>
                        </ul>
                      </Alert>
                    </Col>
                  </Row>
                  <Row className="mb-4 align-items-center">
                    <Col xs={12} md={2} className="mb-3">
                      <Form.Group controlId="formClassLevel" className="mb-0">
                        <Select
                          options={classLevelOptions}
                          value={classLevelOptions.find(
                            (option) => option.value === classLevel
                          )}
                          onChange={(option) =>
                            setClassLevel(option ? option.value : "")
                          }
                          placeholder="Select Level"
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              maxWidth: "100%",
                            }),
                            control: (provided) => ({
                              ...provided,
                              border: "0.5px solid",
                              boxShadow: "none",
                              "&:hover": {
                                border: "1px solid #0056b3",
                              },
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "",
                              "&:hover": {
                                color: "#0056b3",
                              },
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={2} className="mb-3">
                      <Form.Group controlId="formClassType" className="mb-0">
                        <Select
                          options={getClassTypes()}
                          value={getClassTypes().find(
                            (option) => option.value === classType
                          )}
                          onChange={(option) =>
                            setClassType(option ? option.value : "")
                          }
                          placeholder="Select Class Type"
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              maxWidth: "100%",
                            }),
                            control: (provided) => ({
                              ...provided,
                              border: "0.5px solid",
                              boxShadow: "none",
                              "&:hover": {
                                border: "1px solid #0056b3",
                              },
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "",
                              "&:hover": {
                                color: "#0056b3",
                              },
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={2} className="mb-3">
                      <Form.Group
                        controlId="formClassInstructor"
                        className="mb-0"
                      >
                        <Select
                          options={getInstructorNames()}
                          value={getInstructorNames().find(
                            (option) => option.value === classInstructor
                          )}
                          onChange={(option) =>
                            setClassInstructor(option ? option.value : "")
                          }
                          placeholder="Select Instructor"
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              maxWidth: "100%",
                            }),
                            control: (provided) => ({
                              ...provided,
                              border: "0.5px solid",
                              boxShadow: "none",
                              "&:hover": {
                                border: "1px solid #0056b3",
                              },
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "",
                              "&:hover": {
                                color: "#0056b3",
                              },
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={2}>
                      {/* Checkbox to toggle showing cancelled events */}
                      <Form.Group
                        controlId="hideCancelledEvents"
                        className="mb-3"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Hide Cancelled Events"
                          checked={hideCancelledEvents} // Bind checkbox to state
                          onChange={() =>
                            setHideCancelledEvents((prev) => !prev)
                          } // Toggle state on change
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Tabs
                    id="view-switcher"
                    activeKey={viewTab}
                    onSelect={(key) => setViewTab(key)}
                    className="mb-3"
                  >
                    <Tab eventKey="calendar" title="Calendar View">
                      <div className="calendar-border">
                        <Calendar
                          localizer={localizer}
                          events={eventList}
                          startAccessor="start"
                          endAccessor="end"
                          views={["week", "day"]}
                          style={{
                            height: "calc(100vh - 50px)",
                            width: "100%",
                          }}
                          selectable
                          onSelectEvent={handleEventClick}
                          eventPropGetter={eventStyleGetter}
                          scrollToTime={scrollToTime}
                          view={defaultView}
                          onView={handleViewChange}
                          date={currentDate}
                          onNavigate={handleNavigate}
                          components={components}
                        />
                      </div>
                    </Tab>
                    <Tab eventKey="dayCards" title="Day View">
                      <EventsCards
                        onEventClick={handleEventClick}
                        events={eventList}
                        headerColor={timetable.header_colour || "#007bff"}
                        headerTextColor={
                          timetable.header_text_colour || "white"
                        }
                      />
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{selectedEvent?.title}</Modal.Title>
            {selectedEvent?.cancelled && (
              <Badge bg="danger" className="ml-2">
                Cancelled
              </Badge>
            )}
            {selectedEvent?.old_start_date && (
              <Badge bg="warning" className="ml-2">
                Rescheduled from:&nbsp;
                {new Date(selectedEvent?.old_start_date).toLocaleDateString(
                  undefined,
                  {
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}
              </Badge>
            )}
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <Nav
                variant="tabs"
                activeKey={activeTab}
                onSelect={(tab) => setActiveTab(tab)}
              >
                <Nav.Item>
                  <Nav.Link eventKey="details">Event Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="instructor">Instructor Profiles</Nav.Link>
                </Nav.Item>
                {!selectedEvent?.cancelled && (
                  <Nav.Item>
                    <Nav.Link eventKey="actions">Actions</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </div>

            {activeTab === "instructor" && (
              <>
                {selectedEvent?.instructors &&
                  selectedEvent.instructors.length > 0 ? (
                  selectedEvent.instructors.map((instructor, index) => (
                    <div key={instructor.id} className="mb-4">
                      {/* Display instructor image if available */}
                      {instructor.image_url && (
                        <div className="mb-3">
                          <img
                            src={instructor.image_url}
                            alt="Instructor"
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      )}
                      {/* Display Name */}
                      {instructor.name && (
                        <Card.Text>
                          <strong>Name</strong>
                          <br />
                          {instructor.name}
                        </Card.Text>
                      )}
                      {/* Display Bio */}
                      {instructor.bio && (
                        <Card.Text>
                          <strong>Bio</strong>
                          <br />
                          {instructor.bio}
                        </Card.Text>
                      )}
                      {/* Display social media links */}
                      <div className="mt-3">
                        {instructor.fb && (
                          <a
                            href={instructor.fb}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mr-3"
                          >
                            <FontAwesomeIcon icon={faFacebook} size="2x" />
                          </a>
                        )}
                        {instructor.insta && (
                          <a
                            href={instructor.insta}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon icon={faInstagram} size="2x" />
                          </a>
                        )}
                      </div>
                      {/* Horizontal separator */}
                      {index < selectedEvent.instructors.length - 1 && <hr />}
                    </div>
                  ))
                ) : (
                  <Card.Text style={{ color: "grey" }}>
                    No instructor profiles for this class.
                  </Card.Text>
                )}
              </>
            )}

            {activeTab === "details" && (
              <>
                <Card.Text>{selectedEvent?.description}</Card.Text>
                {selectedEvent?.location && (
                  <Card.Text>
                    <strong>Location:</strong>
                    <br />
                    {selectedEvent.location}
                  </Card.Text>
                )}

                {selectedEvent?.instructors &&
                  selectedEvent.instructors.length > 0 && (
                    <>
                      <strong>Instructor(s):</strong>
                      <br />
                      {selectedEvent.instructors.map((instructor, index) => (
                        <span key={index}>
                          {instructor.name}
                          {index < selectedEvent.instructors.length - 1
                            ? ", "
                            : ""}
                        </span>
                      ))}
                      <br />
                    </>
                  )}

                {selectedEvent?.level && (
                  <Card.Text>
                    <strong>Level:</strong>
                    <br />
                    {selectedEvent.level}
                  </Card.Text>
                )}
                <Accordion defaultActiveKey="1" className="mb-2 mt-4 mb-4">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Additional Info</Accordion.Header>
                    <Accordion.Body>
                      <Card.Text>
                        {/* Cost */}
                        {selectedEvent?.cost ? (
                          <>
                            <strong>Cost:</strong>
                            <br />
                            {selectedEvent.cost}
                            <br />
                          </>
                        ) : null}

                        {/* Equipment */}
                        {selectedEvent?.equipment ? (
                          <>
                            <strong>Equipment:</strong>
                            <br />
                            {selectedEvent.equipment}
                            <br />
                          </>
                        ) : null}

                        {/* Requirements */}
                        {selectedEvent?.requirements ? (
                          <>
                            <strong>Requirements:</strong>
                            <br />
                            {selectedEvent.requirements}
                            <br />
                          </>
                        ) : null}

                        {/* Additional Notes */}
                        {selectedEvent?.additional_notes ? (
                          <>
                            <strong>Notes:</strong>
                            <br />
                            {selectedEvent.additional_notes}
                            <br />
                          </>
                        ) : null}

                        {/* Display Colour */}
                        {selectedEvent?.display_colour ? (
                          <>
                            <strong>Display Colour:</strong>
                            <br />
                            <div
                              className="mt-2"
                              style={{
                                width: "30px",
                                height: "30px",
                                backgroundColor: selectedEvent.display_colour,
                                borderRadius: "10%",
                                border: "1px solid #000",
                              }}
                            />
                          </>
                        ) : null}

                        {/* Placeholder text if none of the fields are defined */}
                        {!selectedEvent?.cost &&
                          !selectedEvent?.equipment &&
                          !selectedEvent?.requirements &&
                          !selectedEvent?.additional_notes &&
                          !selectedEvent?.display_colour ? (
                          <span>No additional information available.</span>
                        ) : null}
                      </Card.Text>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {selectedEvent?.cancelled ? (
                  <div>
                    <strong>Cancellation Reason:</strong>
                    <br />
                    <span>
                      {selectedEvent?.reason
                        ? selectedEvent.reason
                        : "No reason provided."}
                    </span>
                  </div>
                ) : null}
              </>
            )}
            {activeTab === "actions" && (
              <>
                <Form.Group className="mt-4 mb-4">
                  {/* React Select Dropdown for Actions */}
                  <Select
                    value={selectedAction}
                    onChange={handleActionChange}
                    options={selectActions()}
                    placeholder="Choose an action..."
                  />
                </Form.Group>
                {selectedAction && (
                  <Alert variant="info" className="mt-3">
                    {actionDescriptions[selectedAction.value]}
                  </Alert>
                )}

                {selectedAction && selectedAction.value === "subscribe" && (
                  <Button
                    variant="success"
                    onClick={handleSubscribe}
                    className="w-100"
                  >
                    Subscribe to Class
                  </Button>
                )}
                {selectedAction && selectedAction.value === "proposal" && (
                  <Button
                    variant="success"
                    onClick={handleTimeProposal}
                    className="w-100 mt-2"
                  >
                    Propose New Time
                  </Button>
                )}
              </>
            )}
          </Modal.Body>
        </Modal>
      </PublicLayout>
    </div>
  );
};

export default PublicTimetable;
