import React from "react";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import Features from "../components/Features";
import Pricing from "../components/Pricing";
import Footer from "../components/Footer";
import LandingBanner from "../components/LandingBanner";
import FeatureSelector from "../components/FeatureSelector";
import PublicFeatureSelector from "../components/PublicFeatureSelector";

const Landing = () => (
  <div>
    <Header />
    <HeroSection />
    <FeatureSelector />
    <PublicFeatureSelector />
    <section id="pricing">
      <Pricing />
    </section>
    <LandingBanner />
    <Footer />
  </div>
);

export default Landing;
