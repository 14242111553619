import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const LandingBanner = () => {
  return (
    <div className="landing-banner">
      <Container>
        <Row className="justify-content-center">
          <Col md={10} className="text-center">
            <h1 className="landing-style-text">
              Streamline your class schedule with Timetablr.
            </h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LandingBanner;
