import React, { useState } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Table,
  Badge,
} from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { jsPDF } from "jspdf";

const EventsDayCards = ({
  events,
  onEventClick,
  headerColor = "#007bff",
  headerTextColor = "white",
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const daysPerPage = 3;

  // Group events by day (YYYY-MM-DD format)
  const groupedEvents = events.reduce((acc, event) => {
    const eventDate = moment(event.start).format("YYYY-MM-DD");
    if (!acc[eventDate]) {
      acc[eventDate] = [];
    }
    acc[eventDate].push(event);
    return acc;
  }, {});

  // Sort events within each day by start time
  Object.keys(groupedEvents).forEach((day) => {
    groupedEvents[day].sort((a, b) => moment(a.start).diff(moment(b.start)));
  });

  const daysArray = Object.keys(groupedEvents).sort(); // Sorted array of days

  // Calculate total pages
  const totalPages = Math.ceil(daysArray.length / daysPerPage);

  // Pagination logic: slice the days array based on the current page
  const currentDays = daysArray.slice(
    currentPage * daysPerPage,
    (currentPage + 1) * daysPerPage
  );

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    let yOffset = 10; // Initial vertical offset

    // Loop through all days and their events
    Object.keys(groupedEvents).forEach((day) => {
      doc.setFontSize(16);
      doc.text(moment(day).format("dddd, MMMM Do YYYY"), 10, yOffset);
      yOffset += 10; // Add space before the events

      groupedEvents[day].forEach((event) => {
        doc.setFontSize(12);
        doc.text(`${event.title}`, 10, yOffset);
        doc.text(
          `${moment(event.start).format("h:mm A")} - ${moment(event.end).format("h:mm A")}`,
          150,
          yOffset
        );
        yOffset += 10;

        // Add duration
        const duration = moment.duration(
          moment(event.end).diff(moment(event.start))
        );
        const formattedDuration = `${String(duration.hours()).padStart(2, "0")}:${String(duration.minutes()).padStart(2, "0")}`;
        doc.text(`Duration: ${formattedDuration}`, 10, yOffset);
        yOffset += 5;

        // Add additional info
        doc.text(`Level: ${event.level || "No Level"}`, 10, yOffset);
        doc.text(
          `Location: ${event.location || "No Location"}`,
          10,
          yOffset + 5
        );

        // Display all instructors
        if (event.instructors && event.instructors.length > 0) {
          const instructors = event.instructors
            .map((inst) => inst.name)
            .join(", ");
          doc.text(`Instructors: ${instructors}`, 10, yOffset + 10);
        } else {
          doc.text(`Instructors: No Instructor`, 10, yOffset + 10);
        }

        // Add event status
        if (event.cancelled) {
          doc.setTextColor(255, 0, 0); // Red for cancelled
          doc.text("Cancelled", 10, yOffset + 15);
          doc.setTextColor(0); // Reset color
        }
        if (event.old_start_date) {
          doc.setTextColor(255, 165, 0); // Orange for rescheduled
          doc.text("Rescheduled", 10, yOffset + 15);
          doc.setTextColor(0); // Reset color
        }
        yOffset += 25; // Add some space between events
      });

      yOffset += 10; // Add space before the next day header
    });

    doc.save("events.pdf");
  };

  return (
    <Container>
      {events.length === 0 ? (
        <Row className="justify-content-center">
          <Col md={12} className="text-center">
            <h5 style={{ color: "grey" }}>No events available.</h5>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            {currentDays.map((day) => (
              <Col md={12} key={day} className="mb-4">
                <Card>
                  <Card.Header
                    style={{
                      backgroundColor: headerColor,
                      color: headerTextColor,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong>{moment(day).format("dddd, MMMM Do YYYY")}</strong>
                    <span style={{ fontWeight: "bold" }}>
                      {(() => {
                        // Calculate total duration for the day
                        const eventsForDay = groupedEvents[day];
                        const totalDuration = eventsForDay.reduce(
                          (acc, event) => {
                            return acc.add(
                              moment.duration(
                                moment(event.end).diff(moment(event.start))
                              )
                            );
                          },
                          moment.duration(0)
                        );
                        return `Total: ${String(totalDuration.hours()).padStart(2, "0")}:${String(totalDuration.minutes()).padStart(2, "0")}`;
                      })()}
                    </span>
                  </Card.Header>
                  <Card.Body>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Time</th>
                          <th>Duration</th>
                          <th>Instructor(s)</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupedEvents[day].map((event) => (
                          <tr key={event.id}>
                            <td>
                              <a
                                href="#"
                                onClick={() => onEventClick(event)} // Open the modal when title is clicked
                                style={{
                                  color: event.display_colour,
                                  textDecoration: "none",
                                  fontWeight: "bold",
                                }}
                              >
                                &bull; {event.title}
                                {event.level && ` - ${event.level}`}{" "}
                                {/* Display level if defined */}
                              </a>
                            </td>
                            <td>
                              {moment(event.start).format("HH:mm")} -{" "}
                              {moment(event.end).format("HH:mm")}
                            </td>
                            <td>
                              {(() => {
                                const duration = moment.duration(
                                  moment(event.end).diff(moment(event.start))
                                );
                                return `${String(duration.hours()).padStart(2, "0")}:${String(duration.minutes()).padStart(2, "0")}`;
                              })()}
                            </td>
                            <td>
                              {event.instructors && event.instructors.length > 0
                                ? event.instructors
                                    .map((instructor) => instructor.name)
                                    .join(", ")
                                : "No Instructor"}
                            </td>
                            <td>
                              {event.cancelled ? (
                                <Badge bg="danger">Cancelled</Badge>
                              ) : event.old_start_date ? (
                                <Badge bg="warning">
                                  Rescheduled from:&nbsp;
                                  {new Date(
                                    event?.old_start_date
                                  ).toLocaleDateString(undefined, {
                                    month: "long",
                                    day: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </Badge>
                              ) : (
                                <Badge bg="success">No Changes</Badge>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="mt-4 mb-4 justify-content-center">
            <Col
              xs={12}
              sm={6}
              className="d-flex flex-column flex-sm-row justify-content-between"
            >
              <Button
                onClick={goToPreviousPage}
                disabled={currentPage === 0}
                className="mb-2 mb-sm-0 me-sm-2" // Adjust margin for small screens
                style={{
                  border: "none",
                  flex: 1,
                  color: headerTextColor,
                  backgroundColor: headerColor,
                }} // Make button flexible
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
              <Button
                onClick={goToNextPage}
                disabled={currentPage === totalPages - 1}
                className="mb-2 mb-sm-0 me-sm-2" // Adjust margin for small screens
                style={{
                  border: "none",
                  flex: 1,
                  color: headerTextColor,
                  backgroundColor: headerColor,
                }} // Make button flexible
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
              <Button
                onClick={downloadPDF}
                className="mb-2 mb-sm-0" // Adjust margin for small screens
                style={{
                  border: "none",
                  flex: 1,
                  color: headerTextColor,
                  backgroundColor: headerColor,
                }} // Make button flexible
              >
                Download PDF
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default EventsDayCards;
