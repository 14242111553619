import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { Card, Button, Alert, ListGroup } from "react-bootstrap";
import BasicHeader from "../components/BasicHeader";
import api from "../axiosInstance";

const stripePromise = loadStripe(
  "pk_live_51PzD99Hb0z7d6EtcR0tWhvGWegCOeawJzCo7jPzD5stmcBUCOiIbc4mepv6MUlkHEMQ0wZ57EglVojnJuhsRMelH00zkBx8uEd"
);

const Payment = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  // Handles the checkout process
  const handleCheckout = async () => {
    setLoading(true);
    setError("");
    try {
      const token = localStorage.getItem("tt_jwt");
      const response = await api.post(
        "/api/create-checkout-session",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Redirect to Stripe Checkout
      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });

      if (result.error) {
        setError(result.error.message);
        setLoading(false);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            setError(err.response.data.message);
            setLoading(false);
            break;
          default:
            setError("Failed to initiate payment. Please try again.");
            setLoading(false);
        }
      } else {
        setError("Failed to initiate payment. Please try again.");
        setLoading(false);
      }
    }
  };

  // Success message or loading spinner
  if (success) {
    return <h3>Payment Successful! Thank you for signing up!</h3>;
  }

  return (
    <div className="page-background">
      <BasicHeader />
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Card style={{ width: "400px" }}>
          <Card.Body>
            {error && <Alert variant="danger">{error}</Alert>}
            <h3 className="text-center">Complete Your Payment</h3>
            <ListGroup className="mb-4 mt-4">
              <ListGroup.Item>✔️ Timetables</ListGroup.Item>
              <ListGroup.Item>✔️ Class Descriptions</ListGroup.Item>
              <ListGroup.Item>✔️ Instructor Profiles</ListGroup.Item>
              <ListGroup.Item>
                ✔️ Class Cancellation and Rescheduling Notifications
              </ListGroup.Item>
              <ListGroup.Item>✔️ Class Scheduling Proposals</ListGroup.Item>
              <ListGroup.Item>✔️ Custom Theme</ListGroup.Item>
            </ListGroup>
            <h5 className="text-center">7 day free trial and then $27/month</h5>
            <h6 className="text-center">Cancel anytime!</h6>
            <Button
              variant="primary"
              onClick={handleCheckout}
              className="w-100 mt-4"
            >
              Pay Now
            </Button>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Payment;
