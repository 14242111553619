import React from "react";
import { Nav } from "react-bootstrap";
import Dashboard from "../../pages/Dashboard";

const TimetableSidebar = () => {
  return (
    <Nav className="flex-column" defaultActiveKey="/home">
      <Nav.Link style={{ color: "black" }} href="#createEvent">
        Create an Event
      </Nav.Link>
      <Nav.Link style={{ color: "black" }} href="#actions">
        Event Actions
      </Nav.Link>
    </Nav>
  );
};

export default TimetableSidebar;
