import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

function HeroSection() {
  // Function to determine font size based on window width
  const getFontSize = () => {
    const width = window.innerWidth;
    if (width < 576) return "1.5rem"; // Small screens
    if (width < 768) return "2rem";   // Medium screens
    return "2.5rem";                   // Default
  };

  // Set the font size dynamically
  const fontSize = getFontSize();

  return (
    <Container fluid className="py-5 hero-section">
      <div>
        <Row className="justify-content-center text-center">
          <Col md={8}>
            <h5
              className="display-4 mb-4"
              style={{ fontWeight: "bold", fontSize }}
            >
              Your <span style={{ color: "#3399ff" }}>All-in-One</span> Solution
              for Classes, Instructors, and Timetables
            </h5>
            <p className="lead mb-4">
              Ease the burden of maintaining your own website with public
              timetables, detailed class descriptions, instructor profiles and
              real-time class notifications.
            </p>
            <Button size="md" href="/auth/signup">
              Start your 7-day free trial today!{" "}
              <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default HeroSection;
