import React from "react";
import { Container, Card } from "react-bootstrap";

const GettingStartedContent = () => {
  return (
    <Container>
      <section id="introduction">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Welcome to Timetablr!</Card.Title>
            <Card.Text>
              Timetablr provides an easy-to-use interface for you to manage your
              class based business.
              <br />
              <br />
              We noticed that there were an abundant amount of class based
              businesses having to manage their own websites - figuring out how
              to display their class details, instructor profiles and an easy to
              manage timetable. Any updates either had to be done by a
              developer which was a long and expensive process or with a website
              builder which isn't always the easiest. There was a lack
              of consistency amongst the websites, making it harder for
              customers to navigate and figure how best to use it.
              <br />
              <br />
              Timetablr removes, or at least eases the burden of managing your
              own website. With Timetablr you can easily update and add new
              classes, instructors and events to your timetable whenever you
              like with little friction. Customers can view your class details,
              instructor profiles and timetable without needing to create an
              account. They can also subscribe to classes for real-time
              cancellation and rescheduling notifications. Customers can propose
              times that work better for them, helping to give you some insight
              into how best to structure your class schedule.
              <br />
              <br />
              This guide will help you get started and make the most out of our
              features.
            </Card.Text>
          </Card.Body>
        </Card>
      </section>

      <section id="getting-started">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Getting Started</Card.Title>
            <Card.Text>To get started, follow these steps:</Card.Text>
            <l>
              <li>Sign up for an account.</li>
              <li>Verify your email address.</li>
              <li>Log in to the platform.</li>
              <li>
                Setup your payment details through our payment provider Stripe.
              </li>
              <li>Create your first timetable!</li>
            </l>
            <br />
            To change your account or payment details, navigate to your profile
            page.
          </Card.Body>
        </Card>
      </section>
    </Container>
  );
};

export default GettingStartedContent;
