import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
  ListGroup,
  Modal,
  Form,
} from "react-bootstrap";
import DashboardHeader from "../components/DashboardHeader";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import api from "../axiosInstance";

const validatePassword = (password) => {
  const lengthRequirement = password.length >= 8;
  const uppercaseRequirement = /[A-Z]/.test(password);
  const lowercaseRequirement = /[a-z]/.test(password);
  const numberRequirement = /[0-9]/.test(password);
  const specialCharRequirement = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return {
    length: lengthRequirement,
    uppercase: uppercaseRequirement,
    lowercase: lowercaseRequirement,
    number: numberRequirement,
    specialChar: specialCharRequirement,
  };
};

const Profile = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [success, setSuccess] = useState(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const token = localStorage.getItem("tt_jwt");
        const response = await api.get("/api/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEmail(response.data.email);
      } catch (err) {
        console.error("Error fetching user email", err);
      }
    };

    fetchEmail();
  }, []);

  const handleUpdateCard = async () => {
    try {
      const token = localStorage.getItem("tt_jwt");
      const response = await api.get("/create-customer-portal-session", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      if (data.url) {
        window.location.href = data.url; // Redirect to the Stripe portal
      } else {
        console.error("Error creating portal session:", data.error);
      }
    } catch (error) {
      console.error("Error updating card details:", error);
    }
  };

  const handleUpdateEmail = async (e) => {
    e.preventDefault();
    setEmailError(null);
    setSuccess(null);
    setEmailError("");

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(newEmail)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    try {
      const token = localStorage.getItem("tt_jwt");
      const res = await api.post(
        "/api/user/update-email",
        { email: newEmail },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEmail(newEmail);
      setSuccess(res.data.message);
      setShowEmailModal(false);
    } catch (err) {
      setEmailError(
        err.response
          ? err.response.data.message
          : "An unexpected error occurred. Please contact us for further assistance."
      );
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setPasswordError(null);
    setSuccess(null);

    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords don't match.");
      return;
    }

    if (!Object.values(passwordValidation).every(Boolean)) {
      setPasswordError("Password does not meet the requirements.");
      return;
    }

    try {
      const token = localStorage.getItem("tt_jwt");
      await api.post(
        "/api/user/change-password",
        { currentPassword, newPassword, confirmPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess("Password changed successfully!");
      setShowPasswordModal(false);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (err) {
      setPasswordError(
        err.response ? err.response.data.message : "An error occurred."
      );
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    setPasswordValidation(validatePassword(newPassword));
  };

  return (
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh" }}>
      <DashboardHeader />
      <Container className="mt-4">
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>
                <h4>My Profile</h4>
                Manage your login credentials and subscription.
              </Card.Header>
              <Card.Body>
                {success && <Alert variant="success">{success}</Alert>}
                <ListGroup>
                  <ListGroup.Item>
                    <strong>Current Email:</strong> {email}
                  </ListGroup.Item>
                </ListGroup>
                <div className="mt-3">
                  <Button
                    variant="outline-primary"
                    onClick={() => setShowEmailModal(true)}
                    className="m-1"
                  >
                    Change Email
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => setShowPasswordModal(true)}
                    className="m-1"
                  >
                    Change Password
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={handleUpdateCard}
                    className="m-1"
                  >
                    Manage Billing
                  </Button>
                </div>
                <div>
                  <Button
                    variant="outline-secondary"
                    onClick={() => navigate("/dashboard")}
                    className="m-1 mt-5"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                    Back to Dashboard
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Email Modal */}
      <Modal show={showEmailModal} onHide={() => setShowEmailModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Email Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleUpdateEmail}>
            <Form.Group controlId="formNewEmail">
              <Form.Label>
                New Email Address <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Enter new email address"
                isInvalid={!!emailError}
              />
              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Update Email
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Password Modal */}
      <Modal
        show={showPasswordModal}
        onHide={() => setShowPasswordModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleChangePassword}>
            <Form.Group controlId="formCurrentPassword">
              <Form.Label>
                Current Password <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Enter current password"
              />
            </Form.Group>
            <Form.Group controlId="formNewPassword" className="mt-3">
              <Form.Label>
                New Password <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={handlePasswordChange}
                placeholder="Enter new password"
                isInvalid={!!passwordError}
              />
              <Form.Control.Feedback type="invalid">
                {passwordError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formConfirmPassword" className="mt-3">
              <Form.Label>
                Confirm New Password <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm new password"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Change Password
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Profile;
