import React, { useState, useEffect } from "react";
import {
  Accordion,
  Alert,
  Container,
  Card,
  Button,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { HexColorPicker } from "react-colorful";
import Layout from "./Layout";
import Select from "react-select";
import api from "../axiosInstance";

const Classes = () => {
  const timetableId = useParams()["id"];
  const [classes, setClasses] = useState([]);

  const [instructors, setInstructors] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [error, setError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editColour, setEditColour] = useState("");
  const [newClass, setNewClass] = useState({
    title: "",
    description: "",
    location: "",
    cost: "",
    level: "",
    instructor_ids: [],
    instructor_names: [],
    equipment: "",
    requirements: "",
    display_colour: "",
    additional_notes: "",
    timetable_id: timetableId,
  });
  const [selectedClass, setSelectedClass] = useState({
    title: "",
    description: "",
    location: "",
    cost: "",
    level: "",
    instructor_ids: [],
    instructor_names: [],
    equipment: "",
    requirements: "",
    display_colour: "",
    additional_notes: "",
    timetable_id: useParams()["id"],
  });
  const navigate = useNavigate();

  const fetchClasses = async () => {
    try {
      const response = await api.get(`/api/timetables/${timetableId}/classes`);
      setClasses(response.data);
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            navigate("/bad-request");
            break;
          case 402:
            navigate("/payment-required");
            break;
          case 401:
            navigate("/auth/login");
            break;
          case 500:
            navigate("/server-error");
            break;
          default:
            navigate("/not-found");
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  useEffect(() => {
    const fetchInstructors = async () => {
      try {
        const response = await api.get(
          `/api/timetables/${timetableId}/instructors`
        );
        setInstructors(response.data);
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    };

    fetchClasses();
    fetchInstructors();
  }, [timetableId]);

  const handleCreateClass = async () => {
    setTitleError("");
    setDescriptionError("");
    setError("");
    setUpdateError("");

    if (!newClass.title.trim()) {
      setTitleError("Title is required");
      return;
    }

    if (!newClass.description.trim()) {
      setDescriptionError("Description is required");
      return;
    }

    try {
      const token = localStorage.getItem("tt_jwt");
      await api.post(
        "/api/classes",
        {
          title: newClass.title,
          description: newClass.description,
          location: newClass.location,
          instructor_ids: newClass.instructor_ids,
          level: newClass.level,
          cost: newClass.cost,
          requirements: newClass.requirements,
          equipment: newClass.equipment,
          additional_notes: newClass.additional_notes,
          display_colour: newClass.display_colour,
          timetable_id: newClass.timetable_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowCreateModal(false);
      setNewClass({
        title: "",
        description: "",
        location: "",
        instructor_ids: [],
        instructor_names: [],
        level: "",
        display_colour: "",
        timetable_id: timetableId,
      });
      fetchClasses();
    } catch (err) {
      if (err.response) {
        if (err.response.data?.message) {
          setError(err.response.data.message);
        } else {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  const handleUpdateClass = async () => {
    if (!selectedClass) return;

    setTitleError("");
    setError("");
    setUpdateError("");
    setDescriptionError("");

    if (!selectedClass.title.trim()) {
      setTitleError("Title is required");
      return;
    }

    if (!selectedClass.description.trim()) {
      setDescriptionError("Description is required");
      return;
    }

    try {
      const token = localStorage.getItem("tt_jwt");
      await api.put(
        `/api/classes/${selectedClass.id}`,
        {
          title: selectedClass.title,
          description: selectedClass.description,
          location: selectedClass.location,
          instructor_ids: selectedClass.instructor_ids,
          level: selectedClass.level,
          cost: selectedClass.cost,
          requirements: selectedClass.requirements,
          equipment: selectedClass.equipment,
          additional_notes: selectedClass.additional_notes,
          display_colour: editColour,
          timetable_id: selectedClass.timetable_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowEditModal(false);
      setSelectedClass(null);
      fetchClasses();
    } catch (err) {
      if (err.response) {
        if (err.response.data?.message) {
          setUpdateError(err.response.data.message);
        } else {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  const handleDeleteClass = async (id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this class? This will delete all associated events."
      )
    ) {
      try {
        const token = localStorage.getItem("tt_jwt");
        await api.delete(`/api/classes/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        fetchClasses();
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    }
  };

  const levelOptions = [
    { value: "", label: "Select level" },
    { value: "Beginner", label: "Beginner" },
    { value: "Intermediate", label: "Intermediate" },
    { value: "Advanced", label: "Advanced" },
  ];

  const instructorOptions = instructors.map((instructor) => ({
    value: instructor.id,
    label: instructor.name,
  }));

  const handleShowCreateModal = () => {
    setNewClass({
      title: "",
      description: "",
      location: "",
      cost: "",
      level: "",
      instructor_ids: [],
      instructor_names: [],
      equipment: "",
      requirements: "",
      display_colour: "",
      additional_notes: "",
      timetable_id: timetableId,
    });
    setShowCreateModal(true);
  };
  const handleCloseCreateModal = () => setShowCreateModal(false);
  const handleShowEditModal = (cls) => {
    setSelectedClass(cls);
    setEditColour(cls.display_colour);
    setShowEditModal(true);
    setDescriptionError("");
    setTitleError("");
    setError("");
    setUpdateError("");
    setUpdateError("");
  };
  const handleCloseEditModal = () => {
    setSelectedClass(null);
    setShowEditModal(false);
    setDescriptionError("");
    setTitleError("");
    setError("");
    setUpdateError("");
    setUpdateError("");
  };

  const handleHexChange = (e) => {
    const hexValue = e.target.value;
    setNewClass({ ...newClass, display_colour: hexValue });
  };

  const handleHexChangeEdit = (e) => {
    const hexValue = e.target.value;
    setEditColour(hexValue);
  };

  return (
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh" }}>
      <Layout>
        <Container className="mt-4">
          <Row className="align-items-center">
            <Col>
              <h3>Manage Classes</h3>
            </Col>
            <Col className="text-end">
              <Button
                variant="outline-primary"
                onClick={handleShowCreateModal}
                className="mr-8"
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className={window.innerWidth < 768 ? "" : "me-2"}
                />{" "}
                {/* Icon always shown */}
                <span className="d-none d-md-inline">Create Class</span>{" "}
                {/* Show text only on medium and larger screens */}
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            {classes.length === 0 ? (
              <p>No classes available.</p>
            ) : (
              classes.map((cls) => (
                <Col key={cls.id} md={4} className="mb-4">
                  <Card
                    className="h-100 d-flex flex-column"
                    style={{
                      borderTop: cls.display_colour
                        ? `6px solid ${cls.display_colour}`
                        : "none", // Add top border if display_colour is defined
                    }}
                  >
                    <Card.Header>
                      <Card.Title className="fw-bold">
                        {cls.title} {cls.level ? `- ${cls.level}` : ""}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body className="d-flex flex-column">
                      <Card.Text>{cls.description}</Card.Text>
                      <Card.Text>
                        {cls.location && (
                          <>
                            <strong>Location:</strong>
                            <br />
                            {cls.location}
                            <br />
                          </>
                        )}
                      </Card.Text>
                      <Card.Text>
                        {cls.instructor_names &&
                          cls.instructor_names.length > 0 && (
                            <>
                              <strong>Instructor(s):</strong>
                              <br />
                              {cls.instructor_names.map((name, index) => (
                                <span key={index}>
                                  {name}
                                  <br />
                                </span>
                              ))}
                            </>
                          )}
                      </Card.Text>
                      <Card.Text>
                        {cls.level && (
                          <>
                            <strong>Level:</strong>
                            <br />
                            {cls.level}
                            <br />
                          </>
                        )}
                      </Card.Text>
                      <Accordion defaultActiveKey="1" className="mt-auto">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Additional Info</Accordion.Header>
                          <Accordion.Body>
                            <Card.Text>
                              {/* Cost */}
                              {cls.cost ? (
                                <>
                                  <strong>Cost:</strong>
                                  <br />
                                  {cls.cost}
                                  <br />
                                </>
                              ) : null}

                              {/* Equipment */}
                              {cls.equipment ? (
                                <>
                                  <strong>Equipment:</strong>
                                  <br />
                                  {cls.equipment}
                                  <br />
                                </>
                              ) : null}

                              {/* Requirements */}
                              {cls.requirements ? (
                                <>
                                  <strong>Requirements:</strong>
                                  <br />
                                  {cls.requirements}
                                  <br />
                                </>
                              ) : null}

                              {/* Additional Notes */}
                              {cls.additional_notes ? (
                                <>
                                  <strong>Notes:</strong>
                                  <br />
                                  {cls.additional_notes}
                                  <br />
                                </>
                              ) : null}

                              {/* Display Colour */}
                              {cls.display_colour ? (
                                <>
                                  <strong>Display Colour:</strong>
                                  <br />
                                  <div className="d-flex align-items-center mt-2">
                                    <div
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                        backgroundColor: cls.display_colour,
                                        borderRadius: "10%",
                                        border: "1px solid #000",
                                        marginRight: "10px",
                                      }}
                                    />
                                    <p className="mb-0">{cls.display_colour}</p>
                                  </div>
                                </>
                              ) : null}

                              {/* Placeholder text if none of the fields are defined */}
                              {!cls.cost &&
                              !cls.equipment &&
                              !cls.requirements &&
                              !cls.additional_notes &&
                              !cls.display_colour ? (
                                <span>
                                  No additional information available.
                                </span>
                              ) : null}
                            </Card.Text>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Card.Body>
                    <Card.Footer>
                      <Row>
                        <Button
                          variant="warning"
                          onClick={() => handleShowEditModal(cls)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </Row>
                      <Row>
                        <Button
                          variant="danger"
                          className="mt-2"
                          onClick={() => handleDeleteClass(cls.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Container>

        {/* Create Class Modal */}
        <Modal show={showCreateModal} onHide={handleCloseCreateModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Class</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="warning" className="mt-2">
              These details will be publicly displayed on your page. Do not
              share anything you wouldn't want to be seen by the public.
            </Alert>
            <Form>
              <Form.Group controlId="formClassTitle" className="mb-2">
                <Form.Label className="fw-bold">
                  Title <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter class title"
                  value={newClass.title}
                  onChange={(e) =>
                    setNewClass({ ...newClass, title: e.target.value })
                  }
                  required
                  isInvalid={!!titleError}
                />
                <Form.Control.Feedback type="invalid">
                  {titleError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formClassDescription" className="mb-2">
                <Form.Label className="fw-bold">
                  Description <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter class description"
                  value={newClass.description}
                  onChange={(e) =>
                    setNewClass({ ...newClass, description: e.target.value })
                  }
                  isInvalid={!!descriptionError}
                />
                <Form.Control.Feedback type="invalid">
                  {descriptionError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formClassLocation" className="mb-2">
                <Form.Label className="fw-bold">Location</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter class location"
                  value={newClass.location}
                  onChange={(e) =>
                    setNewClass({ ...newClass, location: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formClassLevel" className="mb-2">
                <Form.Label className="fw-bold">Level</Form.Label>
                <Select
                  value={levelOptions.find(
                    (option) => option.value === newClass.level
                  )}
                  onChange={(selectedOption) =>
                    setNewClass({ ...newClass, level: selectedOption.value })
                  }
                  options={levelOptions}
                  placeholder="Select level"
                />
              </Form.Group>
              <Form.Group controlId="formClassInstructor" className="mb-2">
                <Form.Label className="fw-bold">Instructors</Form.Label>
                <Select
                  isMulti // This enables multiple selections
                  value={instructorOptions.filter((option) =>
                    newClass.instructor_ids.includes(option.value)
                  )} // Find options matching selected instructor IDs
                  onChange={(selectedOptions) =>
                    setNewClass({
                      ...newClass,
                      instructor_ids: selectedOptions.map(
                        (option) => option.value
                      ),
                    })
                  }
                  options={instructorOptions}
                  placeholder="Select instructors"
                />
              </Form.Group>
              <div className="mt-2 mb-2">
                No instructors yet?&nbsp;
                <Link to={`/timetable/${timetableId}/instructors`}>
                  Create instructor
                </Link>
              </div>
              <Form.Group controlId="formClassCost" className="mb-2">
                <Form.Label className="fw-bold">Cost</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter class cost"
                  value={newClass.cost}
                  onChange={(e) =>
                    setNewClass({ ...newClass, cost: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formClassRequirements" className="mb-2">
                <Form.Label className="fw-bold">Requirements</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter class requirements"
                  value={newClass.requirements}
                  onChange={(e) =>
                    setNewClass({ ...newClass, requirements: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formClassEquipment" className="mb-2">
                <Form.Label className="fw-bold">Equipment</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter class equipment"
                  value={newClass.equipment}
                  onChange={(e) =>
                    setNewClass({ ...newClass, equipment: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formClassAdditionalNotes" className="mb-2">
                <Form.Label className="fw-bold">Additional Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter additional information"
                  value={newClass.additional_notes}
                  onChange={(e) =>
                    setNewClass({
                      ...newClass,
                      additional_notes: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formClassColour" className="mb-2">
                <Form.Label className="fw-bold">Display Colour</Form.Label>
                <HexColorPicker
                  color={newClass.display_colour}
                  onChange={(display_colour) =>
                    setNewClass({ ...newClass, display_colour })
                  }
                  className="mt-3"
                />
                <Form.Group controlId="formHexColor">
                  <Form.Label>Hex Color</Form.Label>
                  <Form.Control
                    type="text"
                    value={newClass.display_colour}
                    onChange={handleHexChange}
                    placeholder="#000000"
                  />
                </Form.Group>
              </Form.Group>
              <Button
                variant="primary"
                onClick={handleCreateClass}
                className="mt-3"
              >
                Publish Class
              </Button>
              {error && (
                <Alert className="mt-4" variant="danger">
                  {error}
                </Alert>
              )}
            </Form>
          </Modal.Body>
        </Modal>

        {/* Edit Class Modal */}
        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Class</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="warning" className="mt-2">
              These details will be publicly displayed on your page. Do not
              share anything you wouldn't want to be seen by the public.
            </Alert>
            {selectedClass && (
              <Form>
                <Form.Group controlId="formEditClassTitle" className="mb-2">
                  <Form.Label className="fw-bold">
                    Title<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter class title"
                    value={selectedClass.title}
                    onChange={(e) =>
                      setSelectedClass({
                        ...selectedClass,
                        title: e.target.value,
                      })
                    }
                    isInvalid={!!titleError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {titleError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="formEditClassDescription"
                  className="mb-2"
                >
                  <Form.Label className="fw-bold">
                    Description <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter class description"
                    value={selectedClass.description}
                    onChange={(e) =>
                      setSelectedClass({
                        ...selectedClass,
                        description: e.target.value,
                      })
                    }
                    isInvalid={!!descriptionError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {descriptionError}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formEditClassLocation" className="mb-2">
                  <Form.Label className="fw-bold">Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter class location"
                    value={selectedClass.location}
                    onChange={(e) =>
                      setSelectedClass({
                        ...selectedClass,
                        location: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="formEditClassLevel" className="mb-2">
                  <Form.Label className="fw-bold">Level</Form.Label>
                  <Select
                    value={levelOptions.find(
                      (option) => option.value === selectedClass.level
                    )}
                    onChange={(selectedOption) =>
                      setSelectedClass({
                        ...selectedClass,
                        level: selectedOption.value,
                      })
                    }
                    options={levelOptions}
                    placeholder="Select level"
                  />
                </Form.Group>
                <Form.Group controlId="formClassInstructor" className="mb-2">
                  <Form.Label className="fw-bold">Instructors</Form.Label>
                  <Select
                    isMulti // Enables multiple selections
                    value={instructorOptions.filter(
                      (option) =>
                        selectedClass.instructor_ids &&
                        selectedClass.instructor_ids.includes(option.value)
                    )} // Ensure this checks for instructor_ids being defined
                    onChange={(selectedOptions) => {
                      const selectedIds = selectedOptions.map(
                        (option) => option.value
                      );
                      setSelectedClass({
                        ...selectedClass,
                        instructor_ids: selectedIds, // Ensure this updates correctly
                      });
                    }}
                    options={instructorOptions}
                    placeholder="Select instructors"
                  />
                </Form.Group>
                <Link
                  to={`/timetable/${timetableId}/instructors`}
                  style={{ display: "block", marginBottom: "20px" }}
                >
                  Create instructor
                </Link>
                <Form.Group controlId="formEditClassCost" className="mb-2">
                  <Form.Label className="fw-bold">Cost</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter class cost"
                    value={selectedClass.cost}
                    onChange={(e) =>
                      setSelectedClass({
                        ...selectedClass,
                        cost: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group
                  controlId="formEditClassRequirements"
                  className="mb-2"
                >
                  <Form.Label className="fw-bold">Requirements</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter class requirements"
                    value={selectedClass.requirements}
                    onChange={(e) =>
                      setSelectedClass({
                        ...selectedClass,
                        requirements: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="formEditClassEquipment" className="mb-2">
                  <Form.Label className="fw-bold">Equipment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter class equipment"
                    value={selectedClass.equipment}
                    onChange={(e) =>
                      setSelectedClass({
                        ...selectedClass,
                        equipment: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group
                  controlId="formEditClassAdditionalNotes"
                  className="mb-2"
                >
                  <Form.Label className="fw-bold">Additional Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter class additional notes"
                    value={selectedClass.additional_notes}
                    onChange={(e) =>
                      setSelectedClass({
                        ...selectedClass,
                        additional_notes: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group controlId="formEditClassColour" className="mb-2">
                  <Form.Label className="fw-bold">Display Colour</Form.Label>
                  <HexColorPicker
                    color={editColour}
                    onChange={setEditColour}
                    className="mt-3 mb-3"
                    width="30px"
                  />
                  <Form.Group controlId="formHexColor">
                    <Form.Label>Hex Color</Form.Label>
                    <Form.Control
                      type="text"
                      value={editColour}
                      onChange={handleHexChangeEdit}
                      placeholder="#000000"
                    />
                  </Form.Group>
                </Form.Group>
                <Button
                  variant="primary"
                  className="mt-4"
                  onClick={handleUpdateClass}
                >
                  Publish Class
                </Button>
                {updateError && (
                  <Alert className="mt-4" variant="danger">
                    {updateError}
                  </Alert>
                )}
              </Form>
            )}
          </Modal.Body>
        </Modal>
      </Layout>
    </div>
  );
};

export default Classes;
