import React, { useState } from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";

const featuresData = [
  {
    id: 1,
    name: "Timetable Dashboard",
    image: "/images/landing_dashboard.png",
    icon: "📊",
    description: "Manage your timetables in the dashboard.",
  },
  {
    id: 2,
    name: "Timetable View",
    image: "/images/landing_timetable.png",
    icon: "faCalendar",
    description: "Display, schedule, cancel and reschedule your events.",
  },
  {
    id: 3,
    name: "Day View",
    image: "/images/landing_day_view.png",
    icon: "faCalendar",
    description: "View your events in the day view and generate PDFs of your class schedule.",
  },
  {
    id: 4,
    name: "Class Details",
    image: "/images/landing_classes.png",
    icon: "✅",
    description: "Explore detailed information about each class.",
  },
  {
    id: 5,
    name: "Instructor Profiles",
    image: "/images/landing_instructors.png",
    icon: "🤝",
    description: "Showcase your instructors with detailed profiles, including their name, bio, and social media links.",
  },
  {
    id: 6,
    name: "Reporting",
    image: "/images/landing_reports.png",
    icon: "🤝",
    description: "Track class cancellations and key performance metrics with detailed, real-time reports.",
  },
  {
    id: 7,
    name: "Proposals",
    image: "/images/landing_proposals.png",
    icon: "🤝",
    description: "Review and manage proposed class times to streamline and optimize your scheduling process. Your customers can propose times for classes that work better for them.",
  },
];

const FeatureSelector = () => {
  const [selectedFeature, setSelectedFeature] = useState(featuresData[0]);

  const handleFeatureSelect = (feature) => {
    setSelectedFeature(feature);
  };

  return (
    <Container className="mt-3">
      <Card className="p-4">
        <h1 className="text-center mb-4">Class Management Features</h1>
        <h6 className="text-center">
          Effortlessly create and manage your class details, instructor profiles
          and class schedules. Enjoy peace of mind with easy event cancellations and
          rescheduling, ensuring your customers are always informed via
          email notifications.
        </h6>

        <Row className="mt-3">
          <Col md={4}>
            <Card className="p-3 border-0 d-flex flex-column">
              {featuresData.map((feature) => (
                <Button
                  key={feature.id}
                  variant={
                    selectedFeature.id === feature.id ? "primary" : "light"
                  }
                  className="w-100 mb-3 d-flex align-items-center"
                  onClick={() => handleFeatureSelect(feature)}
                >
                  <div className="text-start">
                    <strong>{feature.name}</strong>
                    <div>{feature.description}</div>
                  </div>
                </Button>
              ))}
            </Card>
          </Col>

          <Col md={8}>
            <Card className="text-center p-3 border-0 d-flex align-items-center justify-content-center" style={{ height: '100%', backgroundColor: '#f0f4f8' }}>
              <img
                src={selectedFeature.image}
                alt={selectedFeature.name}
                style={{
                  maxWidth: "100%", // Increase size by adjusting maxWidth
                  maxHeight: "100vh", // Control the height (70% of the viewport height)
                  objectFit: "contain",
                }}
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default FeatureSelector;
