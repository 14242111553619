import React, { useState } from "react";
import { Alert, Card, Form, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import BasicHeader from "../components/BasicHeader";
import api from "../axiosInstance";

const validatePassword = (password) => {
  const lengthRequirement = password.length >= 8;
  const uppercaseRequirement = /[A-Z]/.test(password);
  const lowercaseRequirement = /[a-z]/.test(password);
  const numberRequirement = /[0-9]/.test(password);
  const specialCharRequirement = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return {
    length: lengthRequirement,
    uppercase: uppercaseRequirement,
    lowercase: lowercaseRequirement,
    number: numberRequirement,
    specialChar: specialCharRequirement,
  };
};

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const resetCode = query.get("code");

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordValidation(validatePassword(newPassword));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");
    setSuccessMessage("");

    if (!Object.values(passwordValidation).every(Boolean)) {
      setError("Password does not meet the requirements.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      // Assuming you have an API endpoint to reset the password
      await api.post("/api/reset-password", {
        resetCode,
        password,
      });
      setSuccessMessage("Your password has been reset successfully!");

      // Wait for 3 seconds before redirecting to login
      setTimeout(() => {
        window.location.href = "/auth/login";
      }, 3000);
    } catch (err) {
      setError(
        err.response.data.message || "An error occurred. Please try again."
      );
    }
  };

  return (
    <div className="page-background">
      <BasicHeader />
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Card style={{ width: "400px" }}>
          <Card.Body>
            <h3 className="text-center">Reset Password</h3>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && (
              <Alert variant="success">{successMessage}</Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formNewPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
                <Form.Text className="text-muted">
                  Your password must meet the following criteria:
                </Form.Text>
                <ul>
                  <li
                    className={
                      passwordValidation.length ? "text-success" : "text-danger"
                    }
                  >
                    At least 8 characters
                  </li>
                  <li
                    className={
                      passwordValidation.uppercase
                        ? "text-success"
                        : "text-danger"
                    }
                  >
                    At least one uppercase letter
                  </li>
                  <li
                    className={
                      passwordValidation.lowercase
                        ? "text-success"
                        : "text-danger"
                    }
                  >
                    At least one lowercase letter
                  </li>
                  <li
                    className={
                      passwordValidation.number ? "text-success" : "text-danger"
                    }
                  >
                    At least one number
                  </li>
                  <li
                    className={
                      passwordValidation.specialChar
                        ? "text-success"
                        : "text-danger"
                    }
                  >
                    At least one special character
                  </li>
                </ul>
              </Form.Group>
              <Form.Group controlId="formConfirmPassword" className="mt-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3 w-100">
                Reset Password
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
