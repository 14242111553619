import React from "react";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faUser,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

function DashboardHeader() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("tt_jwt");
    navigate("/auth/login");
  };

  const handleProfileClick = () => {
    navigate("/profile");
  };

  return (
    <Navbar
      expand="lg"
      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.15)" }} // Add lower border
    >
      <Container>
        <Navbar.Brand href="/">Timetablr</Navbar.Brand>
        <Nav>
          <Nav.Link href="/documentation">Documentation</Nav.Link>
          <Nav.Link href="/contact">Contact Us</Nav.Link>
        </Nav>
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-primary"
            id="profile-dropdown"
            style={{
              border: "none", // Remove border
              borderRadius: "5px", // Adjust border radius if needed
            }}
          >
            Profile
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              border: "none", // Remove border from dropdown menu
              boxShadow: "none", // Optional: remove shadow for a cleaner look
            }}
          >
            <Dropdown.Item onClick={handleProfileClick}>
              <FontAwesomeIcon icon={faUser} className="me-2" /> Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Navbar>
  );
}

export default DashboardHeader;
