import React from "react";
import LoginHeader from "../../components/LoginHeader";

const PaymentRequired = () => {
  return (
    <div className="text-dark">
      <LoginHeader />
      <div style={{ textAlign: "center", padding: "50px" }}>
        <h1>402 - Payment Required</h1>
        <p>
          The owner of the timetable is no longing paying for this service. To
          regain this service, they must login and reinitiate their
          subscription.
        </p>
      </div>
    </div>
  );
};

export default PaymentRequired;
