import React from "react";
import { Nav } from "react-bootstrap";
import Dashboard from "../../pages/Dashboard";

const DashboardSidebar = () => {
  return (
    <Nav className="flex-column" defaultActiveKey="/home">
      <Nav.Link style={{ color: "black" }} href="#create">
        Create a Timetable
      </Nav.Link>
      <Nav.Link style={{ color: "black" }} href="#edit">
        Edit & Delete Timetable
      </Nav.Link>
    </Nav>
  );
};

export default DashboardSidebar;
