import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomEvent = ({ event }) => (
  <OverlayTrigger
    placement="top"
    overlay={
      <Tooltip id={`tooltip-${event.title}`}>
        <strong>{event.title}</strong>
        {event.level && (
          <>
            <br />
            {event.level && `Level: ${event.level}`}
          </>
        )}
        {event.instructors && event.instructors.length > 0 && (
          <>
            <br />
            <strong>Instructors:</strong>
            <br />
            {event.instructors.map((instructor, index) => (
              <span key={index}>
                {instructor.name}
                {index < event.instructors.length - 1 && ", "}{" "}
                {/* Add a comma except for the last instructor */}
              </span>
            ))}
          </>
        )}
        {event.cancelled && (
          <>
            <br />
            <span style={{ color: "red" }}>Cancelled</span>
            <br />
            <span>{event.reason}</span>
          </>
        )}
        {event.old_start_date && (
          <>
            <br />
            <span style={{ color: "orange" }}>Rescheduled</span>
            <br />
            Original Date:{" "}
            {new Date(event.old_start_date).toLocaleDateString(undefined, {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </>
        )}
      </Tooltip>
    }
  >
    <div className={`custom-event ${event.cancelled ? "cancelled" : ""}`}>
      {event.title}
      <br />
      <br />
      {event.level}
    </div>
  </OverlayTrigger>
);

export default CustomEvent;
