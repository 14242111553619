import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ title, additionalText, labels, data, backgroundColor }) => {
  const [showModal, setShowModal] = useState(false);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: data,
        backgroundColor: backgroundColor,
      },
    ],
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <div
        style={{
          width: "100%",
          maxWidth: "500px",
          margin: "20px auto",
          cursor: "pointer",
        }}
        onClick={handleShow}
      >
        <div style={{ textAlign: "center" }}>
          <h4>{title}</h4>
          {additionalText && <p>{additionalText}</p>}
        </div>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                beginAtZero: true,
              },
              y: {
                beginAtZero: true,
                ticks: {
                  stepSize: 1, // Force the ticks to be integer
                  callback: function (value) {
                    return Number.isInteger(value) ? value : ""; // Show only integer values
                  },
                },
              },
            },
          }}
        />
      </div>

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <h4>{title}</h4>
            {additionalText && <p>{additionalText}</p>}
          </div>
          <div style={{ width: "100%" }}>
            <Bar
              data={chartData}
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: false,
                  },
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    beginAtZero: true,
                  },
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: 1, // Force the ticks to be integer
                      callback: function (value) {
                        return Number.isInteger(value) ? value : ""; // Show only integer values
                      },
                    },
                  },
                },
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BarChart;
