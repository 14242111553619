import React, { useState } from "react";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../calendarStyles.css";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const CustomToolbar = ({ label, onNavigate, onView }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  // Calculate min and max dates
  const today = moment();
  const minDate = today.clone().subtract(4, "weeks").toDate(); // 4 weeks prior
  const maxDate = today.clone().add(4, "weeks").toDate(); // 4 weeks ahead

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
    onNavigate("DATE", new Date(newDate)); // Navigate to the selected date immediately
  };

  return (
    <div
      className="rbc-toolbar"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px",
      }}
    >
      <button
        onClick={() => onNavigate("PREV")}
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "white",
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <span>{label}</span>
      <button
        onClick={() => onNavigate("NEXT")}
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          color: "white",
        }}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      {/* ButtonGroup for Week and Day Views */}
      <ButtonGroup style={{ marginLeft: "10px" }}>
        <Button
          variant="outline-primary"
          onClick={() => onView("week")}
          style={{ borderRight: "none" }}
        >
          Week
        </Button>
        <Button variant="outline-primary" onClick={() => onView("day")}>
          Day
        </Button>
      </ButtonGroup>
      {/* Date Picker */}
      <input
        type="date"
        value={moment(selectedDate).format("YYYY-MM-DD")}
        onChange={handleDateChange}
        min={moment(minDate).format("YYYY-MM-DD")} // Set minimum date
        max={moment(maxDate).format("YYYY-MM-DD")} // Set maximum date
        style={{
          marginLeft: "10px",
          border: "1px solid #ccc",
          borderRadius: "2px",
          padding: "5px",
          color: "black",
        }}
      />
    </div>
  );
};

export default CustomToolbar;
