import React, { useState, useEffect } from "react";
import {
  Alert,
  Badge,
  OverlayTrigger,
  Tooltip,
  Container,
  Card,
  Button,
  Modal,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import { HexColorPicker } from "react-colorful";
import DashboardHeader from "../components/DashboardHeader";
import api from "../axiosInstance";

const Dashboard = () => {
  const [timetables, setTimetables] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [facebookError, setFacebookError] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [instagramError, setInstagramError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedTimetable, setSelectedTimetable] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newTimetableTitle, setNewTimetableTitle] = useState("");
  const [newTimetableDescription, setNewTimetableDescription] = useState("");
  const [newTimetableWebsite, setNewTimetableWebsite] = useState("");
  const [newTimetableFacebook, setNewTimetableFacebook] = useState("");
  const [newTimetableHeaderColour, setNewTimetableHeaderColour] = useState("");
  const [editTimetableHeaderColour, setEditTimetableHeaderColour] =
    useState("");
  const [editTimetableTextColour, setEditTimetableTextColour] = useState("");
  const [newTimetableTextColour, setNewTimetableTextColour] = useState("");
  const [newTimetableInstagram, setNewTimetableInstagram] = useState("");
  const [newTimetableAddressLineOne, setNewTimetableAddressLineOne] =
    useState("");
  const [newTimetableAddressLineTwo, setNewTimetableAddressLineTwo] =
    useState("");
  const [newTimetableCity, setNewTimetableCity] = useState("");
  const [newTimetablePostcode, setNewTimetablePostcode] = useState("");
  const [newTimetableCountry, setNewTimetableCountry] = useState("");
  const [newTimetablePhone, setNewTimetablePhone] = useState("");
  const [newTimetableEmail, setNewTimetableEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchTimetables();
  }, []);

  const fetchTimetables = async () => {
    try {
      const token = localStorage.getItem("tt_jwt");
      const response = await api.get("/api/timetables", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTimetables(response.data);
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            navigate("/bad-request");
            break;
          case 402:
            navigate("/payment-required");
            break;
          case 401:
            navigate("/auth/login");
            break;
          case 500:
            navigate("/server-error");
            break;
          default:
            navigate("/not-found");
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  const handleCreateTimetable = async () => {
    setTitleError("");
    setDescriptionError("");
    setFacebookError("");
    setInstagramError("");
    setWebsiteError("");

    if (!newTimetableTitle.trim()) {
      setTitleError("Title is required");
      return;
    }

    if (!newTimetableDescription.trim()) {
      setDescriptionError("Description is required");
      return;
    }

    if (newTimetableFacebook.trim()) {
      try {
        new URL(newTimetableFacebook);
      } catch (error) {
        setFacebookError(
          "Invalid URL format. Please ensure the URL starts with 'http://' or 'https://' and follows the correct format."
        );
        return;
      }
    }

    if (newTimetableInstagram.trim()) {
      try {
        new URL(newTimetableInstagram);
      } catch (error) {
        setInstagramError(
          "Invalid URL format. Please ensure the URL starts with 'http://' or 'https://' and follows the correct format."
        );
        return;
      }
    }

    if (newTimetableWebsite.trim()) {
      try {
        new URL(newTimetableWebsite);
      } catch (error) {
        setWebsiteError(
          "Invalid URL format. Please ensure the URL starts with 'http://' or 'https://' and follows the correct format."
        );
        return;
      }
    }

    try {
      const token = localStorage.getItem("tt_jwt");
      await api.post(
        "/api/timetables",
        {
          title: newTimetableTitle,
          description: newTimetableDescription,
          website: newTimetableWebsite,
          facebook: newTimetableFacebook,
          instagram: newTimetableInstagram,
          address_line_1: newTimetableAddressLineOne,
          address_line_2: newTimetableAddressLineTwo,
          city: newTimetableCity,
          postcode: newTimetablePostcode,
          country: newTimetableCountry,
          phone: newTimetablePhone,
          email: newTimetableEmail,
          header_colour: newTimetableHeaderColour,
          header_text_colour: newTimetableTextColour,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchTimetables();
      setShowCreateModal(false);
      setNewTimetableTitle("");
      setNewTimetableDescription("");
      setNewTimetableWebsite("");
      setNewTimetableFacebook("");
      setNewTimetableInstagram("");
      setNewTimetableAddressLineOne("");
      setNewTimetableAddressLineTwo("");
      setNewTimetableCity("");
      setNewTimetableCountry("");
      setNewTimetablePostcode("");
      setNewTimetablePhone("");
      setNewTimetableEmail("");
      setNewTimetableHeaderColour("");
      setNewTimetableTextColour("");
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            navigate("/bad-request");
            break;
          case 402:
            navigate("/payment-required");
            break;
          case 401:
            navigate("/auth/login");
            break;
          case 500:
            navigate("/server-error");
            break;
          default:
            navigate("/not-found");
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  const handleUpdateTimetable = async () => {
    if (!selectedTimetable) return;

    setTitleError("");
    setDescriptionError("");
    setFacebookError("");
    setWebsiteError("");

    if (!selectedTimetable.title.trim()) {
      setTitleError("Title is required");
      return;
    }

    if (!selectedTimetable.description.trim()) {
      setDescriptionError("Description is required");
      return;
    }

    if (selectedTimetable.facebook.trim()) {
      try {
        new URL(selectedTimetable.facebook); // If URL is invalid, this will throw an error
        // Do something with the valid URL (e.g., submit the form)
      } catch (error) {
        setFacebookError(
          "Invalid URL format. Please ensure the URL starts with 'http://' or 'https://' and follows the correct format."
        );
        return;
      }
    }

    if (selectedTimetable.instagram.trim()) {
      try {
        new URL(selectedTimetable.instagram);
      } catch (error) {
        setInstagramError(
          "Invalid URL format. Please ensure the URL starts with 'http://' or 'https://' and follows the correct format."
        );
        return;
      }
    }

    if (selectedTimetable.website.trim()) {
      try {
        new URL(selectedTimetable.website);
      } catch (error) {
        setWebsiteError(
          "Invalid URL format. Please ensure the URL starts with 'http://' or 'https://' and follows the correct format."
        );
        return;
      }
    }

    try {
      const token = localStorage.getItem("tt_jwt");
      await api.put(
        `/api/timetables/${selectedTimetable.id}`,
        {
          title: selectedTimetable.title,
          description: selectedTimetable.description,
          website: selectedTimetable.website,
          address_line_1: selectedTimetable.address_line_1,
          address_line_2: selectedTimetable.address_line_2,
          city: selectedTimetable.city,
          postcode: selectedTimetable.postcode,
          country: selectedTimetable.country,
          phone: selectedTimetable.phone,
          email: selectedTimetable.email,
          facebook: selectedTimetable.facebook,
          instagram: selectedTimetable.instagram,
          header_colour: editTimetableHeaderColour,
          header_text_colour: editTimetableTextColour,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowEditModal(false);
      setSelectedTimetable(null);
      fetchTimetables();
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            navigate("/bad-request");
            break;
          case 402:
            navigate("/payment-required");
            break;
          case 401:
            navigate("/auth/login");
            break;
          case 500:
            navigate("/server-error");
            break;
          default:
            navigate("/not-found");
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  const handleShowEditModal = (timetable) => {
    setSelectedTimetable(timetable);
    setEditTimetableHeaderColour(timetable.header_colour);
    setEditTimetableTextColour(timetable.header_text_colour);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedTimetable(null);
    setShowEditModal(false);
    setTitleError("");
    setFacebookError("");
    setInstagramError("");
    setWebsiteError("");
    setDescriptionError("");
  };

  const handleDeleteTimetable = async (id) => {
    if (
      window.confirm(
        "Are you sure you want to delete this timetable? This will delete all associated classes, instructors and events."
      )
    ) {
      try {
        const token = localStorage.getItem("tt_jwt");
        await api.delete(`/api/timetables/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        fetchTimetables();
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    }
  };
  return (
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh" }}>
      <DashboardHeader />
      <Container className="mt-4">
        <Card>
          <Card.Body>
            <Row className="align-items-center mb-3">
              <Col>
                <Card.Title>Your Timetables</Card.Title>
              </Col>
              <Col xs="auto" className="text-right">
                <Button
                  variant="outline-primary"
                  onClick={() => setShowCreateModal(true)}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={window.innerWidth < 768 ? "" : "me-2"}
                  />{" "}
                  {/* Icon always shown */}
                  <span className="d-none d-md-inline">
                    Create Timetable
                  </span>{" "}
                  {/* Show text only on medium and larger screens */}
                </Button>
              </Col>
            </Row>
            {timetables.length > 0 ? (
              timetables.map((timetable) => {
                const hasAddress =
                  timetable.address_line_1 ||
                  timetable.address_line_2 ||
                  timetable.city ||
                  timetable.postcode ||
                  timetable.country;
                const hasContact = timetable.phone || timetable.email;
                const hasCustomTheme =
                  timetable.header_colour || timetable.header_text_colour;

                return (
                  <Card
                    key={timetable.id}
                    className="mb-3"
                    style={{ background: "#F0F4F8" }}
                  >
                    <Card.Body>
                      <Card.Title>{timetable.title}</Card.Title>
                      <Card.Text>{timetable.description}</Card.Text>
                      {hasAddress && (
                        <>
                          <Card.Subtitle className="mb-2 fw-bold">
                            Address
                          </Card.Subtitle>
                          {timetable.address_line_1 && (
                            <span>
                              {timetable.address_line_1}
                              <br />
                            </span>
                          )}
                          {timetable.address_line_2 && (
                            <span>
                              {timetable.address_line_2}
                              <br />
                            </span>
                          )}
                          {timetable.city && (
                            <span>
                              {timetable.city}
                              <br />
                            </span>
                          )}
                          {timetable.postcode && (
                            <span>
                              {timetable.postcode}
                              <br />
                            </span>
                          )}
                          {timetable.country && (
                            <span>
                              {timetable.country}
                              <br />
                            </span>
                          )}
                        </>
                      )}

                      {hasContact && (
                        <>
                          <Card.Subtitle className="mt-4 mb-2 fw-bold">
                            Contact
                          </Card.Subtitle>
                          {timetable.phone && (
                            <span>
                              {timetable.phone}
                              <br />
                            </span>
                          )}
                          {timetable.email && (
                            <span>
                              {timetable.email}
                              <br />
                            </span>
                          )}
                        </>
                      )}

                      {hasCustomTheme && (
                        <div>
                          <Card.Subtitle className="mt-4 mb-2 fw-bold">
                            Colour Scheme
                          </Card.Subtitle>
                          {timetable.header_colour && (
                            <div className="d-flex align-items-center mt-2">
                              <div
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  backgroundColor: timetable.header_colour,
                                  borderRadius: "10%",
                                  border: "1px solid #000",
                                  marginRight: "10px",
                                }}
                              />
                            </div>
                          )}
                          {timetable.header_text_colour && (
                            <div className="d-flex align-items-center mt-2">
                              <div
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  backgroundColor: timetable.header_text_colour,
                                  borderRadius: "10%",
                                  border: "1px solid #000",
                                  marginRight: "10px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )}

                      {timetable.website && (
                        <Card.Text className="mt-4">
                          <a
                            href={timetable.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {timetable.website}
                          </a>
                        </Card.Text>
                      )}

                      <div className="mt-3">
                        {timetable.facebook && (
                          <a
                            href={timetable.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mr-3"
                          >
                            <FontAwesomeIcon icon={faFacebook} size="2x" />
                          </a>
                        )}
                        {timetable.instagram && (
                          <a
                            href={timetable.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon icon={faInstagram} size="2x" />
                          </a>
                        )}
                      </div>
                      {/* Action Buttons */}
                      <Row className="mt-3">
                        <Col xs={12} md={3} className="mb-2">
                          <Button
                            href={`/timetable/${timetable.id}`}
                            variant="outline-primary"
                            className="w-100"
                          >
                            Manage Timetable
                          </Button>
                        </Col>
                        <Col xs={6} md={1} className="mb-2">
                          <Button
                            variant="warning"
                            className="w-100"
                            onClick={() => handleShowEditModal(timetable)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </Col>
                        <Col xs={6} md={1} className="mb-2">
                          <Button
                            variant="danger"
                            className="w-100"
                            onClick={() => handleDeleteTimetable(timetable.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                );
              })
            ) : (
              <p>No timetables available.</p>
            )}
          </Card.Body>
        </Card>
      </Container>

      {/* Create Timetable Modal */}
      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Timetable</Modal.Title>
        </Modal.Header>
        <Alert variant="warning" className="mt-2 mr-2 ml-2">
          These details will be publicly displayed on your page. Do not share
          anything you wouldn't want to be seen by the public.
        </Alert>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTimetableTitle" className="mb-2">
              <Form.Label className="fw-bold">
                Title <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter timetable title"
                value={newTimetableTitle}
                onChange={(e) => setNewTimetableTitle(e.target.value)}
                required
                isInvalid={!!titleError}
              />
              <Form.Control.Feedback type="invalid">
                {titleError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formTimetableDescription" className="mb-2">
              <Form.Label className="fw-bold">
                Description <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter timetable description"
                value={newTimetableDescription}
                onChange={(e) => setNewTimetableDescription(e.target.value)}
                required
                isInvalid={!!descriptionError}
              />
              <Form.Control.Feedback type="invalid">
                {descriptionError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formTimetableWebsite" className="mb-2">
              <Form.Label className="fw-bold">Website Link</Form.Label>
              <Form.Control
                type="url"
                placeholder="Enter website link"
                value={newTimetableWebsite}
                onChange={(e) => setNewTimetableWebsite(e.target.value)}
                isInvalid={!!websiteError} // Display validation error
              />
              <Form.Control.Feedback type="invalid">
                {websiteError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formTimetableFacebook" className="mb-2">
              <Form.Label className="fw-bold">Facebook Link</Form.Label>
              <Form.Control
                type="url"
                placeholder="Enter Facebook link"
                value={newTimetableFacebook}
                onChange={(e) => setNewTimetableFacebook(e.target.value)}
                isInvalid={!!facebookError} // Display validation error
              />
              <Form.Control.Feedback type="invalid">
                {facebookError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formTimetableInstagram" className="mb-2">
              <Form.Label className="fw-bold">Instagram Link</Form.Label>
              <Form.Control
                type="url"
                placeholder="Enter Instagram link"
                value={newTimetableInstagram}
                onChange={(e) => setNewTimetableInstagram(e.target.value)}
                isInvalid={!!instagramError} // Display validation error
              />
              <Form.Control.Feedback type="invalid">
                {instagramError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formTimetableAddressOne" className="mb-2">
              <Form.Label className="fw-bold">Address Line 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Address Line 1"
                value={newTimetableAddressLineOne}
                onChange={(e) => setNewTimetableAddressLineOne(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formTimetableAddressTwo" className="mb-2">
              <Form.Label className="fw-bold">Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Address Line 2"
                value={newTimetableAddressLineTwo}
                onChange={(e) => setNewTimetableAddressLineTwo(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formTimetableCity" className="mb-2">
              <Form.Label className="fw-bold">City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter City"
                value={newTimetableCity}
                onChange={(e) => setNewTimetableCity(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formTimetablePostcode" className="mb-2">
              <Form.Label className="fw-bold">Postcode</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Postcode"
                value={newTimetablePostcode}
                onChange={(e) => setNewTimetablePostcode(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formTimetableCountry" className="mb-2">
              <Form.Label className="fw-bold">Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Country"
                value={newTimetableCountry}
                onChange={(e) => setNewTimetableCountry(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formTimetablePhone" className="mb-2">
              <Form.Label className="fw-bold">Contact Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Phone"
                value={newTimetablePhone}
                onChange={(e) => setNewTimetablePhone(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formTimetableEmail" className="mb-2">
              <Form.Label className="fw-bold">Contact Email Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email"
                value={newTimetableEmail}
                onChange={(e) => setNewTimetableEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formTimetablePrimaryColour" className="mb-2">
              <Form.Label>Primary Colour</Form.Label>
              <HexColorPicker
                color={newTimetableHeaderColour}
                onChange={(display_colour) =>
                  setNewTimetableHeaderColour(display_colour)
                }
                className="mt-3"
              />
            </Form.Group>
            <Form.Group controlId="formHexColor">
              <Form.Label>Hex Primary Colour</Form.Label>
              <Form.Control
                type="text"
                value={newTimetableHeaderColour}
                onChange={(e) => setNewTimetableHeaderColour(e.target.value)}
                placeholder="#000000"
              />
            </Form.Group>
            <Form.Group controlId="formTimetableTextColour" className="mb-2">
              <Form.Label>Text Colour</Form.Label>
              <HexColorPicker
                color={newTimetableTextColour}
                onChange={(display_colour) =>
                  setNewTimetableTextColour(display_colour)
                }
                className="mt-3"
              />
            </Form.Group>
            <Form.Group controlId="formHexTextColour">
              <Form.Label>Hex Text Colour</Form.Label>
              <Form.Control
                type="text"
                value={newTimetableTextColour}
                onChange={(e) => setNewTimetableTextColour(e.target.value)}
                placeholder="#000000"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleCreateTimetable}>
            Publish
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Timetable Modal */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Timetable</Modal.Title>
        </Modal.Header>
        <Alert variant="warning" className="mt-2 mr-2 ml-2">
          These details will be publicly displayed on your page. Do not share
          anything you wouldn't want to be seen by the public.
        </Alert>
        <Modal.Body>
          {selectedTimetable && (
            <Form>
              <Form.Group controlId="formEditTimetableTitle" className="mb-2">
                <Form.Label className="fw-bold">
                  Title <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter timetable title"
                  value={selectedTimetable.title}
                  isInvalid={!!titleError}
                  onChange={(e) =>
                    setSelectedTimetable({
                      ...selectedTimetable,
                      title: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {titleError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                controlId="formEditTimetableDescription"
                className="mb-2"
              >
                <Form.Label className="fw-bold">
                  Description <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter timetable description"
                  value={selectedTimetable.description}
                  isInvalid={!!descriptionError}
                  onChange={(e) =>
                    setSelectedTimetable({
                      ...selectedTimetable,
                      description: e.target.value,
                    })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {descriptionError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                controlId="formEditTimetableWebsiteLink"
                className="mb-2"
              >
                <Form.Label className="fw-bold">Website Link</Form.Label>
                <Form.Control
                  type="url"
                  placeholder="Enter website link"
                  value={selectedTimetable.website}
                  onChange={(e) =>
                    setSelectedTimetable({
                      ...selectedTimetable,
                      website: e.target.value,
                    })
                  }
                  isInvalid={!!websiteError} // Display validation error
                />
                <Form.Control.Feedback type="invalid">
                  {websiteError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                controlId="formEditTimetableFacebook"
                className="mb-2"
              >
                <Form.Label className="fw-bold">Facebook Link</Form.Label>
                <Form.Control
                  type="url"
                  placeholder="Enter Facebook Link"
                  value={selectedTimetable.facebook}
                  onChange={(e) =>
                    setSelectedTimetable({
                      ...selectedTimetable,
                      facebook: e.target.value,
                    })
                  }
                  isInvalid={!!facebookError} // Display validation error
                />
                <Form.Control.Feedback type="invalid">
                  {facebookError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                controlId="formEditTimetableInstagram"
                className="mb-2"
              >
                <Form.Label className="fw-bold">Instagram Link</Form.Label>
                <Form.Control
                  type="url"
                  placeholder="Enter Instagram Link"
                  value={selectedTimetable.instagram}
                  onChange={(e) =>
                    setSelectedTimetable({
                      ...selectedTimetable,
                      instagram: e.target.value,
                    })
                  }
                  isInvalid={!!instagramError} // Display validation error
                />
                <Form.Control.Feedback type="invalid">
                  {instagramError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                controlId="formEditTimetableAddressLine1"
                className="mb-2"
              >
                <Form.Label className="fw-bold">Address Line 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter address line 1"
                  value={selectedTimetable.address_line_1}
                  onChange={(e) =>
                    setSelectedTimetable({
                      ...selectedTimetable,
                      address_line_1: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group
                controlId="formEditTimetableAddressLine2"
                className="mb-2"
              >
                <Form.Label className="fw-bold">Address Line 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter address line 2"
                  value={selectedTimetable.address_line_2}
                  onChange={(e) =>
                    setSelectedTimetable({
                      ...selectedTimetable,
                      address_line_2: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formEditTimetableCity" className="mb-2">
                <Form.Label className="fw-bold">City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  value={selectedTimetable.city}
                  onChange={(e) =>
                    setSelectedTimetable({
                      ...selectedTimetable,
                      city: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group
                controlId="formEditTimetablePostcode"
                className="mb-2"
              >
                <Form.Label className="fw-bold">Postcode</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter postcode"
                  value={selectedTimetable.postcode}
                  onChange={(e) =>
                    setSelectedTimetable({
                      ...selectedTimetable,
                      postcode: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formEditTimetableCountry" className="mb-2">
                <Form.Label className="fw-bold">Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter country"
                  value={selectedTimetable.country}
                  onChange={(e) =>
                    setSelectedTimetable({
                      ...selectedTimetable,
                      country: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formEditTimetablePhone" className="mb-2">
                <Form.Label className="fw-bold">
                  Contact Phone Number
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone"
                  value={selectedTimetable.phone}
                  onChange={(e) =>
                    setSelectedTimetable({
                      ...selectedTimetable,
                      phone: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formEditTimetableEmail" className="mb-2">
                <Form.Label className="fw-bold">
                  Contact Email Address
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email"
                  value={selectedTimetable.email}
                  onChange={(e) =>
                    setSelectedTimetable({
                      ...selectedTimetable,
                      email: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group
                controlId="formEditTimetablePrimaryColour"
                className="mb-2"
              >
                <Form.Label>Primary Colour</Form.Label>
                <HexColorPicker
                  color={editTimetableHeaderColour}
                  onChange={(display_colour) =>
                    setEditTimetableHeaderColour(display_colour)
                  }
                  className="mt-3"
                />
              </Form.Group>
              <Form.Group controlId="formEditPrimaryHexColor">
                <Form.Label>Hex Primary Colour</Form.Label>
                <Form.Control
                  type="text"
                  value={editTimetableHeaderColour}
                  onChange={(e) => setEditTimetableHeaderColour(e.target.value)}
                  placeholder="#000000"
                />
              </Form.Group>
              <Form.Group
                controlId="formEditTimetableTextColour"
                className="mb-2"
              >
                <Form.Label>Text Colour</Form.Label>
                <HexColorPicker
                  color={editTimetableTextColour}
                  onChange={(display_colour) =>
                    setEditTimetableTextColour(display_colour)
                  }
                  className="mt-3"
                />
              </Form.Group>
              <Form.Group controlId="formEditHexTextColor">
                <Form.Label>Hex Text Colour</Form.Label>
                <Form.Control
                  type="text"
                  value={editTimetableTextColour}
                  onChange={(e) => setEditTimetableTextColour(e.target.value)}
                  placeholder="#000000"
                />
              </Form.Group>
              <Button
                variant="primary"
                className="mt-4"
                onClick={handleUpdateTimetable}
              >
                Publish Timetable
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Dashboard;
