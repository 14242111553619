import React from "react";
import { Container, Card, Image } from "react-bootstrap";

const DashboardContent = () => {
  return (
    <Container>
      <section id="introduction">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Timetable Dashboard</Card.Title>
            <Card.Text>
              The dashboard is where you will create, edit and delete
              timetables. Each timetable has its own classes, instructors and
              events so it's unlikely you'll need to create many. However, we
              understand that there are businesses that host multiple types of
              class schedules. For example, a community centre may host a
              martial arts school and a dance school. As a result, you're free
              to create as many timetables as you wish!
              <br />
              <br />
              Timetablr prides itself on the public nature of your pages. Please
              be wary of the fact that once a timetable is created, it is a
              public page, ready for you to update and share with your
              customers. Please keep this in mind when adding your details.
            </Card.Text>
            <Image src={"/images/dashboard.png"} fluid />
          </Card.Body>
        </Card>
      </section>

      <section id="create">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Create a Timetable</Card.Title>
            <Card.Text>
              Creating a timetable is as simple as hitting the 'Create
              Timetable' button and filling in your details. Be advised that
              mandatory fields are indicated by a red asterix throughout.
            </Card.Text>
            <Image
              src={"/images/create_timetable.png"}
              style={{ width: "800px", height: "auto" }}
            />
          </Card.Body>
        </Card>
      </section>

      <section id="edit">
        <Card className="mb-4 border-0">
          <Card.Body>
            <Card.Title>Edit & Delete a Timetable</Card.Title>
            <Card.Text>
              You can edit all timetable fields whenever you wish. Simply
              select the edit button on the timetable you wish to change.
              <br />
              <br />
              Similarly, you can delete a timetable by selecting the red trash
              button.
              <br />
              <br />
              <strong>
                Beware that deleting a timetable will delete any related
                instructors, classes and events.
              </strong>
            </Card.Text>
            <Image
              src={"/images/edit_timetable.png"}
              style={{ width: "800px", height: "auto" }}
            />
          </Card.Body>
        </Card>
      </section>
    </Container>
  );
};

export default DashboardContent;
