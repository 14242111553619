// src/pages/ServerError.js
import React from "react";
import LoginHeader from "../../components/LoginHeader";

const ServerError = () => {
  return (
    <div className="text-dark">
      <LoginHeader />
      <div style={{ textAlign: "center", padding: "50px" }}>
        <h1>500 - Server Error</h1>
        <p>Something went wrong on our end. Please try again later.</p>
      </div>
    </div>
  );
};

export default ServerError;
