import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Container, Card } from "react-bootstrap";
import LoginHeader from "../components/LoginHeader";
import { useParams, useNavigate } from "react-router-dom";
import useRecaptcha from "../hooks/useRecaptcha";
import api from "../axiosInstance";

const SubscribeForm = () => {
  const [email, setEmail] = useState("");
  const [classes, setClasses] = useState([]); // State to hold class options
  const [message, setMessage] = useState("");
  const [permission, setPermission] = useState(false); // State for permission checkbox
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [permissionError, setPermissionError] = useState("");

  const navigate = useNavigate();
  const timetableId = useParams()["id"];
  const classId = useParams()["classId"];

  const RECAPTCHA_SITE_KEY = "6Ldk9k4qAAAAAPZl0dfdKCXZQHKlR9YzbN9bffkI";
  useRecaptcha(RECAPTCHA_SITE_KEY);

  // Fetch available classes from the API on component mount
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await api.get(
          `/api/timetables/${timetableId}/classes`
        );
        setClasses(response.data); // Update state with classes from the API
      } catch (err) {
        setError("Failed to fetch classes.");
      }
    };

    fetchClasses();
  }, [timetableId]);

  // Find the selected class based on the classId from the URL
  const selectedClass = classes.find((cls) => cls.id === parseInt(classId));

  const selectedClassName = selectedClass
    ? `${selectedClass.title}${selectedClass.level ? ` - ${selectedClass.level}` : ""}`
    : "Loading...";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setEmailError("");
    setPermissionError("");

    // Basic email validation
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (!selectedClass) {
      setError("Selected class not found.");
      return;
    }

    if (!permission) {
      setPermissionError("Please grant permission to send alerts.");
      return;
    }

    try {
      const recaptchaToken = await window.grecaptcha.execute(
        RECAPTCHA_SITE_KEY,
        { action: "submit" }
      );

      await api.post("/api/subscribe", {
        email: email,
        classId: selectedClass.id, // Pass the selected class ID
        permission: permission,
        recaptcha_token: recaptchaToken,
      });
      navigate("/subscriber-verification");
    } catch (err) {
      if (err.response) {
        if (err.response.data?.message) {
          setError(err.response.data.message);
        } else {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  return (
    <div>
      <LoginHeader />
      <div className="d-flex justify-content-center align-items-center min-vh-100">
        <Container>
          <Card className="mx-auto" style={{ width: "100%", maxWidth: "500px" }}>
            <Card.Header as="h5">Subscribe to Class</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                {/* Display the class title and level */}
                <Form.Group controlId="formClassDisplay" className="mb-3">
                  <Form.Label>Subscribing to notifications for:</Form.Label>
                  <div>{selectedClassName}</div>
                </Form.Group>

                {/* Email field */}
                <Form.Group controlId="formEmail">
                  <Form.Label>
                    Email address <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={!!emailError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {emailError}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Permission Checkbox */}
                <Form.Group controlId="formPermission" className="mb-3 mt-3">
                  <Form.Check
                    type="checkbox"
                    label="I give my permission to Timetablr to send me alerts for the selected class."
                    checked={permission}
                    onChange={(e) => setPermission(e.target.checked)}
                    isInvalid={!!permissionError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {permissionError}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-4">
                  Subscribe
                </Button>
              </Form>

              {message && (
                <Alert variant="success" className="mt-3">
                  {message}
                </Alert>
              )}
              {error && (
                <Alert variant="danger" className="mt-3">
                  {error}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
};

export default SubscribeForm;
