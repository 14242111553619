import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareFromSquare } from "@fortawesome/free-solid-svg-icons";

const handleShareClick = () => {
  // Copy the current page URL to the clipboard
  navigator.clipboard
    .writeText(window.location.href)
    .then(() => {
      alert("Link copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy the link: ", err);
    });
};

const ShareButton = () => {
  return (
    <Button
      variant="outline-primary"
      onClick={handleShareClick}
      className="mt-2 btn-md"
      style={{
        padding: "4px 8px", // Adjust padding for size
        maxWidth: "120px", // Set maximum width
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "2px",
      }}
    >
      <span className="d-none d-md-inline">Share</span>{" "}
      {/* Only show on medium and larger screens */}
      <FontAwesomeIcon
        icon={faShareFromSquare}
        className={window.innerWidth < 768 ? "" : "ml-2"}
      />
    </Button>
  );
};

export default ShareButton;
