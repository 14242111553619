import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Header from "../components/Header";

const PricingPage = () => {
  return (
    <div>
      <Header />
      <Container className="my-5">
        <Row className="text-center mb-5">
          <Col>
            <h1>Everything Included in One Monthly Price</h1>
            <h6 className="mb-4 text-center" style={{ fontSize: "2rem" }}>
              Free to try for 7 days, then...
            </h6>
          </Col>
        </Row>
        <div className="row justify-content-center">
          <div className="col-md-4 mx-auto">
            <div className="card mb-4 box-shadow">
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  $27 <small className="text-muted">/ month</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "#3399ff", marginRight: "8px" }}
                    />
                    Timetables
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "#3399ff", marginRight: "8px" }}
                    />
                    Class Details
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "#3399ff", marginRight: "8px" }}
                    />
                    Instructor Profiles
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "#3399ff", marginRight: "8px" }}
                    />
                    Customer Notifications
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "#3399ff", marginRight: "8px" }}
                    />
                    Class Schedule Proposals
                  </li>
                  <li>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "#3399ff", marginRight: "8px" }}
                    />
                    Timetable Reports
                  </li>
                </ul>
                <Button size="lg" href="/auth/signup?plan=basic">
                  Get started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PricingPage;
