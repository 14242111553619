// App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Landing from "./pages/Landing";
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import Timetable from "./pages/Timetable";
import Classes from "./pages/Classes";
import Unauthorized from "./pages/errors/Unauthorised";
import ServerError from "./pages/errors/ServerError";
import NotFound from "./pages/errors/NotFound";
import VerificationPage from "./pages/VerificationPage";
import VerificationSub from "./pages/VerificationSub";
import "./styles.css";
import "./cardStyles.css";
import Verify from "./pages/Verify";
import Subscribe from "./pages/Subscribe";
import VerifySubscription from "./pages/VerifySubscription";
import Profile from "./pages/Profile";
import Reports from "./pages/Reports";
import ResendEmail from "./pages/errors/ResendEmail";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Unsubscribe from "./pages/Unsubscribe";
import ProposeTime from "./pages/ProposeTime";
import VerificationProp from "./pages/VerificationProp";
import VerifyProp from "./pages/VerifyProp";
import ProposedTimes from "./pages/ProposedTimes";
import Payment from "./pages/Payment";
import BadRequest from "./pages/errors/BadRequest";
import PaymentRequired from "./pages/errors/PaymentRequired";
import ResetPassword from "./pages/ResetPassword";
import Documentation from "./pages/Documentation";
import Instructors from "./pages/Instructors";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/auth/:authType" element={<Auth />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/timetable/:id" element={<Timetable />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/timetable/:id/reports" element={<Reports />} />
        <Route
          path="/timetable/:id/proposed-times"
          element={<ProposedTimes />}
        />
        <Route path="/timetable/:id/classes" element={<Classes />} />
        <Route path="/timetable/:id/instructors" element={<Instructors />} />
        <Route
          path="/timetable/:id/classes/:classId/subscribe"
          element={<Subscribe />}
        />
        <Route
          path="/timetable/:id/classes/:classId/propose-time"
          element={<ProposeTime />}
        />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/bad-request" element={<BadRequest />} />
        <Route path="/payment-required" element={<PaymentRequired />} />
        <Route path="/resend-email/:email" element={<ResendEmail />} />
        <Route path="/auth/verification" element={<VerificationPage />} />
        <Route
          path="/subscriber-verification"
          element={<VerificationSub />}
        />
        <Route path="/proposal-verification" element={<VerificationProp />} />
        <Route path="/auth/verify" element={<Verify />} />
        <Route path="/verify-subscription" element={<VerifySubscription />} />
        <Route path="/verify-proposal" element={<VerifyProp />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/server-error" element={<ServerError />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/auth/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
