import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useParams, useNavigate } from "react-router-dom";
import ProposedTimesChart from "../components/TimesChart";
import Layout from "../components/Layout";
import TimesTable from "../components/TimesTable";
import { Tabs, Tab } from "react-bootstrap";
import api from "../axiosInstance";

const ProposedTimes = () => {
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [proposals, setProposals] = useState([]);
  const [activeTab, setActiveTab] = useState("table");
  const navigate = useNavigate();
  const timetableId = useParams()["id"];

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await api.get(
          `/api/timetables/${timetableId}/classes`
        );
        const classOptions = response.data.map((classItem) => ({
          value: classItem.id,
          label: `${classItem.title}${classItem.level ? ` - ${classItem.level}` : ""}`,
        }));
        setClasses(classOptions);
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              navigate("/bad-request");
              break;
            case 402:
              navigate("/payment-required");
              break;
            case 401:
              navigate("/auth/login");
              break;
            case 500:
              navigate("/server-error");
              break;
            default:
              navigate("/not-found");
          }
        } else {
          navigate("/server-error");
        }
      }
    };

    fetchClasses();
  }, [timetableId, navigate]);

  useEffect(() => {
    const fetchProposals = async () => {
      const token = localStorage.getItem("tt_jwt");
      if (selectedClass) {
        try {
          const response = await api.get(
            `/api/proposals?class_id=${selectedClass.value}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setProposals(response.data);
        } catch (err) {
          if (err.response) {
            switch (err.response.status) {
              case 400:
                navigate("/bad-request");
                break;
              case 402:
                navigate("/payment-required");
                break;
              case 401:
                navigate("/auth/login");
                break;
              case 500:
                navigate("/server-error");
                break;
              default:
                navigate("/not-found");
            }
          } else {
            navigate("/server-error");
          }
        }
      }
    };

    fetchProposals();
  }, [selectedClass, navigate]);

  return (
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh" }}>
      <Layout>
        <Select
          options={classes}
          onChange={setSelectedClass}
          placeholder="Select a class"
          className="mt-2"
          styles={{
            container: (provided) => ({
              ...provided,
              maxWidth: "200px",
              marginLeft: "35px",
            }),
          }}
        />
        {/* Conditional rendering for the message or the tabs */}
        {!selectedClass ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
              fontSize: "24px",
              color: "grey",
            }}
          >
            No Class Selected
          </div>
        ) : (
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mb-4 mr-4 ml-4 mt-4"
          >
            <Tab eventKey="table" title="Table">
              <TimesTable proposedTimes={proposals} />
            </Tab>
            <Tab eventKey="chart" title="Histogram">
              <ProposedTimesChart proposedTimes={proposals} />
            </Tab>
          </Tabs>
        )}
      </Layout>
    </div>
  );
};

export default ProposedTimes;
