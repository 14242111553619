import { useEffect } from "react";

const useRecaptcha = (siteKey) => {
  useEffect(() => {
    const loadRecaptcha = () => {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    loadRecaptcha();
  }, [siteKey]);
};

export default useRecaptcha;
