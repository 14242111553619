import React, { useState } from "react";
import LoginHeader from "../../components/LoginHeader";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import api from "../../axiosInstance";

const ResendEmail = () => {
  const [resendStatus, setResendStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const email = useParams()["email"];

  const handleResendEmail = async () => {
    try {
      await api.post(`/api/resend-email/${email}`);
      setResendStatus("success");
    } catch (error) {
      setResendStatus("error");
      setErrorMessage(
        error.response.data.message ||
          "Unexpected error. Please try again or contact us for further assistance."
      );
    }
  };

  return (
    <div className="page-background text-dark">
      <LoginHeader />
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <h3>Verification Email Sent</h3>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            We have sent you an email with a verification link. Once you've
            verified your account, you'll be able to login and get started!
          </Col>
        </Row>
        <Row className="align-items-center mt-3">
          <Col>
            Didn't receive the email?
            <Button variant="link" onClick={handleResendEmail}>
              Resend Verification Email
            </Button>
          </Col>
        </Row>
        {resendStatus === "success" && (
          <Row className="mt-3">
            <Col>
              <Alert variant="success">
                Verification email has been resent successfully!
              </Alert>
            </Col>
          </Row>
        )}
        {resendStatus === "error" && (
          <Row className="mt-3">
            <Col>
              <Alert variant="danger">{errorMessage}</Alert>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ResendEmail;
