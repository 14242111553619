import React, { useState, useEffect } from "react";
import BarChart from "../components/BarChart";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { Container, Row, Col, Card } from "react-bootstrap";
import api from "../axiosInstance";

const Reports = () => {
  const [classCancellations, setClassCancellations] = useState([]);
  const [totals, setTotals] = useState([]);
  const [classLevels, setClassLevels] = useState([]);
  const [classSubscribers, setClassSubscribers] = useState([]);
  const [instructorCancellations, setInstructorCancellations] = useState([]);
  const timetableId = useParams()["id"];
  const navigate = useNavigate();

  useEffect(() => {
    fetchClassCancellations();
    fetchClassLevels();
    fetchClassSubscribers();
    fetchInstructorCancellations();
    fetchTotals();
  }, []);

  const fetchClassCancellations = async () => {
    try {
      const token = localStorage.getItem("tt_jwt");
      const response = await api.get(
        `/api/timetables/${timetableId}/reports/class-cancellations`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClassCancellations(response.data);
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            navigate("/bad-request");
            break;
          case 402:
            navigate("/payment-required");
            break;
          case 401:
            navigate("/auth/login");
            break;
          case 500:
            navigate("/server-error");
            break;
          default:
            navigate("/not-found");
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  const fetchTotals = async () => {
    try {
      const token = localStorage.getItem("tt_jwt");
      const response = await api.get(
        `/api/timetables/${timetableId}/reports/totals`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotals(response.data);
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            navigate("/bad-request");
            break;
          case 402:
            navigate("/payment-required");
            break;
          case 401:
            navigate("/auth/login");
            break;
          case 500:
            navigate("/server-error");
            break;
          default:
            navigate("/not-found");
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  const fetchClassLevels = async () => {
    try {
      const token = localStorage.getItem("tt_jwt");
      const response = await api.get(
        `/api/timetables/${timetableId}/reports/class-levels`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClassLevels(response.data);
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            navigate("/bad-request");
            break;
          case 402:
            navigate("/payment-required");
            break;
          case 401:
            navigate("/auth/login");
            break;
          case 500:
            navigate("/server-error");
            break;
          default:
            navigate("/not-found");
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  const fetchClassSubscribers = async () => {
    try {
      const token = localStorage.getItem("tt_jwt");
      const response = await api.get(
        `/api/timetables/${timetableId}/reports/class-subscribers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClassSubscribers(response.data);
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            navigate("/bad-request");
            break;
          case 402:
            navigate("/payment-required");
            break;
          case 401:
            navigate("/auth/login");
            break;
          case 500:
            navigate("/server-error");
            break;
          default:
            navigate("/not-found");
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  const fetchInstructorCancellations = async () => {
    try {
      const token = localStorage.getItem("tt_jwt");
      const response = await api.get(
        `/api/timetables/${timetableId}/reports/instructor-cancellations`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInstructorCancellations(response.data);
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            navigate("/bad-request");
            break;
          case 402:
            navigate("/payment-required");
            break;
          case 401:
            navigate("/auth/login");
            break;
          case 500:
            navigate("/server-error");
            break;
          default:
            navigate("/not-found");
        }
      } else {
        navigate("/server-error");
      }
    }
  };

  return (
    <div style={{ backgroundColor: "#f0f4f8", minHeight: "100vh" }}>
      <Layout>
        <Container>
          {/* Row for displaying total cancellations and total subscribers */}
          <Row className="mb-4 mt-2">
            <Col md={6}>
              <Card>
                <Card.Body className="text-center">
                  <h1>{totals.cancelled_event_count}</h1>
                  <Card.Text>Total Cancelled Events</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Body className="text-center">
                  <h1>{totals.subscriber_count}</h1>
                  <Card.Text>Total Subscribers</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            <BarChart
              title="Class Cancellations"
              additionalText="Number of cancelled events per class"
              labels={classCancellations.labels}
              data={classCancellations.data}
              backgroundColor="rgba(255, 99, 132, 0.2)"
            />
            <BarChart
              title="Class Subscriptions"
              additionalText="Number of subscriptions per class"
              labels={classSubscribers.labels}
              data={classSubscribers.data}
              backgroundColor="rgba(54, 162, 235, 0.2)"
            />
            <BarChart
              title="Instructor Cancellations"
              additionalText="Number of cancelled classes per instructor"
              labels={instructorCancellations.labels}
              data={instructorCancellations.data}
              backgroundColor="rgba(255, 206, 86, 0.2)"
            />
            <BarChart
              title="Class Levels"
              additionalText="Number of classes with each level"
              labels={classLevels.labels}
              data={classLevels.data}
              backgroundColor="rgba(75, 192, 192, 0.2)"
            />
          </div>
        </Container>
      </Layout>
    </div>
  );
};

export default Reports;
