// Header.js
import React from "react";
import { Navbar, Nav, Button, Container } from "react-bootstrap";
import "../styles.css"; // Import styles for the header

function BasicHeader() {
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">Timetablr</Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default BasicHeader;
