import React from "react";
import LoginHeader from "../components/LoginHeader";
import { Container, Row, Col } from "react-bootstrap";

const VerificationProp = () => {
  return (
    <div className="page-background text-dark">
      <LoginHeader />
      <Container className="mt-4">
        <Row className="align-items-center">
          <Col>
            <h3>Verification Email Sent</h3>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            We have sent you an email with a verification link. Once you've
            verified your proposal, the business will be made aware of the
            proposed time.
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VerificationProp;
